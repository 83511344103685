
//
//  Messages
//  ---------------------------------------------

.message.global {
    &.cookie {
        background: @quaternary__color;
        padding: 15px 0;
        z-index: 30;

        .content {
            .outer-container();

            &:before,
            &:after {
                display: none;
            }

            p {
                margin-right: @indent__s;
            }
        }

        .actions {
            margin-top: 0;
        }

        .action.allow {
            min-width: 240px;
        }

        .mq(m; {
            .content {
                .lib-css(display, flex);
                .lib-css(justify-content, space-between);
                .lib-css(align-items, center);
            }
        });

        .mq(- xs; {
            .action.allow {
                width: 100%;
            }
        });
    }
    &.demo {
        .mq(- xs; {
            font-size: 1.125rem;
        })
    }
}
