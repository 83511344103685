
//
//  Account
//  ---------------------------------------------

.quick-order-icon {
    display: block;

    &:before {
        content: '';
        .svg-list-icon();
        .svg-list-icon-dims();
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px 0 auto;
        transform: scale(0.8);
    }

    &:hover {
        text-decoration: none;
    }

    .quick-order-icon__label {
        .mq(- xl, {
            display: none;
        });
    }
}