.company-account-index {
    .company-create__message--success {
        color: #000000;

        div[data-element="main"] {
            div[data-element="inner"] {
                padding: 0 !important;
                margin: 0 !important;
            }
        }
    }
}

.company-role-edit {
    .form-edit-role {
        .note {
            display: none;
        }
    }
}
