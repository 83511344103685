
//
//  Products Grid
//  ---------------------------------------------

.catalog-product-view{
    .products-grid {
        .product-items {
            .product-item {
                width: 25%;

                .mq(- m; {
                    width: 50%;
                });

                .mq(- xs; {
                    width: 100%;
                });

                .product-item-info {

                    .field.choice {
                        display: none;
                    }
                }

            }
        }

    }
}

.page-with-filter > .page-wrapper {
    .breadcrumbs {
        order: 3;
    }
    .category-view {
        order: 2;
    }
    .page-main {
        order:1;
    }
    #maincontent{
        order: 4;
    }
    .page-footer {
        order: 5;
    }
}

