//
//  Downloads
//  ---------------------------------------------

.product-downloads {
    .mq(xs; {
        display: table;
    });

    .mq(m; {
        padding-right: @indent__base;
    });
}

.product-downloads__button-wrapper {
    margin-bottom: @indent__s;
}

.product-downloads__button {
    .btn-secondary();
    .btn-responsive();
    padding-left: 30px;
    padding-right: 30px;
}

.product-page__details {
    .lib-css(display, flex);
    .lib-css(flex-direction, row);
    .lib-css(justify-content, space-between);
    -ms-flex-pack: justify;

    .product-page__downloads {
        .lib-css(flex, 1);
        width: 100%;

        .mq(m; {
            .lib-css(flex, 1 1 (100% / 3 * 1));
            width: 100% / 3 * 1;
            padding-right: @indent__base;
        });
    }

    .product-page__downloads + .product-page__blocks {
        .lib-css(flex, 1);
        width: 100%;

        .mq(m; {
            .lib-css(flex, 1 1 (100% / 3 * 2));
            width: 100% / 3 * 2;
        });
    }

}
