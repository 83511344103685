
//
//  Compare
//  ---------------------------------------------

.action.print{
    .btn-primary();
}

.comparison {
    .product.info {
        .lib-product-price-box(catalog);
    }

    .action.tocart {
        .btn-cart();
    }

    .table-comparison {
        .cell {
            padding: @indent__s;
            width: 210px;
        }

        tr:nth-child(even) {
            background: @tertiary__color;
        }
    }
}
