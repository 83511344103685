.cms-index-index {
  .page-section__inner {
    max-width: none;
    padding: 0;
  }
  .widget__inner {
    max-width: @layout__max-width + (@layout__width-xs-indent * 2);
    box-sizing: border-box;
    width: 100%;
    padding: 0 @layout-indent__width;
    margin-left: auto;
    margin-right: auto;

    &:not(.row) {
      max-width: @layout__max-width;

    }
  }
}
