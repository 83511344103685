
//
//  Settings
//  ---------------------------------------------




//
//  Breadcrumbs
//  ---------------------------------------------

.breadcrumbs {
    .items {
        font-size: @base__font-size;
        color: #363636;
    }

    a,
    a:visited {
        color: #363636;
        text-decoration: underline;
    }

    a {
        &:hover {
            text-decoration: none;
            color: @secondary__color;
        }
    }

    .item:not(:last-child) {
        &:after {
            margin: 3px @indent__xs;
        }
    }
}
