
//
//  Recently Viewed
//  ---------------------------------------------

.recently-viewed {
    min-width: 160px;
    min-height: 100px;
    position: relative;
    margin: @indent__base 0;

    &__loader {
        .lib-loader();
        position: absolute;
        z-index: 1;
        background: transparent;
    }
}
