.filter-content {
    .filter-options {
        .filter-options-item {
            &.active {
                .filter-options-title {
                    &::after {
                        content: @icon-up!important; // Override other important
                    }
                }
            }

            .filter-options-title {
                &::after {
                    content: @icon-down!important; // Override other important
                }
            }

            .filter-options-content {
                display: block;

                a {
                    &:hover {
                        background: none;
                        cursor: pointer;
                    }
                }

                .field.search {
                    display: none;
                }
            }
        }
    }
}
