
//
//  Bundle
//  ---------------------------------------------

.bundle-options-container {
    .lib-clearfix();

    .bundle-options-wrapper,
    .product-options-wrapper {
        width: 100%;
        margin-bottom: 0;

        .mq(m; {
            width: 100%;
            float: none;
        });

        .fieldset {
            margin: 0;

            > .field {
                border-top: 0;
                padding-top: 0;

                .nested:not(.options-list) {
                    display: none;
                }
            }
        }


        .price-box {
            display: none;
        }

        //.control {
        //    position: relative;
        //
        //    //.bundle-options-spinner {
        //    //    position: absolute;
        //    //    text-align: center;
        //    //    left: 0;
        //    //    right: 0;
        //    //    top: 0;
        //    //    bottom: 0;
        //    //    padding-top: 10px;
        //    //    background: rgba(0,0,0,0.05);
        //    //    //<div class="bundle-options-spinner"><i class="fas fa-spinner fa-spin"></i></div>
        //    //}
        //}
    }

    .block-bundle-summary {
        width: 100%;
        float: none;
        padding: 0;
        margin-top: 0;
        top: auto !important;
        position: relative !important;

        .mq(m; {
            width: 100%;
            float: none;
            padding: 0;
            margin-top: 0;
            top: auto;
        });

        .price-box {
            display: none;
        }
    }

    .product-add-form {
        display: block !important;
    }
}

.page-product-bundle {
    .product-page__addto-box {
        .product-info-price {
            > .price-box {
                p {
                    margin-bottom: 0;
                }
            }

            .price-to {
                display: none;
            }

            .price-from {
                .price-label {
                    display: none;
                }
            }
        }
    }
}
