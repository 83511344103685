.content-widget {
  .content-widget__row {
    display: flex;
    flex-direction: column;
    padding: 80px 0;

    .mq(m, {
      flex-direction: row;
      > * {
        flex: 1 1 100%;
      }
    });
  }

  .content-widget__image {
    width: 100%;
    background-size: cover;
    background-position: center;

    .mq(- m, {
      min-height: 25vh;
      margin-bottom: 16px;
    });

    .mq(m, {
      margin-right: 90px
    });
  }

  .subtitle {
    text-transform: uppercase;

    + h2 {
      margin-top: 0;
    }
  }

  p + a.primary {
    margin-top: 16px;
  }
}