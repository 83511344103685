
//
//  Forms
//  ---------------------------------------------

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: @text__color--muted;
}
input::-moz-placeholder { /* Firefox 19+ */
    color: @text__color--muted;
}
input:-ms-input-placeholder { /* IE 10+ */
    color: @text__color--muted;
}
input:-moz-placeholder { /* Firefox 18- */
    color: @text__color--muted;
}

select,
input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="file"],
input[type="email"] {
    .lib-bluebird-input();
}

input:not([disabled]) {
    .lib-bluebird-input(@input__height: false, @input__border-radius: false);
}

textarea,
textarea:not([disabled]) {
    .lib-bluebird-input(
        @input__height: false,
        @input__padding: @textarea__padding
    );
}

