
//
//  Newsletter
//  ---------------------------------------------

.block.newsletter {
    margin-bottom: 0;
    width: 100%;

    .mq(s; {
        width: 100%;
    });

    .form.subscribe {
        position: relative;
    }

    .field.newsletter {
        .control {
            &:before {
                display: none;
            }
        }
    }

    input {
        padding-left: @indent__base;
        padding-right: 140px;
        width: ~"calc(100% - 2px)";
    }

    .actions {
        position: absolute;
        width: auto;
        top: 0;
        right: 0;
    }

    .action.subscribe {
        .btn-secondary();
    }
}
