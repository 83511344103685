
//
//  Product Blocks
//  ---------------------------------------------

.product-page__blocks {
    width: 100%;
}

.product-blocks {
    width:100%;
    float: left;

    .product-block {
        margin-bottom: 40px;

        .product-block__title{
            .lib-bluebird-heading(h4);
        }

        .product-block__content {
            font-size: @font-size__l;
            color: @primary__color;
            font-family: @primary__font-family;
            font-weight: @base__font-weight;

            &.flex {
                align-items: center;
                display: flex;
                gap: 4rem;
                padding: 2rem 0;

                .product-block__content--image {
                    flex: 0 0 100%;
                    text-align: center;
                    width: 100%;

                    @media screen and (min-width: 768px) {
                        flex: 0 0 ~'calc(40% - 2rem)';
                        width: ~'calc(40% - 2rem)';
                    }

                    &:last-of-type {
                        flex: 0 0 100%;
                        width: 100%;
                    }

                    + .product-block__content--text {
                        flex: 0 0 100%;
                        width: 100%;

                        @media screen and (min-width: 768px) {
                            flex: 0 0 ~'calc(60% - 2rem)';
                            width: ~'calc(60% - 2rem)';
                        }
                    }
                }

                .product-block__content--text {
                    flex: 0 0 100%;
                    width: 100%;
                }
            }

            &.image-left {
                flex-direction: column;

                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }
            }

            &.image-right {
                flex-direction: column-reverse;

                @media screen and (min-width: 768px) {
                    flex-direction: row-reverse;
                }
            }

            .table-wrapper {
                .lib-table-attributes();

                table tr td{
                    &::before{
                        content:":\00a0";
                    }
                }

                .mq(- s; {
                    .lib-table-attributes();
                    border-top: 0;

                    .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr,
                    .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr {
                        margin: 0 0 5px;

                        td,
                        th {
                            &:before {
                                display: block;
                                color: @primary__color;
                                font-weight: @font-weight__heavier;
                            }
                        }

                    }


                });
            }
        }
    }
}
