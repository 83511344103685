
//
//  Account
//  ---------------------------------------------

.block-collapsible-nav .item.current a,
.block-collapsible-nav .item.current > strong {
    border-color: @primary__color;
}

.login-container .block.block-new-company {
    margin-top: 3rem;
}

.account {
    .title-actions-links {
        margin-top: 0;

        .mq(m; {
            margin-top: 0;
        });
    }

    .page-main {
        .page-title-wrapper {
            .page-title {
                margin-bottom: 1rem;

                .mq(m; {
                    margin-bottom: 2rem;
                });
            }
        }

        .column.main {
            .block {
                margin-bottom: 50px;
            }

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            .actions-toolbar {
                button.action {
                    .btn-primary();
                }

                a {
                    text-decoration: none;
                }
            }

        }
    }


    .search-sku button[type='submit']:after {
        line-height: 42px;
        margin-left: -8px;
    }
}

.create.account {
    .field.reseller-id {
        display: none;
    }
}
