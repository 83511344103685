
//
//  Contact
//  ---------------------------------------------

.contact-page {

    .mq(m; {
        .lib-css(display, flex);
        .lib-css(justify-content, space-between);

        &__form,
        &__content {
            .lib-css(flex, 1 1 50%);
        }

        &__form {
            margin-right: @indent__base;
        }
    });

    &__form {
        margin-bottom: @indent__xl;
    }
}
