
//
//  Footer Newsletter
//  ---------------------------------------------

.page-footer__newsletter {
    padding: 50px 0;
    background: @primary__color;
    color: @color-white;
}

.footer__newsletter {
    .outer-container();
}

.footer__newsletter-inner {
    .lib-css(display, flex);
    .lib-css(justify-content, space-between);
    .lib-css(align-items, center);

    .mq(- m; {
        .lib-css(flex-direction, column);
        .lib-css(align-items, baseline);
    });
}

.footer__newsletter-title{
    .lib-css(flex, 1 1 60%);
    font-family: @secondary__font-family;
    font-weight: @font-weight__bold;
    font-size: @h3__font-size;

    strong {
        color: @secondary__color;
    }

    p {
        margin-bottom: 0;
    }

    .mq(- m; {
        margin-bottom: @indent__base;
        .lib-css(flex, 1 1 100%);
        width: 100%;
        font-size: @font-size__l;
    });
}

.footer__newsletter-form {
    .lib-css(flex, 1 1 40%);
    .mq( m; {
    margin-left: 40px;
    });

    input {
        border-color: white;
        background-clip: unset;

        &:active {
            border-color: @secondary__color;
        }
    }

    .mq(- m; {
        .lib-css(flex, 1 1 100%);
        width: 100%;
    });
}
