
//
//  Mini Search
//  ---------------------------------------------

.block-search {
    width: 100%;
    padding-left: 0;
    margin-bottom: @indent__s;
    float: none;

    input {
        border: 1px solid @quaternary__color;
        border-radius: 30px;
        padding-right: 100px;
        font-size: @font-size__m;
        font-weight: @font-weight__light;
        font-family: @primary__font-family;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: @text__color--muted !important;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: @text__color--muted !important;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: @text__color--muted !important;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: @text__color--muted !important;
        }

        &::-ms-clear {
            display: none;
            height: 0;
            width: 0;
        }
    }

    .action.search {
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: @button__border-radius;
        border: 2px solid @button-primary__background;
        background: @button-primary__background;
        color: @button-primary__color;
        opacity: 1 !important;
        padding: 0 @indent__base;
        cursor: pointer;
        font-size: @button__font-size;
        font-family: @secondary__font-family;
        font-weight: @button__font-weight;

        > span {
            position: relative;
        }

        &:before {
            display: none;
        }

        &:after {
            content: '';
            .svg-search-icon-white();
            .svg-search-icon-white-dims();
            display: block;
            position: absolute;
            top: 7px;
            right: 14px;
        }

        &:hover,
        &:active,
        &:focus {
            background: @button-primary__background--hover;
            border: 2px solid @button-primary__background--hover;
        }
    }

    .mq(l; {
        .action.search:after {
            display: none;
        }
        .control {
            &:after {
                content: '';
                .svg-search-icon();
                .svg-search-icon-dims();
                display: block;
                position: absolute;
                top: 7px;
                right: 98px;
            }
        }
    });

    .control {
        border-top: 0;
    }

    .label {
        display: none;
    }

    .mq(l; {
        min-width: 390px;
    });

    .mq(- l; {
        .action.search {
            padding: 0 25px;
            > span {
                display: none;
            }
        }

        input {
            padding-right: 60px;
        }
    });
}

#minisearch-autocomplete-top-search {
    border: 1px solid #b9c4cd;
    background-color: white;
    padding: .5em;
}
.copyright{
    display: none !important;
}

