
//
//  Switcher
//  ---------------------------------------------

.page-header,
.page-footer {
    .switcher {
        .options {
            .lib-bluebird-dropdown(
                @dropdown-toggle-selector: ~'.action.toggle',
                @dropdown-options-selector: ~'ul.dropdown',
                @dropdown-arrow__border-color: transparent transparent white transparent
            );

            ul.dropdown {
                li {
                    &:hover {
                        background: inherit;
                        text-decoration: underline;
                    }

                    a.switcher__link {
                        .lib-css(display, flex);
                        .lib-css(align-items, center);
                        padding: 5px;
                    }

                    .switcher__lang {
                        margin-left: @indent__s;
                    }
                }
            }
        }
    }
}

.switcher__image {
    width: 28px;
    height: 20px;
    display: inline-block;
    background-size: cover;

    vertical-align: bottom;
    .lib-css(border-radius, 4px);
}

.switcher-trigger {
    .switcher__image {
        margin-top: 4px;
    }
}
