
//
//  Layout variables
//  _____________________________________________

//  Variables used for layout grid
@total-columns:                         12;
@gutter-width:                          0;

//  Variables for layout columns
@layout-column__width:                  @total-columns;
@layout-column__sidebar-width:          3;
@layout-column__left-width:             @layout-column__sidebar-width;
@layout-column__right-width:            @layout-column__sidebar-width;

//  Variables for layout columns depending on layout used
@layout-column-main__width-1:           100%;
@layout-column-main__width-2-left:      @layout-column__width - @layout-column__left-width;
@layout-column-main__width-2-right:     @layout-column__width - @layout-column__right-width;
@layout-column-main__width-3:           @layout-column__width - @layout-column__left-width - @layout-column__right-width;

//  Checkout columns width
@layout-column-checkout__width-left:    3;
@layout-column-checkout__width-main:    @layout-column__width - @layout-column-checkout__width-left;

@layout-column-main__sidebar-offset:        30px;//@grid-gutter-width/2;
@layout-column__additional-sidebar-offset:  @layout-column-main__sidebar-offset;

//
//
//  _____________________________________________

.columns {
    #lib-layout-columns();

    .main {
        .lib-vendor-prefix-flex-grow(1);
        .lib-vendor-prefix-flex-basis(100%);
        .lib-vendor-prefix-order(2);
        // slick js height fix
        min-width: 0;
        min-height: 0;
    }

    .sidebar {
        .lib-vendor-prefix-flex-grow(1);
        .lib-vendor-prefix-flex-basis(100%);
        .lib-vendor-prefix-order(1);
    }

    .sidebar-additional {
        .lib-vendor-prefix-order(2);
    }
}

.sidebar {
    + .sidebar {
        margin-top: @indent__base;
    }

}

.mq(m; {
    .columns {
        display: block;

        .column.main {
            #lib-layout-columns > .main();
            min-height: 300px;

            .page-layout-2columns-left & {
                padding-left: @layout-column-main__sidebar-offset;
            }

            .page-layout-2columns-right & {
                padding-right: @layout-column-main__sidebar-offset;
            }

            .page-layout-3columns & {
                float: left;
            }
        }

        .sidebar {
            #lib-layout-columns > .left();
            padding-right: @layout-column-main__sidebar-offset;

            .page-layout-2columns-left & {
                clear: left;
                padding-right: 0;
            }

            .page-layout-2columns-right & {
                padding-right: 0;
                padding-left: @layout-column-main__sidebar-offset;
            }
        }

        .sidebar-additional {
            .page-layout-3columns & {
                padding-right: 0;
                padding-left: @layout-column-main__sidebar-offset;
            }
        }
    }
});
