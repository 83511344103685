
//
//  Widget Product Grid
//  ---------------------------------------------

.widget.block-products-list,
.widget.block-viewed-products-grid {
    .block-title {
        strong{
            .lib-bluebird-heading(h2);
        }
    }

    .products-grid {
        margin-top: 30px;
    }

    .product-items.widget-product-grid {
        display: block;
        margin: 0 -@indent__s;
        .lib-clearfix();

        .product-item {
            position: relative;
            width: 25%;
            margin: 0 0 @indent__base;
            padding: 0 @indent__s;
            display: block;
            float: left;

            .mq(- l; {
                width: 50%;
                margin-bottom: @indent__base;
            });

            .mq(- xs; {
                width: 100%;
            });

            .product-item-info {
                &:hover {
                    background: none;
                    box-shadow: none;
                    border: none;
                    margin: 0px;
                    padding: 0px;
                }
            }

            .product-image-container {
                position: relative;
                border: 1px solid @border-color__base;

                box-sizing: border-box;
                -moz-box-sizing: border-box;

                &:hover {
                    box-shadow: 3px 3px 10px -4px rgba(0,0,0,0.75);
                }
            }

            .product-image-wrapper {
                background: white;
            }

            .product-image-photo {
                position: absolute;
            }

        }
    }
}

.widget.block-viewed-products-grid {
    .product-item {
        .price-box {
            height: 50px;
        }
    }
}

.cms-index-index {
    .widget.block-products-list {
        .product-items.widget-product-grid {
            .product-item {
                &:first-child,
                &:last-child {
                    .product-image-container {
                        &:before {
                            content: "";
                            display: block;
                            height: 45px;
                            width: 30px;
                            position: absolute;
                            z-index: -1;
                        }
                    }
                }

                &:first-child {
                    .product-image-container {
                        &:before {
                            background: @primary__color;
                            bottom: -15px;
                            left: -15px;
                            border-bottom-right-radius: 10px;

                            .mq(- l; {
                                display: none;
                            });
                        }
                    }
                }

                &:last-child {
                    .product-image-container {
                        &:before {
                            background: @secondary__color;
                            top: -15px;
                            right: -15px;
                            border-top-right-radius: 15px;

                            .mq(- l; {
                                display: none;
                            });
                        }
                    }
                }
            }
        }
    }
}
