.navigation {
    .mq(m; {
        align-items: center;
        background-color: @navigation__background;
        display: flex;
        font-size: @font-size__m;
        font-family: @primary__font-family;
        padding: 0 @indent__base;
    });

    ul.navigation-wrapper {
        .mq(m; {
            width: 100%;
        });

        li {
            &.level0 {
                .mq(m; {
                    margin: 0 @indent__sm 0 0;
                    position: static;
                });

                &:not(.ui-menu-item) {
                    display: none;
                }

                &.parent,
                &.has-submenu {
                    &:hover {
                        > a.level-top {
                            span {
                                &::after {
                                    .mq(m; {
                                        top: -2px;
                                        transform: rotate(180deg);
                                    });
                                }
                            }
                        }
                        > .submenu {
                            .mq(m; {
                                display: flex;
                                flex-wrap: wrap;
                                padding: @indent__m;
                            });

                            &.default-menu__submenu,
                            &.full-width-menu__submenu {
                                > li {
                                    .mq(m; {
                                        width: 100%;
                                    });

                                    .default-menu__container,
                                    .full-width-menu__container {
                                        .mq(m; {
                                            columns: 4;
                                        });

                                        > div:not(.default-menu__static):not(.full-width-menu__static) {
                                            .mq(m; {
                                                break-inside: avoid-column;
                                            });
                                        }

                                        .default-menu__static,
                                        .full-width-menu__static {
                                            .mq(m; {
                                                break-inside: avoid;
                                                width: 400%; // To cover 4 columns
                                            });
                                        }
                                    }
                                }
                            }

                            &:not(.default-menu__submenu):not(.full-width-menu__submenu) {
                                > li {
                                    .mq(m; {
                                        flex: 0 0 25%;
                                    });
                                }
                            }
                        }
                    }

                    > a.level-top {
                        .mq(m; {
                            padding-right: @indent__sm;
                        });

                        span {
                            .mq(m; {
                                .lib-icon-font(
                                    @icon-down,
                                    @_icon-font: 'luma-icons',
                                    @_icon-font-line-height: 20px,
                                    @_icon-font-size: @font-size__xs,
                                    @_icon-font-position: after
                                );
                            });

                            &::after {
                                .mq(m; {
                                    font-weight: @font-weight__bold;
                                    position: relative;
                                    right: -@indent__xs;
                                });
                            }
                        }
                    }
                }

                &.active {
                    > a.level-top {
                        .mq(m; {
                            border-bottom: none;
                            color: @color-black;
                        });
                    }
                }

                > a.level-top {
                    .mq(m; {
                        color: @primary__color;
                        font-weight: @base__font-weight;
                        line-height: 6.6rem;
                    });

                    &:hover {
                        .mq(m; {
                            color: @primary__color;
                        });
                    }
                }

                > ul.submenu {
                    .mq(m; {
                        width: 100%;
                    });

                    > li {
                        &.ui-menu-item {
                            .mq(m; {
                                display: none;
                            });
                        }
                        h2,
                        h3,
                        h4,
                        h5 {
                            > a {
                                .mq(m; {
                                    color: @primary__color;
                                    padding: 0;
                                });
                            }
                        }

                        h2,
                        h3,
                        h4,
                        h5,
                        &.parent > a {
                            .mq(m; {
                                border-bottom: 1px solid @quaternary__color;
                                font-style: normal;
                            });
                        }

                        &.active,
                        &.level1.active {
                            > a {
                                .mq(m; {
                                    border-left: none;
                                    color: @color-black;
                                });
                            }
                        }

                        h2,
                        h3,
                        h4,
                        h5,
                        &.level1 > a {
                            .mq(m; {
                                color: @primary__color;
                                font-size: @font-size__l;
                                font-weight: @font-weight__bold;
                                margin: 0 @indent__base @indent__s;
                                padding: @indent__s 0;
                            });
                        }

                        a {
                            .mq(m; {
                                color: @text__color--grey;
                                transition: color 200ms ease-out;
                            });

                            &:hover {
                                .mq(m; {
                                    background: none;
                                    color: @color-black;
                                });
                            }
                        }

                        ul {
                            .mq(m; {
                                border: none;
                                box-shadow: none;
                                display: block;
                                margin-bottom: @indent__s;
                                position: static;
                            });

                            > li {
                                .mq(m; {
                                    display: block;
                                });

                                &.parent {
                                    > a {
                                        .mq(m; {
                                            font-weight: @font-weight__heavier;
                                            padding: @indent__s @indent__base;
                                        });
                                    }
                                }

                                &.active {
                                    > a {
                                        .mq(m; {
                                            border-left: none;
                                            color: @color-black;
                                        });
                                    }
                                }

                                &:not(.parent) {
                                    .mq(m; {
                                        padding: @indent__xs @indent__base;
                                    });

                                    > a {
                                        position: relative;
                                        padding-left: @indent__base;

                                        .mq(m; {
                                            .lib-icon-font(
                                                @icon-next,
                                                @_icon-font: 'luma-icons',
                                                @_icon-font-line-height: 20px,
                                                @_icon-font-margin: 0 @indent__xs 0 0,
                                                @_icon-font-size: @font-size__xs,
                                                @_icon-font-position: before
                                            );
                                        });

                                        &::before {
                                            position: absolute;
                                            left: 0;
                                            top: 3px;
                                        }
                                    }

                                    > * {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
