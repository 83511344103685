
//
//  Footer Columns
//  ---------------------------------------------

.page-footer{
    background: @tertiary__color;
    font-size: @font-size__m;

    .page-footer__content{
        color: @primary__color;
        .lib-clearfix();

        .footer.content {
            .lib-clearfix();
            padding: @indent__xl 0;
        }

        .footer__columns {
            .lib-css(display, flex);
            .lib-css(justify-content, space-between);

            .mq(- l; {
                .lib-css(flex-wrap, wrap);
            });

            .mq(- s; {
                .lib-css(flex-direction, column);
            });
        }

        .footer__col{
            .lib-css(flex, 1 1 100%);

            a{
                cursor: pointer;
            }

            p{
                margin-bottom: 30px;
                line-height: 1.8;

                .mq(- s; {
                    margin-bottom: 15px;
                });
            }

            h4{
                font-size: @h4__font-size;
                font-weight: @font-weight__bold;
                font-family: @secondary__font-family;
                margin-top: 0;
                margin-bottom: @indent__base;

                .mq(- s; {
                    margin-bottom: @indent__s;
                });
            }

            svg{
                margin-right: @indent__s;
                font-size: @font-size__l;
            }

            ul {
                li {
                    padding: 3px 0;

                    &:before {
                        display: inline-block;
                        content: '';
                        .svg-arrow-right-icon-blue();
                        .svg-arrow-right-icon-blue-dims();
                        margin-right: @indent__s;
                    }

                    a {
                        color: @primary__color;
                    }
                }
            }

            .mq( l; {
                &.footer__col--third {
                    .lib-css(flex, 1 1 150%);
                }
            });

            .mq(- l; {
                .lib-css(flex, 1 1 50%);
                .lib-css(width, 50%);
                margin-bottom: @indent__base;

                &:last-child {
                    margin-bottom: 0;
                }
            });

            .mq(- s; {
                .lib-css(flex, 1 1 100%);
                .lib-css(width, 100%);

                &.footer__col--third {
                    margin-bottom: 40px;
                }
            });


            .mq(- xs; {
                &.footer__col--fourth {
                    text-align: center;
                }
            });
        }

        .footer__col--no-title {
            padding-top: 44px;

            .mq(- s; {
                padding-top: 0;
            });
        }
    }

}
