
//
//  Settings
//  ---------------------------------------------

@fieldset-label__font-family: @secondary__font-family;
@fieldset-label__color: @primary__color;
@fieldset-label__font-weight: @font-weight__bold;
@fieldset-label__font-size: @font-size__m;
@fieldset-label__margin: 0 0 2px;

@fieldset-field__margin: 0 0 @indent__s;

@fieldset-label-required__color: @primary__color;
@fieldset-label-required__margin: 0 0 0 2px;
@fieldset-label-required__font-size: @font-size__m;


//
//  Forms
//  ---------------------------------------------

// Label
.fieldset>.field>.label,
.fieldset>.fields>.field>.label {
    font-family: @fieldset-label__font-family;
    color: @fieldset-label__color;
    font-weight: @fieldset-label__font-weight;
    font-size: @fieldset-label__font-size;
    margin: @fieldset-label__margin;
}

// Field
.fieldset>.field,
.fieldset>.fields>.field {
    margin: @fieldset-field__margin;
}

// Required
.fieldset>.field.required>.label:after,
.fieldset>.fields>.field.required>.label:after,
.fieldset>.field._required>.label:after,
.fieldset>.fields>.field._required>.label:after {
    color: @fieldset-label-required__color;
    margin: @fieldset-label-required__margin;
    font-size: @fieldset-label-required__font-size;
}

.field .control.has-tooltip {
    position: relative;
    padding-right: 30px;
}




//
//  File uploader
//  ---------------------------------------------

.file-uploader {
    input[type="file"] {
        padding: @indent__s @indent__base;
    }
}

.fieldset {
    .error-message {
        color: @error__color;
        font-size: 1.2rem;
    }
}
