//
//  Quick Order
//  ---------------------------------------------

.quickorder-index-index {

    .ui-front {
        z-index: 1;
    }

    > .loading-mask {
        display: none !important;
    }

    .product-length-attributes {
        display: flex;
        flex-direction: column;
    }

    .box-upload {
        margin-bottom: 30px;
    }

    .fieldset .fields .actions-toolbar {
        top: 18px;
        .mq(s; { position: absolute; right: 0; top: 9px; });
    }

    .fields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .length,
        .sku {
            padding-right: @indent__s;
        }

        .qty {
            width: 100px;
            display: inline-block;
        }

        .length {
            width: 240px;
        }
    }

    .action.remove:hover {
        color: @primary__color;
    }

    .upload-container {
        .action-upload {
            cursor: pointer;
        }
    }

    .product-block:not(:empty) {
        border-radius: 20px;
        margin-top: 4px;
        overflow: hidden;
        border: none;
    }
}

.column {
    .sku {
        margin-right: 0;
        padding-right: 0;
    }

    .fieldset > .field, .fieldset {
        .fields.deletable-item {
            margin-bottom: 15px;
            z-index: 1;

            input:disabled {
                background-color: #f5f5f5;
            }

            .mq(- s; {
                display: flex;
                flex-direction: column;
            });
        }
    }

    .qty {
        .qty {
            padding: 0 @indent__s;
        }
    }
}
