.svg-common() {
	background: url("../svg/dist/css/css-sprite.svg") no-repeat;
}

.svg-account-icon() {
.svg-common();
background-position: 0 0;
}

.svg-account-icon-dims() {
	width: 23px;
height: 27px;
}

.svg-arrow-down-icon() {
.svg-common();
background-position: 0 4.838709677419355%;
}

.svg-arrow-down-icon-dims() {
	width: 18px;
height: 21px;
}

.svg-arrow-down-icon-blue() {
.svg-common();
background-position: 0 8.602150537634408%;
}

.svg-arrow-down-icon-blue-dims() {
	width: 18px;
height: 21px;
}

.svg-arrow-icon() {
.svg-common();
background-position: 0 12.062937062937063%;
}

.svg-arrow-icon-dims() {
	width: 10px;
height: 7px;
}

.svg-arrow-next-icon() {
.svg-common();
background-position: 0 13.403880070546737%;
}

.svg-arrow-next-icon-dims() {
	width: 14px;
height: 12px;
}

.svg-arrow-next-icon-blue() {
.svg-common();
background-position: 0 15.520282186948853%;
}

.svg-arrow-next-icon-blue-dims() {
	width: 14px;
height: 12px;
}

.svg-arrow-right-icon-blue() {
.svg-common();
background-position: 0 17.513134851138354%;
}

.svg-arrow-right-icon-blue-dims() {
	width: 6px;
height: 8px;
}

.svg-cart-icon() {
.svg-common();
background-position: 0 19.529837251356238%;
}

.svg-cart-icon-dims() {
	width: 40px;
height: 26px;
}

.svg-cart-icon-white() {
.svg-common();
background-position: 0 24.057450628366247%;
}

.svg-cart-icon-white-dims() {
	width: 34px;
height: 22px;
}

.svg-check-icon-blue() {
.svg-common();
background-position: 0 27.41652021089631%;
}

.svg-check-icon-blue-dims() {
	width: 13px;
height: 10px;
}

.svg-check-icon-green() {
.svg-common();
background-position: 0 29.173989455184536%;
}

.svg-check-icon-green-dims() {
	width: 13px;
height: 10px;
}

.svg-chevron-left() {
.svg-common();
background-position: 0 31.941923774954628%;
}

.svg-chevron-left-dims() {
	width: 17px;
height: 28px;
}

.svg-chevron-left-blue() {
.svg-common();
background-position: 0 37.023593466424686%;
}

.svg-chevron-left-blue-dims() {
	width: 17px;
height: 28px;
}

.svg-chevron-right() {
.svg-common();
background-position: 0 42.10526315789474%;
}

.svg-chevron-right-dims() {
	width: 17px;
height: 28px;
}

.svg-chevron-right-blue() {
.svg-common();
background-position: 0 47.186932849364794%;
}

.svg-chevron-right-blue-dims() {
	width: 17px;
height: 28px;
}

.svg-compare-icon() {
.svg-common();
background-position: 0 51.15452930728242%;
}

.svg-compare-icon-dims() {
	width: 21px;
height: 16px;
}

.svg-compare-icon-lightblue() {
.svg-common();
background-position: 0 53.99644760213144%;
}

.svg-compare-icon-lightblue-dims() {
	width: 21px;
height: 16px;
}

.svg-delete-icon() {
.svg-common();
background-position: 0 57.24508050089445%;
}

.svg-delete-icon-dims() {
	width: 20px;
height: 20px;
}

.svg-delete-icon-blue() {
.svg-common();
background-position: 0 60.82289803220036%;
}

.svg-delete-icon-blue-dims() {
	width: 20px;
height: 20px;
}

.svg-delete-icon-red() {
.svg-common();
background-position: 0 63.2688927943761%;
}

.svg-delete-icon-red-dims() {
	width: 13px;
height: 10px;
}

.svg-list-icon() {
.svg-common();
background-position: 0 67.02898550724638%;
}

.svg-list-icon-dims() {
	width: 19px;
height: 27px;
}

.svg-long-arrow-right-icon() {
.svg-common();
background-position: 0 69.77152899824253%;
}

.svg-long-arrow-right-icon-dims() {
	width: 12px;
height: 10px;
}

.svg-long-arrow-right-icon-lightblue() {
.svg-common();
background-position: 0 71.52899824253076%;
}

.svg-long-arrow-right-icon-lightblue-dims() {
	width: 12px;
height: 10px;
}

.svg-menu-icon-blue() {
.svg-common();
background-position: 0 74.59749552772809%;
}

.svg-menu-icon-blue-dims() {
	width: 28px;
height: 20px;
}

.svg-menu-icon-darkblue() {
.svg-common();
background-position: 0 78.17531305903398%;
}

.svg-menu-icon-darkblue-dims() {
	width: 28px;
height: 20px;
}

.svg-menu-icon-white() {
.svg-common();
background-position: 0 81.7531305903399%;
}

.svg-menu-icon-white-dims() {
	width: 28px;
height: 20px;
}

.svg-remove-icon() {
.svg-common();
background-position: 0 84.12698412698413%;
}

.svg-remove-icon-dims() {
	width: 12px;
height: 12px;
}

.svg-search-icon() {
.svg-common();
background-position: 0 88.58695652173913%;
}

.svg-search-icon-dims() {
	width: 26px;
height: 27px;
}

.svg-search-icon-white() {
.svg-common();
background-position: 0 92.47311827956989%;
}

.svg-search-icon-white-dims() {
	width: 21px;
height: 21px;
}

.svg-trash-icon() {
.svg-common();
background-position: 0 96.23655913978494%;
}

.svg-trash-icon-dims() {
	width: 18px;
height: 21px;
}

.svg-trash-icon-darkblue() {
.svg-common();
background-position: 0 100%;
}

.svg-trash-icon-darkblue-dims() {
	width: 18px;
height: 21px;
}

