// Pagebuilder Button
a.pagebuilder-button-primary,
button.pagebuilder-button-primary,
div.pagebuilder-button-primary {
    padding: @indent__s 16px;
    background-color: @success__color;
    border: 1px solid @success__color;
    border-radius: 20px;
    cursor: pointer !important;
    font-weight: @font-weight__bold;

    &:focus {
      background: @success__color;
      border: 1px solid @success__color;
      color: #ffffff;
    }

    &:active {
      background: @success__color;
      border: 1px solid @success__color;
      color: #ffffff;
    }

    &:visited {
      background: @success__color;
      border: 1px solid @success__color;
      color: #ffffff;
    }

    &:hover {
      background: #3ea30c;
      border: 1px solid #3ea30c;
      color: #ffffff;
    }
}

// Headings
[data-content-type='text'] {
    h2 {
        font-size: 2rem;
        color: #0D314D;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    h3 {
        font-size: @font-size__s;
        color: #0D314D;
    }

    h4 {
        font-size: @font-size__s;
        font-style: italic;
        color: #0D314D;
    }
}

h2[data-content-type='heading'] {
    font-size: 2rem;
    color: #0D314D;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h3[data-content-type='heading'] {
    font-size: @font-size__s;
    color: #0D314D;
}

h4[data-content-type='heading'] {
    font-size: @font-size__s;
    font-style: italic;
    color: #0D314D;
}

.pagebuilder-column {
    [data-content-type='products'] {
        .product-item {

            .product-item-details{

                .product-item-name {
                    margin: 15px 0 @indent__s;

                    a {
                        font-size: @font-size__xl;
                        font-family: @secondary__font-family;
                        color: @text__color;

                        &:hover {
                            text-decoration: none;
                            color: @secondary__color;
                        }
                    }
                }

                .product-item-actions {
                    display: block;

                    .actions-primary {
                        display: block;

                        .tocart {
                            .btn-cart();
                            .btn-responsive();
                            padding-left: 0;
                            padding-right: 0;
                            text-align: center;
                            max-width: 320px;
                        }

                        .tocart:before{
                            display: none;
                        }

                        .action.primary:not(.tocart) {
                            .btn-success();
                            .btn-responsive();
                            max-width: 320px;
                        }
                    }

                    .actions-secondary{
                        display: none;
                    }
                }
            }

            .product-item-photo {
                display: block;
            }

            .product-reviews-summary {
                display: none;
            }

            .lib-product-price-box(catalog);

            // Bundle product prices
            .price-box {
                .price-to {
                    display: none;
                }

                .price-from {
                    .price-label {
                        order: 1;
                        color: @secondary__color;
                        font-weight: @font-weight__bold;

                        &:after {
                            content: ':';
                        }
                    }
                }
            }
        }
    }
}

[data-content-type='row'][data-appearance='contained']{
    padding: 0 @indent__base;
}
