
//
//  Stores
//  ---------------------------------------------

.stores {
    color: @primary__color;
    font-size: @font-size__m;
    padding: 0;
    margin: @indent__s 0 0;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin-bottom: 0.5rem;
        }
    }

    a,
    a:visited,
    a:hover {
        color: @primary__color;
    }
}

.stores__title {
    font-family: @secondary__font-family;
    font-size: 2.2rem;
    font-weight: @font-weight__bold;
    display: block;
    margin-bottom: 0.5rem;
}

.stores__stock {
    font-weight: @font-weight__bold;

    &:before {
        content: ':';
        color: @primary__color;
        margin: 0 4px 0 0;
        font-weight: normal;
    }

    &.stores__stock--available {
        color: @success__color;
    }

    &.stores__stock--unavailable {
        color: @success__color;
    }
}

.stores__list {
    position: relative;
    min-height: 30px;
}

.stores__loader {
    .lib-bluebird-loader();
}

.delivery-label + .stores {
    .stores__loader {
        display: none;
    }
}
