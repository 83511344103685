
//
//  Brand Slider
//  ---------------------------------------------


.brand-slider{
    .slick-track {
        display: flex;
        align-items: center;
    }

    ul {
        position: relative;
        padding: 0;
        margin: 30px 0 0;
        display: none;
        list-style-type: none;

        &.slick-initialized {
            display: block;
        }
    }

    .brand-slider-nav{
        position: absolute;
        top: 50%;
        margin-top: -14px;
    }

    .brand-slider-prev{
        .svg-chevron-left();
        .svg-chevron-left-dims();
        left: 0;

        &:hover{
            .svg-chevron-left-blue();
            .svg-chevron-left-blue-dims();
        }
    }

    .brand-slider-next{
        .svg-chevron-right();
        .svg-chevron-right-dims();
        right: 0;

        &:hover{
            .svg-chevron-right-blue();
            .svg-chevron-right-blue-dims();
        }
    }

    .slick-list {
        overflow: hidden;
        text-align: center;
        margin: 0 40px;
    }

    .slick-slide{
        list-style-type: none;
        display: inline-block;

        padding: 0 25px;
        box-sizing: border-box;

        img{
            vertical-align: middle;
            max-width: 120px;
        }
    }
}