//
//  Negotiable Quote
//  ---------------------------------------------

.quote-status {
  .lib-bluebird-status();
}

.quote-actions-toolbar {
  #deleteLink {
    display: none;
  }
}
