
//
//  Settings
//  ---------------------------------------------

@dropdown-arrow__border-width: 12px;
@dropdown-arrow__border-color: transparent transparent @primary__color transparent;
@dropdown-arrow__top: -24px;
@dropdown-arrow__right: 22px;
@dropdown-arrow__left: auto;

@dropdown__min-width: 0;
@dropdown__right: 0;
@dropdown__top: 35px;
@dropdown__border: 0;
@dropdown__border-radius: 6px;
@dropdown__padding: @indent__s;
@dropdown__box-shadow: 0 3px 10px rgba(0,0,0,.3);

@dropdown-toggle-selector: ~'.dropdown__trigger';
@dropdown-options-selector: ~'.dropdown__content';
@dropdown-header-selector: ~'.dropdown__header';
@dropdown-body-selector: ~'.dropdown__body';

@dropdown-header__background: @primary__color;
@dropdown-header__color: white;
@dropdown-header__padding: 17px @indent__base;
@dropdown-header__font-size: 22px;
@dropdown-header__font-weight: @font-weight__bold;
@dropdown-header__font-family: @secondary__font-family;

@dropdown-content__padding: @dropdown-header__padding;



//
//  Dropdown
//  ---------------------------------------------

.lib-bluebird-dropdown(
    @dropdown-toggle-selector: @dropdown-toggle-selector,
    @dropdown-options-selector: @dropdown-options-selector,
    @dropdown-header-selector: @dropdown-header-selector,
    @dropdown-body-selector: @dropdown-body-selector,
    @dropdown-override: true,
    @dropdown-has-header: false,
    @dropdown-arrow__border-width: @dropdown-arrow__border-width,
    @dropdown-arrow__border-color: @dropdown-arrow__border-color,
    @dropdown-arrow__top: @dropdown-arrow__top,
    @dropdown-arrow__right: @dropdown-arrow__right,
    @dropdown-arrow__left: @dropdown-arrow__left,
    @dropdown__min-width: @dropdown__min-width,
    @dropdown__right: @dropdown__right,
    @dropdown__top: @dropdown__top,
    @dropdown__border: @dropdown__border,
    @dropdown__border-radius: @dropdown__border-radius,
    @dropdown__padding: @dropdown__padding,
    @dropdown__box-shadow: @dropdown__box-shadow,
    @dropdown-header__background: @dropdown-header__background,
    @dropdown-header__color: @dropdown-header__color,
    @dropdown-header__padding: @dropdown-header__padding,
    @dropdown-header__font-size: @dropdown-header__font-size,
    @dropdown-header__font-weight: @dropdown-header__font-weight,
    @dropdown-header__font-family: @dropdown-header__font-family,
    @dropdown-content__padding: @dropdown-content__padding
) {

    & when(@dropdown-override = true) {
        .lib-dropdown(
            @_toggle-selector: @dropdown-toggle-selector,
            @_options-selector: @dropdown-options-selector
        );
    }

    @{dropdown-toggle-selector} {
        &:after,
        &.active:after {
            display: inline-block;
            content: '';
            .svg-arrow-icon();
            .svg-arrow-icon-dims();
            margin-left: 6px;
            //margin-top: 7px;
            vertical-align: middle;
        }

        &.active:after {
            transform: rotate(180deg);
        }
    }

    @{dropdown-options-selector} {
        .lib-css(min-width, @dropdown__min-width);
        .lib-css(right, @dropdown__right);
        .lib-css(top, @dropdown__top);
        .lib-css(border, @dropdown__border);
        .lib-css(border-radius, @dropdown__border-radius);
        .lib-css(box-shadow, @dropdown__box-shadow);

        & when (@dropdown-has-header = false) {
            .lib-css(padding, @dropdown__padding);
        }

        &:before {
            .lib-css(border, @dropdown-arrow__border-width solid);
            .lib-css(border-color, @dropdown-arrow__border-color);
            .lib-css(top, @dropdown-arrow__top);
            .lib-css(left, @dropdown-arrow__left);
            .lib-css(right, @dropdown-arrow__right);
        }

        &:after {
            display: none;
        }

        & when (@dropdown-has-header = true) {
            .lib-css(padding, 0);

            @{dropdown-header-selector} {
                background: @dropdown-header__background;
                color: @dropdown-header__color;
                padding: @dropdown-header__padding;
                font-size: @dropdown-header__font-size;
                font-weight: @dropdown-header__font-weight;
                font-family: @dropdown-header__font-family;
                .lib-css(border-top-left-radius, @dropdown__border-radius);
                .lib-css(border-top-right-radius, @dropdown__border-radius);
            }

            @{dropdown-body-selector} {
                color: @text__color;
                padding: @dropdown-content__padding;
            }
        }
    }

}




//
//  Settings
//  ---------------------------------------------

@dropdown-close__top: 28px;
@dropdown-close__right: 5px;

//
//  Dropdown Close
//  ---------------------------------------------

.lib-bluebird-dropdown-close(
    @dropdown-close__top: @dropdown-close__top,
    @dropdown-close__right: @dropdown-close__right
) {
    z-index: 10;
    position: absolute;
    top: @dropdown-close__top;
    right: @dropdown-close__right;
    box-shadow: none;
    background: transparent;
    border: 0;

    .ui-button-text {
        display: none;
    }

    &:after {
        display: block;
        content: '';
        .svg-delete-icon();
        .svg-delete-icon-dims();
    }

    &:hover {
        box-shadow: none;
        background: transparent;
        border: 0;

        &:after {
            .svg-delete-icon-blue();
            .svg-delete-icon-blue-dims();
        }
    }
}
