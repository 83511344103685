.minicart-items {
    &-wrapper {
        .product-item {
            .product {
                &-item-inner {
                    align-items: flex-start;
                    display: flex;
                }
            }
        }
    }
}
