
//
//  Toolbar
//  ---------------------------------------------
.toolbar-products{
    text-align: left;

    .toolbar-amount{
        display: none;
    }

    label{
        font-family: @fieldset-label__font-family;
        color: @fieldset-label__color;
        font-weight: @fieldset-label__font-weight;
        font-size: @fieldset-label__font-size;
        margin: @fieldset-label__margin;
    }

    select{
        background-color: @color-white;
        height: 37px;
        width: 140px;
        padding: 0 0 0 12px;
    }

    .toolbar-sorter{
        float: left;
        position: relative;
        right: auto;
        top: auto;

        .mq(- xs; {
            width: 100%;
            margin-top: @indent__s;

            .sorter-options {
                width: 120px;
            }
        });

        .sort-asc{
            &:before{
                .svg-arrow-down-icon();
                .svg-arrow-down-icon-dims();
                width: 18px;
                height: 21px;
                color: transparent;
                margin-left: @indent__s;
            }

            &:hover{
                &:before{
                    .svg-arrow-down-icon-blue();
                    .svg-arrow-down-icon-blue-dims();
                }
            }
        }

        .sort-desc{
            &:before{
                .svg-arrow-down-icon();
                .svg-arrow-down-icon-dims();
                transform: rotate(180deg);
                width: 18px;
                height: 21px;
                color: transparent;
                margin-left: @indent__s;
            }

            &:hover{
                &:before{
                    .svg-arrow-down-icon-blue();
                    .svg-arrow-down-icon-blue-dims();
                    transform: rotate(180deg);
                }
            }
        }
    }

    .pages{
        width: 100%;
        text-align: center;

        .pages-item-next,
        .pages-item-previous {
            background: none;

            span.label{
                display: none;
            }

            span{
                width: auto;
                position: relative;
                height: auto;

                font-family: @fieldset-label__font-family;
                color: @fieldset-label__color;
                font-weight: @fieldset-label__font-weight;
                font-size: @fieldset-label__font-size;
                margin: @fieldset-label__margin;
            }

            .action{
                &:hover{
                    span{
                        color: @secondary__color;
                    }
                }
            }
        }

        .pages-item-next .action,
        .pages-item-previous .action{
            border: none;
            background: none;
            line-height: @font-size__m;
            .lib-css(box-shadow, none);

            &:after{
                position: relative;
                width: auto;
                height: auto;
            }
        }

        .pages-item-next{
            .action.next{
                &:after{
                    .svg-arrow-next-icon();
                    .svg-arrow-next-icon-dims();
                    display: inline-block;
                    content: '';
                    margin-left: @indent__s;
                }

                &:before{
                    display: none;
                }

                &:hover{
                    &:after {
                        .svg-arrow-next-icon-blue();
                        .svg-arrow-next-icon-blue-dims();
                    }
                }
            }
        }

        .pages-item-previous{
            .action.previous{
                &:before{
                    .svg-arrow-next-icon();
                    .svg-arrow-next-icon-dims();
                    transform: rotate(180deg);
                    content: '';
                    margin-right: @indent__s;
                }

                &:hover{
                    &:before{
                        .svg-arrow-next-icon-blue();
                        .svg-arrow-next-icon-blue-dims();
                    }
                }
            }
        }

        .item,
        a.page{
            font-family: @fieldset-label__font-family;
            color: @fieldset-label__color;
            font-weight: @fieldset-label__font-weight;
            font-size: @fieldset-label__font-size;
            margin: @fieldset-label__margin;

            &:active{
                color: @fieldset-label__color;
            }

            &:visited{
                color: @fieldset-label__color;
            }

            &:hover{
                color: @secondary__color;
            }
        }

        .item.current strong.page{
            font-family: @fieldset-label__font-family;
            color: @secondary__color;
            font-weight: @fieldset-label__font-weight;
            font-size: @fieldset-label__font-size;
            margin: @fieldset-label__margin;
            background: none;
        }
    }
}

.page-products{
    .toolbar-products {
        .limiter {
            display: block;
            float: right;

            .mq(- xs; {
                width: 100%;
            });

            select{
                width: 60px;
            }

            .limiter-text{
                display: none;
            }
        }

        &:last-child{
            .limiter{
                display: none;
            }
        }
    }
}

.products.wrapper ~ .toolbar {
    .field.limiter {
        display: none;
    }
}
