
//
//  Settings
//  ---------------------------------------------






//
//  Prices
//  ---------------------------------------------

.price-label {
    width: 100%;

}

.price-box.price-final_price {
    .lib-css(display, flex);
    .lib-css(flex-direction, column);
}

.old-price {
    .price-container {
        .lib-display-price(old);
    }
}

.price-tier_price {
    .prices-tier.items {
        margin: @indent__base 0 15px 0;

        .item {
            color: @primary__color;
            font-size: 1.3rem;
            margin: 0;

            .mq(- l; {
              font-size: @font-size__m;
            });

            .price-container {
                display: inline;
            }

            .price-tier_price {
                .price-wrapper {
                    display: inline;

                    .price {
                        color: @primary__color;
                        font-size: @font-size__m;
                    }
                }
            }

            .benefit {
                color: @success__color;
            }
        }
    }
}

.product-info-price {
    .benefit {
        display: none;
    }
    .prices-tier.items {
        padding: 0;
        margin: 2rem 0 0;

        .item,
        .price {
            font-size: 1.6rem !important;
            font-weight: normal !important;
        }

        .price-excluding-tax .price {
            font-size: 1.6rem !important;
            font-weight: 700 !important;
        }
    }

    .prices-tier .price-including-tax {
        display: none !important;
    }

    .prices-tier .price-container .price-including-tax + .price-excluding-tax:before {
        display: none;
    }
    .prices-tier .price-container .price-including-tax + .price-excluding-tax:last-child:after {
        content: ' 'attr(data-label);
    }
}

.product-info-main .product-info-price .price-including-tax + .price-excluding-tax {
    font-size: 1.6rem !important
}

.prod-qty {
    font-weight: 700;
}
