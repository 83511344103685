
//
//  Price Mixins
//  ---------------------------------------------

.lib-display-price (
    @_price-type: default
) {

    & when(@_price-type = default) {
        .lib-display-price-mixin(
            @price-excl-tax__font-size: 2.8rem,
            @price-incl-tax__font-size: @font-size__l,
            @price-excl-tax-suffix__font-size: @font-size__s,
            @price-incl-tax-suffix__font-size: 1.2rem
        );
    }

    & when(@_price-type = product-small) {
        .lib-display-price-mixin(
            @price-excl-tax__font-size: 2.4rem,
            @price-incl-tax__font-size: @font-size__m,
            @price-excl-tax-suffix__font-size: @font-size__s,
            @price-incl-tax-suffix__font-size: 1.2rem
        );
    }

    & when(@_price-type = catalog) {
        .lib-display-price-mixin(
            @price-excl-tax__font-size: @font-size__xl,
            @price-incl-tax__font-size: 1.5rem
        );
    }

    & when(@_price-type = minicart-item) {
        .lib-display-price-mixin(
            @price-excl-tax__font-size: @font-size__l,
            @price-incl-tax__font-size: @font-size__s,
            @price-excl-tax-suffix__font-size: 1.1rem,
            @price-incl-tax-suffix__font-size: @font-size__xs
        );
    }

    & when(@_price-type = minicart-total) {
        .lib-display-price-mixin(
            @price-excl-tax__font-size: 2.4rem,
            @price-incl-tax__font-size: @font-size__m
        );
    }

    & when(@_price-type = old) {
        .lib-display-price-mixin(
            @price-excl-tax__font-size: false,
            @price-incl-tax__font-size: false,
            @price-color: @secondary__color,
            @suffix-color: @text__color--muted,
            @price-excl-tax-suffix__font-size: false,
            @price-incl-tax-suffix__font-size: false
        );
        .lib-old-price();
    }
}





.lib-display-price-mixin (
    @price-excl-tax__font-size: 2.3rem,
    @price-incl-tax__font-size: 1.5rem,
    @price-excl-tax-suffix__font-size: 1.2rem,
    @price-incl-tax-suffix__font-size: 1.1rem,
    @price-excl-tax-after: true,
    @price-incl-tax-after: true,
    @price-color: @secondary__color,
    @suffix-color: @text__color--suffix
) {
    .lib-css(display, flex);
    .lib-css(flex-direction, column-reverse);

    .price {
        color: @price-color;
    }

    .old-price-excl-vat {
        line-height: normal;
        .price {
            color: @price-color;
            .lib-css(font-size, @price-excl-tax__font-size);
        }
    }

    .price-excluding-tax,
    .price-including-tax {
        margin: 2px 0;
    }

    .price-excluding-tax {
        .price {
            .lib-css(font-size, @price-excl-tax__font-size);
        }

        &:before {
            display: none;
        }

        &:after {
            display: none;

            & when(@price-excl-tax-after = true) {
                .lib-display-price-mixin-after(
                    @suffix-color: @suffix-color,
                    @suffix-font-size: @price-excl-tax-suffix__font-size
                );
            }
        }
    }

    .price-including-tax {
        .price {
            .lib-css(font-size, @price-incl-tax__font-size);
            color: @text__color--grey;
        }

        &:before {
            display: none;
        }

        &:after {
            display: none;

            & when(@price-incl-tax-after = true) {
                .lib-display-price-mixin-after(
                    @suffix-color: @suffix-color,
                    @suffix-font-size: @price-incl-tax-suffix__font-size
                );
            }
        }
    }
}





.lib-display-price-mixin-after(
    @suffix-color: @text__color--suffix,
    @suffix-font-size: @font-size__m
) {
    display: inline-block;
    content: attr(data-label);
    margin-left: 5px;
    color: @suffix-color;
    .lib-css(font-size, @suffix-font-size);
    text-transform: lowercase;
}

.lib-old-price () {
    .price-label {
        display: none;
    }

    .price {
        text-decoration: line-through;
        font-weight: normal;
        color: @text__color--grey;
    }

    .price-wrapper {
        &:after {
            text-decoration: line-through;
        }
    }
}




.lib-price-box-special()
{
    .lib-css(display, flex);
    .lib-css(flex-direction, column);
    position: relative;

    .special-price {
        margin-left: 2px;
    }

    .old-price {
        margin: 0;
        .lib-css(display, flex);
        .lib-css(flex-direction, column);
        .lib-css(align-items, center);

        .price-container {
            .price-excluding-tax,
            .price-including-tax {
                &:after,
                &:before {
                    display: none;
                }
            }
        }
    }
}




.lib-product-price-box(@_price-type: default) {
    .price-box{
        margin: @indent__s 0 30px;

        .price-container {
            .lib-display-price(@_price-type);
        }

        .old-price .price-container {
            .lib-display-price(old);
        }

        // Re-position special & old price
        .lib-price-box-special();
    }
}
