
//
//  Links
//  ---------------------------------------------

.lib-table-attributes() {
    table {
        tr {
            th {
                border: none;
                width: 20%;
                min-width: 200px;
                padding: 0 0 2px 0;
                font-weight: @font-weight__heavier;
            }

            td {
                border: none;
                padding: 0 0 2px 0;
            }
        }
    }
}
