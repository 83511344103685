
//
//  Mixins
//  ---------------------------------------------

// Remove click events on minicart (for example on cart and checkout pages)
.prevent-clicking-minicart() {
    .page-header__minicart {
        .minicart-wrapper .action.showcart {
            pointer-events: none;

            .counter-number {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.lib-bluebird-loader() {
    .lib-loader();
    position: absolute;
    z-index: 1;
    background: transparent;
    right: auto;

    &:before {
        width: 30px;
        height: 30px;
        background-size: 150px;
    }
}

.elementMaxWidth () {
    max-width: @screen__max_width;
    margin: 0 auto;
}
