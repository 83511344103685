.footer__col {
  .payment-methods__container {
    width: 100%;

    ul {
      list-style: none;
      padding: 0;
      max-width: 230px;
      margin: 0 auto;
      .lib-css(display, flex);
      .lib-css(flex-direction, row);
      .lib-css(flex-wrap, wrap);
      .lib-css(justify-content, space-between);
      .lib-css(align-items, flex-end);

      .mq(m, {
        max-width: 70%;
      });

      .mq(l, {
        max-width: 100%;
      });

      li {
        margin-right: 0;

        //.mq(l, {
        //  margin-right: 20px;
        //});

        &:before {
          content: none !important;
        }
      }
    }
  }
}