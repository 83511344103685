
//
//  Media Queries
//  ---------------------------------------------

/**
// The Magento default media query variables:
@screen__xxs: 320px;
@screen__xs: 480px;
@screen__s: 640px;
@screen__m: 768px;
@screen__l: 1024px;
@screen__xl: 1440px;

 Example:
 .selector {
     ...
     .mq(sm; {
         width: 100px;
         height: 200px
     });
 }
 Output:
 .selector {
     ...
 }
 @media screen and (min-width: 768px) {
     .selector {
         width: 100px;
         height: 200px;
     }
 }
 -
 Example using max width:
 .selector {
     ...
     .mq(- sm; {
         width: 100px;
         height: 200px
     });
 }
 Output:
 .selector {
     ...
 }
 @media screen and (max-width: 767px) {
     .selector {
         width: 100px;
         height: 200px;
     }
 }
 -
 Example using both min and max width:
 .selector {
     ...
     .mq(sm md; {
         width: 100px;
         height: 200px
     });
 }
 Output:
 .selector {
     ...
 }
 @media screen and (min-width: 768px) and (max-width: 991px) {
     .selector {
         width: 100px;
         height: 200px;
     }
 }
*/

.mq(@breakpoints; @rules;) {
    // If there's only one breakpoint specified
    & when (length(@breakpoints) = 1) {
        @query: ~"(min-width: @{screen__@{breakpoints}})";
        @media screen and @query {@rules();};
    }

    // If there's two breakpoints specified
    & when (length(@breakpoints) = 2) {
        @bpMin: extract(@breakpoints, 1);
        @maxVar: extract(@breakpoints, 2);
        @tmpMax: ~"screen__@{maxVar}";
        @bpMax: (@@tmpMax - 1);

        // If the first "breakpoint" = -, then let's build a max-width query
        & when (@bpMin = -) {
            @query: ~"(max-width: @{bpMax})";
            @media screen and @query {@rules();};
        }

        // If the first breakpoint is something else than "-",
        // then let's build a min-width AND max-width query
        & when not (@bpMin = -) {
            @query: ~"(min-width: @{screen__@{bpMin}}) and (max-width: @{bpMax})";
            @media screen and @query {@rules();};
        }
    }
}