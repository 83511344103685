
//
//  Typography
//  ---------------------------------------------

body {
    font-family: @base__font-family;
    font-size: @base__font-size;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-family: @heading__font-family;
}

h1, .h1 { .lib-bluebird-heading(h1) };
h2, .h2 { .lib-bluebird-heading(h2) };
h3, .h3 { .lib-bluebird-heading(h3) };
h4, .h4 { .lib-bluebird-heading(h4) };
h5, .h5 { .lib-bluebird-heading(h5) };
h6, .h6 { .lib-bluebird-heading(h6) };

.subtitle {
    .lib-bluebird-heading(h6);
    color: @secondary__color;
}