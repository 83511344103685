//
//  Generic
//  ---------------------------------------------

* {
    box-sizing: border-box;
}

body {
    -webkit-font-smoothing: antialiased;

    &.navigation--open {
        overflow-y: hidden;
    }
}
