
//
//  Delivery Time
//  ---------------------------------------------

.delivery-label__text {
    color: @primary__color;
    font-size: @font-size__m;
    padding-top: 5px;
    line-height: 1.7;
    position: relative;
    display: block;
    min-height: 20px;
    margin-bottom: @indent__base;
}
