.icons-widget {
  background: @tertiary__color;

  .icons-widget__row {
    display: flex;
    flex-direction: column;
    padding: 80px 0;

    .mq(m, {
      flex-direction: row;
      > * {
        flex: 1 1 100%;
      }
    });
  }

  .icons-widget__icons {
    display: flex;
    flex-direction: row;

    .mq(m, {
      margin-left: 90px
    });

    .mq(- m, {
      margin-top: 4rem;
    });

    > * {
      flex: 1 1 100%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      .mq(m, {
        margin-top: auto;
        margin-bottom: auto;
      });
    }
  }

  .icons-widget__icon a {
    display: block;
    color: @text__color;

    &:hover {
      text-decoration: none;
      color: @secondary__color;

      .icon-widget__title:after {
        content: '';
        .svg-long-arrow-right-icon-lightblue();
        .svg-long-arrow-right-icon-dims();
        display: inline-block;
        position: relative;
        .lib-css(transform, none);
        margin: 0 0 0 @indent__s;
        .lib-css(transition, transform 0.3s 0s);
      }
    }
  }

  .icon-widget__title {
    font-size: @font-size__l;

    .mq(- m, {
      font-size: @font-size__m;
    });
  }

  .icons-widget__round {
    border-radius: 50%;
    background: @color-white;
    width: 100px;
    height: 100px;
    margin: 0 auto 12px;
    position: relative;


    .mq(- m, {
      width: 75px;
      height: 75px;
    });

    img {
      display: block;
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  .subtitle {
    text-transform: uppercase;

    + h2 {
      margin-top: 0;
    }
  }

  p + a.primary {
    margin-top: 16px;
  }
}
