
//
//  Grid
//  ---------------------------------------------

//** Number of columns in the grid.
@grid-columns:                  12;
//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width:             30px;

.make-grid-columns() {
    // Common styles for all sizes of grid columns, widths 1-12
    .col(@index) { // initial
        @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
        .col((@index + 1), @item);
    }
    .col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
        @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
        .col((@index + 1), ~"@{list}, @{item}");
    }
    .col(@index, @list) when (@index > @grid-columns) { // terminal
        @{list} {
            position: relative;
            // Prevent columns from collapsing when empty
            min-height: 1px;
            // Inner gutter via padding
            padding-left:  ceil((@grid-gutter-width / 2));
            padding-right: floor((@grid-gutter-width / 2));
        }
    }
    .col(1); // kickstart it
}

.float-grid-columns(@class) {
    .col(@index) { // initial
        @item: ~".col-@{class}-@{index}";
        .col((@index + 1), @item);
    }
    .col(@index, @list) when (@index =< @grid-columns) { // general
        @item: ~".col-@{class}-@{index}";
        .col((@index + 1), ~"@{list}, @{item}");
    }
    .col(@index, @list) when (@index > @grid-columns) { // terminal
        @{list} {
            float: left;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
    }
    .col(1); // kickstart it
}


.calc-grid-column(@index, @class, @type) when (@type = width) and (@index > 0) {
    .col-@{class}-@{index} {
        width: percentage((@index / @grid-columns));
    }
}
.calc-grid-column(@index, @class, @type) when (@type = push) and (@index > 0) {
    .col-@{class}-push-@{index} {
        left: percentage((@index / @grid-columns));
    }
}
.calc-grid-column(@index, @class, @type) when (@type = push) and (@index = 0) {
    .col-@{class}-push-0 {
        left: auto;
    }
}
.calc-grid-column(@index, @class, @type) when (@type = pull) and (@index > 0) {
    .col-@{class}-pull-@{index} {
        right: percentage((@index / @grid-columns));
    }
}
.calc-grid-column(@index, @class, @type) when (@type = pull) and (@index = 0) {
    .col-@{class}-pull-0 {
        right: auto;
    }
}
.calc-grid-column(@index, @class, @type) when (@type = offset) {
    .col-@{class}-offset-@{index} {
        margin-left: percentage((@index / @grid-columns));
    }
}


.reset-padding-column(@class) {
    .col(@index) { // initial
        @item: ~".col-@{class}-@{index}";
        .col((@index + 1), @item);
    }
    .col(@index, @list) when (@index =< @grid-columns) { // general
        @item: ~".col-@{class}-@{index}";
        .col((@index + 1), ~"@{list}, @{item}");
    }
    .col(@index, @list) when (@index > @grid-columns) { // terminal
        @{list} {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .col(1); // kickstart it
}


// Basic looping in LESS
.loop-grid-columns(@index, @class, @type) when (@index >= 0) {
    .calc-grid-column(@index, @class, @type);
    // next iteration
    .loop-grid-columns((@index - 1), @class, @type);
}

// Create grid for specific class
.make-grid(@class) {
    .float-grid-columns(@class);
    .loop-grid-columns(@grid-columns, @class, width);
    .loop-grid-columns(@grid-columns, @class, pull);
    .loop-grid-columns(@grid-columns, @class, push);
    .loop-grid-columns(@grid-columns, @class, offset);
}




// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
.container-fixed(@gutter: @grid-gutter-width) {
    margin-right: auto;
    margin-left: auto;
    padding-left:  floor((@gutter / 2));
    padding-right: ceil((@gutter / 2));
    .lib-clearfix();

    &:extend(.clearfix all);
}

// Creates a wrapper for a series of columns
.make-row(@gutter: @grid-gutter-width) {
    margin-left:  ceil((@gutter / -2));
    margin-right: floor((@gutter / -2));
    .lib-clearfix();
}

.make-column(@columns; @gutter: @grid-gutter-width) {
    box-sizing: border-box;
    position: relative;
    min-height: 1px;
    padding-left:  (@gutter / 2);
    padding-right: (@gutter / 2);

    float: left;
    width: percentage((@columns / @grid-columns));
}

.make-column-offset(@columns) {
    margin-left: percentage((@columns / @grid-columns));
}
.make-column-push(@columns) {
    left: percentage((@columns / @grid-columns));
}
.make-column-pull(@columns) {
    right: percentage((@columns / @grid-columns));
}

// Generate the extra small columns
.make-xs-column(@columns; @gutter: @grid-gutter-width) {
    box-sizing: border-box;
    position: relative;
    float: left;
    width: percentage((@columns / @grid-columns));
    min-height: 1px;
    padding-left:  (@gutter / 2);
    padding-right: (@gutter / 2);
}

.make-xs-column-offset(@columns) {
    margin-left: percentage((@columns / @grid-columns));
}
.make-xs-column-push(@columns) {
    left: percentage((@columns / @grid-columns));
}
.make-xs-column-pull(@columns) {
    right: percentage((@columns / @grid-columns));
}

// Generate the small columns
.make-sm-column(@columns; @gutter: @grid-gutter-width) {
    box-sizing: border-box;
    position: relative;
    min-height: 1px;
    padding-left:  (@gutter / 2);
    padding-right: (@gutter / 2);

    .mq(xs; {
        float: left;
        width: percentage((@columns / @grid-columns));
    });
}
.make-sm-column-offset(@columns) {
    .mq(xs; {
        margin-left: percentage((@columns / @grid-columns));
    });
}
.make-sm-column-push(@columns) {
    .mq(xs; {
        left: percentage((@columns / @grid-columns));
    });
}
.make-sm-column-pull(@columns) {
    .mq(xs; {
        right: percentage((@columns / @grid-columns));
    });
}

// Generate the medium columns
.make-md-column(@columns; @gutter: @grid-gutter-width) {
    box-sizing: border-box;
    position: relative;
    min-height: 1px;
    padding-left:  (@gutter / 2);
    padding-right: (@gutter / 2);

    .mq(m; {
        float: left;
        width: percentage((@columns / @grid-columns));
    });
}
.make-md-column-offset(@columns) {
    .mq(m; {
        margin-left: percentage((@columns / @grid-columns));
    });
}
.make-md-column-push(@columns) {
    .mq(m; {
        left: percentage((@columns / @grid-columns));
    });
}
.make-md-column-pull(@columns) {
    .mq(m; {
        right: percentage((@columns / @grid-columns));
    });
}

// Generate the large columns
.make-lg-column(@columns; @gutter: @grid-gutter-width) {
    box-sizing: border-box;
    position: relative;
    min-height: 1px;
    padding-left:  (@gutter / 2);
    padding-right: (@gutter / 2);

    .mq(l; {
        float: left;
        width: percentage((@columns / @grid-columns));
    });
}
.make-lg-column-offset(@columns) {
    .mq(l; {
        margin-left: percentage((@columns / @grid-columns));
    });
}
.make-lg-column-push(@columns) {
    .mq(l; {
        left: percentage((@columns / @grid-columns));
    });
}
.make-lg-column-pull(@columns) {
    .mq(l; {
        right: percentage((@columns / @grid-columns));
    });
}


//
// Grid system
// --------------------------------------------------


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
    .container-fixed();
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
    .container-fixed();
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
    .make-row();
    .lib-clearfix();
}

// Columns
//
// Common styles for small and large grid columns

.make-grid-columns();


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

.make-grid(xs);

.mq(xs; {
    .make-grid(sm);
});

.mq(m; {
    .make-grid(md);
});

.mq(l; {
    .make-grid(lg);
});
