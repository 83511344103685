
//
//  Sidebar
//  ---------------------------------------------

.sidebar__title {
    font-size: 2.2rem;
    color: @primary__color;
    font-weight: @font-weight__bold;
    font-family: @secondary__font-family;
    margin-bottom: @indent__s;
    margin-top: 14px;
}

.sidebar__title--filter {
    display: none;

    .mq(m; {
        display: block;
    });
}

.sidebar-additional {
    margin-bottom: 40px;
}
