
//
//  Swatches
//  ---------------------------------------------

.swatch-attribute {
    margin: 0 -@indent__s;

    .swatch-option {

        &.image:not(.disabled):hover,
        &.color:not(.disabled):hover,
        &.selected:hover,
        &.selected {
            outline: 2px solid @secondary__color;
        }

        &.text {
            height: auto;
        }

    }
}
