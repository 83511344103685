
//
//  Buttons
//  ---------------------------------------------

.btn(
    @button__background: @button-default__background,
    @button__color: @button-default__color,
    @button__background--hover: @button-default__background--hover,
    @button__color--hover: @button-default__color--hover,
    @button__border: @button-default__border,
    @button__border--hover: @button-default__border--hover
) {

    // Default styling
    .lib-css(padding, @button__padding);
    .lib-css(border-radius, @button__border-radius);
    .lib-css(font-family, @button__font-family);
    .lib-css(font-weight, @button__font-weight);
    .lib-css(font-size, @button__font-size);
    .lib-css(cursor, pointer);

    .lib-css(background, @button__background);
    .lib-css(color, @button__color);
    .lib-css(text-align, @button__text-align);
    .lib-css(text-decoration, @button__text-decoration);
    .lib-css(border, @button__border);

    &:visited,
    &:focus {
        .lib-css(background, @button__background);
        .lib-css(color, @button__color);
        .lib-css(text-decoration, @button__text-decoration);
        .lib-css(border, @button__border);
    }

    &:hover,
    &:active {
        .lib-css(background, @button__background--hover);
        .lib-css(color, @button__color--hover);
        .lib-css(text-decoration, @button__text-decoration--hover);
        .lib-css(border, @button__border--hover);
    }

    // Reset styling
    .btn-reset();

    &:visited,
    &:focus,
    &:hover,
    &:active {
        .btn-reset();
    }

}

.btn-reset() {
    box-sizing: border-box;
    box-shadow: none;
    line-height: @button__line-height;
    display: inline-block;
}

.btn-primary() {
    .btn(
        @button__background: @button-primary__background,
        @button__color: @button-primary__color,
        @button__background--hover: @button-primary__background--hover,
        @button__color--hover: @button-primary__color--hover,
        @button__border: @button-primary__border,
        @button__border--hover: @button-primary__border--hover
    )
}

.btn-secondary() {
    .btn(
        @button__background: @button-secondary__background,
        @button__color: @button-secondary__color,
        @button__background--hover: @button-secondary__background--hover,
        @button__color--hover: @button-secondary__color--hover,
        @button__border: @button-secondary__border,
        @button__border--hover: @button-secondary__border--hover
    )
}

.btn-success() {
    .btn(
        @button__background: @button-success__background,
        @button__color: @button-success__color,
        @button__background--hover: @button-success__background--hover,
        @button__color--hover: @button-success__color--hover,
        @button__border: @button-success__border,
        @button__border--hover: @button-success__border--hover
    )
}

.btn-alt() {
    .btn(
        @button__background: @button-alt__background,
        @button__color: @button-alt__color,
        @button__background--hover: @button-alt__background--hover,
        @button__color--hover: @button-alt__color--hover,
        @button__border: @button-alt__border,
        @button__border--hover: @button-alt__border--hover
    )
}

.btn-cart() {
    .btn-success();
    position: relative;
    padding-right: 65px;
    padding-left: 25px;
    text-align: left;

    &:before {
        content: "";
        .svg-cart-icon-white();
        .svg-cart-icon-white-dims();
        display: block;
        position: absolute;
        right: @indent__base;
        top: 9px;
    }
}

.btn-responsive() {
    width: 100%;
}
