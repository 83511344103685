
//
//  Dropdown
//  ---------------------------------------------

.mage-dropdown-dialog {
    .ui-dialog-buttonpane {
        position: relative;

        .action.close {
            .lib-bluebird-dropdown-close();
        }

        .mq(- m; {
            position: absolute;
            right: 0;
            margin-top: -7px;
            margin-right: @indent__s;
        });
    }
}
