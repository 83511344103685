
//
//  Page Sections
//  ---------------------------------------------

.page-section {
    padding: 60px 0;
}

.page-section--top {
    padding-top: 0;
}

.cms-index-index {
    .page-section--top {
        padding-bottom: 0;

        .page-section__inner {
            .mq(m; {
                min-height: 250px;
            });
        }

    }

    .page-section--content {
        padding-top: @indent__base;
        .mq(m; {
            padding-top: 0;
        });
    }
}

.page-section--aside {
    background-color: @panel__color;
    padding: 40px 0;
}

.page-section__title {
    .lib-bluebird-heading(h2);
}

.row-full-width-inner > figure[data-appearance="full-width"] {
    object-fit: cover;
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw !important;
    margin-right: -50vw !important;

    .mq(m; {
        width: ~"calc(100vw - 4px)";
    });
}

.row-full-width-inner > figure[data-appearance="full-width"] img {
    width: 100%;
}

[data-content-type='row'][data-appearance='full-width'] {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw !important;
    margin-right: -50vw !important;

    .mq(m; {
        width: ~"calc(100vw - 8px)";
    });

    > .row-full-width-inner {
        padding: 25px;
        box-sizing: border-box;
    }
}
