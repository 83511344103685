
//
//  Active Filters
//  ---------------------------------------------

.selected-items__title {
    color: @primary__color;
    font-size: @font-size__l;
    font-weight: @font-weight__bold;
    font-family: @secondary__font-family;
    margin-bottom: @indent__s;
}

#layered-selected-filters-block {
    .selected-items-list {

        // Reset styling
        .selected-item {
            padding: 0;
            background-color: transparent;
            border: 0;
            display: block;
            line-height: normal;
            margin-bottom: 5px;

            &:hover {
                text-decoration: none;
            }

            .action-clear {
                font-size: @font-size__s;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            &[data-role="selected-item"] {
                .mq(xxs m; {
                    float: left;
                });

            }

            &:last-child {
                margin-top: @indent__s;

                .mq(- m; {
                    display: inline-block;
                    .lib-clearfix();
                    clear: both;
                    float: none;
                    width: 100%;
                });
            }

            .action-close {
                background: @button-secondary__background;
                color: @text__color--secondary;
                padding: 6px 15px;
                font-size: @font-size__m;
                border-radius: 40px;
                margin-right: @indent__s;
                position: relative;
                padding-right: 35px;
                width: 100%;

                .mq(l; {
                    width: 80%;
                });

                .mq(xxs m; {
                    width: auto;
                });


                > span {
                    width: auto;
                    position: relative;
                    height: auto;
                    overflow: unset;
                    margin: 0;
                    clip: unset;
                    font-size: @font-size__m;
                    padding: 0;
                    float: left;
                }

                &:before {
                    display: none;
                }

                &:after {
                    display: inline-block;
                    content: '';
                    .svg-remove-icon();
                    .svg-remove-icon-dims();
                    margin-left: @indent__s;
                    float: right;
                    margin-top: 3px;
                    position: absolute;
                    right: 15px;
                }

                &:hover,
                &:active,
                &:focus {
                    background: @button-secondary__background--hover;
                }
            }
        }
    }
}
