
//
//  Container
//  ---------------------------------------------

.outer-container() {
    box-sizing: border-box;
    width: 100%;
    padding: 0 @indent__base;

    .mq(- m; {
        padding-left: 15px;
        padding-right: 15px;
    });
}
