
//
//  Requisition List
//  ---------------------------------------------

.social-button .requisition-list-button {
    text-transform: lowercase;
    font-weight: normal;
    letter-spacing: normal;
    color: @primary__color;
    font-size: @font-size__m;
    font-family: @primary__font-family;
    display: block;
    line-height: normal;

    &:hover,
    &:focus {
        color: @secondary__color;
    }

    &:before {
        margin-right: 5px;
    }
}


.requisition-list-action .action.toggle {
    margin-bottom: 5px;
    line-height: normal;

    &.active:after,
    &:after {
        display: none;
    }
}

//
//  Changes
//  ---------------------------------------------
.requisition-grid{
    .col-checkbox{
        display: block;
    }

    .requisition-list-title{
        position: relative;
    }

    .actions-toolbar{
        position: relative;
    }
}

.requisition-list-action {
    .action.toggle {
        margin-bottom: @indent__s;
    }
}


.block-requisition-management{
    .requisition-list-title{
        h1{
            font-weight: @font-weight__bold;
            margin-right: @indent__base;
        }

        .action.edit{
            .btn-primary();

            margin-top: -15px;
        }
    }
}

.requisition-view-links{
    .print{
        .btn-primary();
    }
}

.requisition-toolbar-actions {
    span {
        font-size: @font-size__s;
        font-weight: @font-weight__hairline;
    }

    > .action.remove-selected,
    .requisition-list-action > .action {
        color: @secondary__color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.requisition-grid {
    .actions-toolbar {
        > .action-edit {
            &:hover:before,
            &:before {
                color: white;
            }
        }

        > .action-delete {
            margin-left: 5px;

            &:hover:before,
            &:before {
                color: white;
            }
        }
    }
    .item {
        .col {
            .product-item-subtotal{
                height: auto;
            }
        }
    }
}


.requisition-toolbar {
    .requisition-list-action .action.toggle {
        margin-bottom: -3px;
        font-weight: @font-weight__hairline;
    }
}

.account {
    .page-main {
        .column.main {
            .requisition-content{
                .actions-toolbar {

                    .mq(- m; { margin-top: @indent__base });

                    .mq(- s; { margin-top: -30px; });

                    button.action {
                        padding: @indent__s 12px;
                    }
                }

                a.print {
                    text-decoration: none;
                }
            }
        }
    }
}
