//
//  Category List
//  ---------------------------------------------

.hiddenToolbar{
    display: none;
}
.category-list {
    font-family: @secondary__font-family;
    font-weight: @font-weight__bold;
    color: @primary__color;

    a {
        color: @primary__color;
        padding: 2px 0;
        display: block;

        &:hover {
            color: @secondary__color;
        }
    }

    ul {
        padding-left: 40px;

        &.category-list__first-depth {
            padding-left: 0;
        }

        li {
            &.active {
                > a {
                    color: @secondary__color;
                }
            }
        }
    }

    .level0 {
        display: none; // hide other main categories

        > a {
            font-size: 2.2rem;
            position: relative;
            padding-left: 25px;

            &:before {
                .svg-long-arrow-right-icon();
                .svg-long-arrow-right-icon-dims();
                content: '';
                display: block;
                position: absolute;
                top: 12px;
                left: 0;
                .lib-css(transition, transform 0.5s);
            }
        }

        .submenu {
            display: none;
        }

        &.active,
        &.has-active {
            display: block; // only show current main category

            //&.parent {
            //    > a {
            //        &:before {
            //            .lib-css(transform, rotate(90deg));
            //        }
            //    }
            //}

            .submenu {
                display: block;
            }
        }
    }

}
