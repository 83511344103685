
//
//  Tailor Made
//  ---------------------------------------------

.product-options-wrapper {
    label[for="options_length_text"] {

        &:after {
            display: none;
        }
    }

    .product-custom-option {
        margin: 5px 0;
    }

    .field {
        .note {
            color: @primary__color;
            font-size: @font-size__l;
        }
    }
}

.note__message {
    display: block;
    margin-bottom: 2em;
    color: #bf0000;
}

.product-tailormade-info {
    padding: 0 @indent__base;
    color: @primary__color;
}

.product-page__price-calculation .product-info-main .product-info-price .price-box .price-container .price-excluding-tax:after,
.product-page__price-calculation .product-info-main .product-info-price .price-box .price-container .price-including-tax:after {
    content: attr(data-label-calculation);
}

.configuration_notice {
    color: @primary__color;
    font-size: 1.7rem;
    padding: 0;
    font-weight: @font-weight__bold;
}

.reset-button__container {
    padding: @indent__s 0;
}
