
//
//  Account
//  ---------------------------------------------

.account-dropdown {

    .lib-bluebird-dropdown(
        @dropdown__top: 60px,
        @dropdown-arrow__right: 39px,
        @dropdown__min-width: 340px,
        @dropdown-has-header: true,
        @dropdown-content__padding: 0
    );

    .account-dropdown__trigger {

        &:before {
            content: '';
            .svg-account-icon();
            .svg-account-icon-dims();
            vertical-align: middle;
            display: inline-block;
            margin: 0 auto 2px;
            .mq( m, {
                transform: scale(0.7);
            });
        }

        .text {
            .mq(- xl, {
                display: none;
            });
        }

        &.active:after,
        &:after {
            .mq(- m; {
                display: none;
            });
        }
    }

    &.active {
        .account-dropdown__trigger {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    .account-dropdown__login {
        padding: 17px @indent__base;
    }

    .account-dropdown__btn {
        .btn-success();
        .btn-responsive();
        margin-top: @indent__base;
    }

    .account-dropdown__remind {
        display: block;
        text-align: center;
        margin-top: @indent__s;
        font-size: @font-size__m;
        .lib-bluebird-link();
    }

    .account-dropdown__register {
        border-top: 1px solid @quaternary__color;
    }

    a.account-dropdown__register-url {
        display: block;
        padding: 17px @indent__base;
        text-align: center;
        color: @primary__color;
        font-family: @secondary__font-family;
        font-size: 2rem;
        font-weight: @font-weight__bold;

        span {
            display: block;
            font-family: @primary__font-family;
            color: @secondary__color;
            font-weight: normal;
        }

        &:hover {
            text-decoration: none;
            span {
                text-decoration: underline;
            }
        }
    }

    .header.links {
        li {
            display: block;
            padding: 0;
        }

        a {
            display: block;
            padding: 8px @indent__base;
        }
    }

    &.account-dropdown--logged-in {
        .dropdown__content {
            .dropdown__body {
                padding: @indent__s 0;
            }
        }
    }

    .mq(- m; {
        position: static;

        .dropdown__content {
            left: @indent__s;
            right: @indent__s;
            min-width: 0;
            text-align: left;

            &:before {
                right: 92px;
                top: -23px;
            }
        }
    });
}
