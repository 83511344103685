//
//  Cart Override Luma Styles
//  ---------------------------------------------

.cart.table-wrapper {
  .actions-toolbar {
    min-height: 35px;
  }

  .col.qty .input-text {
    width: 54px;
  }
}

.cart-summary {
  &._sticky {
    margin-top: @indent__base;
  }
  .checkout-methods-items {
    .action.primary.checkout {
      .btn-success();
    }
  }
}

//
//  Cart
//  ---------------------------------------------


.page-main {

  .cart-summary {

    .block {
      display: none;
    }
  }
}

.checkout-cart-index {
  .prevent-clicking-minicart();

  #shopping-cart-table {
    .item-actions {
      .actions-toolbar {
        .fa-calendar-check,
        .fa-spinner {
          pointer-events: none;
          color: @color-gray46;
          display: block;
          position: absolute;
          right: 15px;
          top: 35px;
        }

        > i {
          color: @color-gray46;
        }

        .action.action-delete {
          right: 0;
          top: 30px;
        }

        .action.action-edit {
          right: 45px;
          top: 30px;
          display: none;
        }
      }
    }
  }

  .ui-datepicker {
    .ui-datepicker-header {
      .ui-datepicker-prev {
        .ui-icon-circle-triangle-w {
          border-color: transparent @secondary__color transparent transparent;
        }
      }

      .ui-datepicker-next {
        .ui-icon-circle-triangle-e {
          border-color: transparent transparent transparent @secondary__color;
        }
      }
    }

    .ui-datepicker-calendar {
      .ui-state-active {
        background: lighten(@secondary__color, 25%);
      }

      .ui-state-hover {
        background: lighten(@secondary__color, 50%);
      }
    }
  }

  .action-datepicker__wrapper {
    position: relative;
    max-width: 215px;
    float: right;
    margin-right: 70px;

    .mq(- m; {
      max-width: 100%;
    });

    .action-datepicker__input {
      cursor: pointer;
    }

    .action-datepicker__label {
      color: @color-gray46;
      font-size: 1.1rem;
      margin-left: @indent__s;
    }
  }

  .cart-discount {
    .field {
      width: 100%;
      padding-right: @indent__s;
    }

    .actions-toolbar {
      width: 220px;

        .primary {
        .action.primary {
          .btn-primary();
          width: 100%;
        }
      }
    }
  }
}

.cart {

  &.table-wrapper {

    .col.qty {

      .input-text {
        .lib-bluebird-input-qty();
      }

      .control.qty {
        margin-top: @indent__s;
      }
    }

    .product-item-sku, .customer-sku {
      display: block;
    }

    .product-item-photo {

      .mq(m; { max-width: 100px; });

    }
  }

  &.item {
    background: white;

    .pricebox {
      .lib-display-price(minicart-item);
    }
  }
}

.cart-discount {
  .block.discount {
    width: 100%;
  }
}

.crosssell {
  .block-title {
    strong {
      color: @primary__color;
      font-size: @h4__font-size;
      font-family: @secondary__font-family;
    }
  }
}
