
//
//  Products Grid
//  ---------------------------------------------

.page-layout-1column .block.widget .products-grid .product-item:nth-child(3n + 1) {
  margin-left: 0;
}

.products-grid {

    .ias-no-more {
        display: block;
        padding: 0 15px;
    }

    .product-items:not(.widget-product-grid) {
        margin: 0 -@indent__s;

        .mq(l; {
            margin: 0 -15px;
        });


        //
        //  Product Item
        //  ---------------------------------------------

        .product-item {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: @indent__base;
            padding: 0 @indent__s;

            .mq(l; {
                width: 1/3 * 100%;
                padding: 0 15px;
                margin-bottom: 25px;
            });

            // 50% between 768px - 900px
            .mq(m grid-m; {
                width: 50%;
            });

            .mq(- xs; {
                width: 100%;
            });

            // override luma styles
            .product-item-info:not(:hover):not(.active) .product-item-inner,
            .product-item-inner {
                position: static;
                display: block;
                height: auto;
                width: auto;
                .lib-css(overflow, unset);
                .lib-css(box-shadow, none);
                .lib-css(background, none);
                .lib-css(clip, auto);
                left: auto;
                right: auto;
                z-index: auto;
                margin: 0;
                padding: 0;
                border: 0;
            }


            //
            //  Product Info
            //  ---------------------------------------------

            .product-item-info {
                border: 1px solid @quaternary__color;
                .lib-css(border-radius, 5px);
                width: 100%;

                // override luma styles
                &:hover,
                &.active {
                    margin: 0;
                    padding: 0;
                    .lib-css(box-shadow, 0px 0px 4px 0 rgba(0, 0, 0, 0.3));
                    position: static;

                    .mq(m; {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;

                        .product-item-actions {
                            .actions-secondary {
                                display: block;
                            }
                        }
                        .product-image-wrapper {
                            z-index: 1;
                        }
                    });
                }
            }


            //
            //  Product Photo
            //  ---------------------------------------------

            .product-image-wrapper {
                margin: 0 @indent__base;
                z-index: 1;
                //.mq(m; {
                //    z-index: 1;
                //});
            }

            .product-item-photo {
                display: block;
                margin: 0 auto;
                text-align: center;
            }


            //
            //  Product Name
            //  ---------------------------------------------

            .product-item-name {
                margin: @indent__s @indent__base;
            }



            //
            //  Product Price
            //  ---------------------------------------------

            .price-box {
                margin: @indent__s @indent__base;
            }



            //
            //  Product Actions
            //  ---------------------------------------------

            .product-item-details {
                position: relative;
            }

            .product-details {
                text-align: center;
                position: absolute;
                margin-top: 70px;
                width: 100%;
                z-index: 10;
            }

            .product-item-actions {
                margin: 0;
                border-top: 1px solid @quaternary__color;
                z-index: 5;

                .actions-primary {
                    width: 100%;
                    margin: @indent__base auto;
                    padding: 0 @indent__base @indent__base;
                }

                .actions-secondary {
                    display: none;
                    width: 100%;
                    border-top: 1px solid @quaternary__color;
                    padding-left: @indent__base;
                    padding: @indent__s @indent__base;
                    text-align: center;
                    background: white;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    z-index: 5;

                    width: ~"calc(100% + 2px)";
                    position: absolute;
                    border: 1px solid @quaternary__color;
                    margin-left: -1px;
                    margin-right: -1px;
                    .lib-css(box-shadow, 0px 1px 4px rgba(0, 0, 0, 0.3));

                    .action.towishlist,
                    .wishlist.split.button {
                        display: none;
                    }

                    .action.tocompare {
                        .lib-compare-link();
                    }

                    .requisition-list-button.toggle {
                        margin-top: 5px;
                        span {
                            font-size: @font-size__s;
                        }
                    }
                }
            }
        }
    }

    .ias-load-more, .ias-load-prev {
        display: block !important;
        margin: 30px auto 15px;

        &:hover {
            display: block;
        }
    }

    .ias-spinner {
        margin: 30px 0;
        text-align: center;

        img {
            display: inline-block;
            max-width: 50px;
        }

        span {
            margin-top: @indent__base;
            display: block;
        }

    }
}
