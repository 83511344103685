.tooltip-bottom {
    .lib-tooltip(@_tooltip-position: bottom);

    .tooltip-content {
        margin-right: @indent__s;
        min-width: 200px;
        max-width: 500px;
        width: 50vw;

        p {
            font-weight: @font-weight__light;
        }
    }
}

.tooltip-info {
    margin-right: 5px;

    &:hover {
        text-decoration: none;
    }
}
