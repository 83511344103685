
//
//  Minicart
//  ---------------------------------------------

.minicart-wrapper {

    .lib-bluebird-dropdown(
        @dropdown__top: 60px,
        @dropdown-arrow__right: @indent__s,
        @dropdown__min-width: 340px,
        @dropdown-has-header: true,
        @dropdown-content__padding: 0,
        @dropdown-toggle-selector: false,
        @dropdown-options-selector: ~'.block-minicart',
        @dropdown-header-selector: ~'.block-title',
        @dropdown-body-selector: ~'.block-content'
    );

    .block-minicart {
        .block-title {
            display: block;

            .qty {
                display: none;
            }
        }

        .block-content {
            padding: 17px @indent__base;
            .action.close {
                .lib-bluebird-dropdown-close(
                    @dropdown-close__top: 11px,
                    @dropdown-close__right: 1px
                );

                &:before {
                    display: none;
                }
            }

            >.actions >.primary {
                margin: 0 0 15px;
            }

            .items-total {
                display: none;
            }

            .subtotal {
                .lib-css(display, flex);
                .lib-css(justify-content, space-between);
                .lib-css(align-items, center);
                margin: 0;

                .label {
                    &:after {
                        display: none;
                    }
                }

                .amount {

                }
            }

            .action.primary.checkout {
                .btn-success();
            }
        }

        .subtitle.empty {
            padding: 17px @indent__base 0;
        }
    }

    .action.showcart,
    .action.showcart.active {
        &:before {
            content: '';
            .svg-cart-icon();
            .svg-cart-icon-dims();
            transform: scale(0.9);
            .mq( m; {
                transform: scale(0.7);
            });

        }

        .counter-number {
            text-shadow: none;

            .mq(- m; {
                color: transparent;
            });

            &:after {
                content: '';
                .svg-arrow-icon();
                .svg-arrow-icon-dims();
                display: inline-block;
                margin-left: 6px;
            }
        }

        .counter.qty,
        .counter.qty.empty {
            color: @text__color;
            //display: block;
            background: transparent;
            min-width: 0;
            margin: 0;
            padding: 0;
            font-family: @secondary__font-family;

            .mq(- m; {
                margin-left: -@indent__base;
                margin-right: 5px;
            });
        }

        .counter.qty.empty {
            color: transparent;

            .counter-number {
                &:after {
                    margin-left: 0;
                }
            }
        }
    }

    .action.showcart.active {
        .counter-number {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    .mini-checkout-extra-text {
        text-align: center;
        margin: 0 0 @indent__s;
        font-size: 1.3rem;
    }

    .mq(- m; {
        margin-top: 0;
        float: none;
        position: static;

        .block-minicart {
            left: @indent__s;
            right: @indent__s;
            width: ~"calc(100% - 20px)";
            min-width: 0;
            text-align: left;

            &:before {
                right: 55px;
            }
        }
    });
}




//
//  Minicart Content
//  ---------------------------------------------

.minicart-wrapper {
    margin: 0;
    .block-minicart {

        .block-content {
            padding: 0;
            font-family: @primary__font-family;

            .subtotal {
                padding: 15px @indent__base 0;

                .label {
                    font-size: @font-size__m;
                    color: @primary__color;
                    font-family: @secondary__font-family;
                }

                .price-container {
                    .lib-display-price(minicart-total);
                }
            }

            .actions {
                padding: 0 @indent__base;

                .action.viewcart {
                    font-size: @font-size__m;
                    color: @secondary__color;
                }
            }
        }

        .minicart-items-wrapper {
            margin: 0;
            padding: @indent__base;
            border: 0;
            border-bottom: 1px solid @border__color;
        }

        li {
            padding: 15px 0;

            &:hover {
                background: inherit;
                cursor: inherit;
            }
        }
    }
}

.minicart-items {

    .subtotal {
        .lib-display-price(minicart-item);
    }

    input.item-qty {
        .lib-bluebird-input-square();
        width: 40px;
        height: 34px;
        padding: 0;
        margin-right: 5px;
    }

    .product-item-pricing {
        .lib-css(display, flex);
        .lib-css(flex-direction, row-reverse);
        .lib-css(justify-content, space-between);

        .details-qty {
            .label {
                display: none;
            }

            &:after {
                display: inline-block;
                content: 'x';
                color: @primary__color;
                font-family: @secondary__font-family;
                font-size: @font-size__m;
            }
        }

        // Prices
        .price-container {
            text-align: right;

            .price-wrapper {
                .lib-display-price(minicart-item);
            }
        }

        .mq(- xs; {
            .lib-css(display, block);

            .price-container {
                margin-bottom: @indent__s;
            }
        });

    }

    .product-item-details {
        position: relative;

        .details-qty {
            margin-top: 0;
        }

        .product.actions {
            padding: 0;
            margin: 0;
            position: absolute;
            top: 0;
            right: 0;

            .primary {
                display: none;
            }
        }
    }

    .product-item-name {
        font-family: @secondary__font-family;

        a,
        a:hover {
            color: @primary__color;
        }
    }

    .product-item .product-item-details .product-item-name {
        margin: 0 22px @indent__s 0;
    }

    .action.delete {
        &:before {
            content: '';
            .svg-trash-icon();
            .svg-trash-icon-dims();
        }

        &:hover {
            &:before {
                .svg-trash-icon-darkblue();
                .svg-trash-icon-darkblue-dims();
            }
        }
    }

    .update-cart-item {
        position: absolute;
        margin-left: 15px;
    }
}
