
//
//  Settings
//  ---------------------------------------------






//
//  Compare
//  ---------------------------------------------

.mq(m; {

    .compare.wrapper {
        .action.compare {
            line-height: inherit;
        }
    }

});