
//
//  Quotes
//  ---------------------------------------------

.quote-actions-toolbar{
    a {
        .btn-primary();
    }
}

.quote-grid-filters-wrap > button.action {
    font-size: @font-size__s;
    line-height: 1;
    text-decoration: underline;
    color: @secondary__color;
    font-family: @primary__font-family;
    font-weight: @font-weight__hairline;

    &:hover {
        text-decoration: none;
        color: @secondary__color;
    }
}
