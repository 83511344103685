
//
//  Page Main
//  ---------------------------------------------

.page-main {
    font-size: @font-size__m;

    .block {
        margin-bottom: 0;
    }
}

.has-page-sections {
    .page-main {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }
}

.page-main__inner,
.page-section__inner {
    .outer-container();
}