
//
//  Settings
//  ---------------------------------------------






//
//  Product item
//  ---------------------------------------------

.product-item {

    .product-item-details{

        .product-item-name{
            margin: 15px 0 @indent__s;

            a{
                font-size: @font-size__xl;
                font-family: @secondary__font-family;
                color: @text__color;

                &:hover{
                    text-decoration: none;
                    color: @secondary__color;
                }
            }
        }

        .product-item-actions{
            display: block;

            .actions-primary{
                display: block;

                .tocart{
                    .btn-cart();
                    .btn-responsive();
                    max-width: 320px;
                }

                .action.primary:not(.tocart) {
                    .btn-success();
                    .btn-responsive();
                    max-width: 320px;
                }
            }

            .actions-secondary{
                display: none !important;
            }
        }
    }

    .product-item-photo {
        display: block;
    }

    .product-reviews-summary {
        display: none;
    }

    .lib-product-price-box(catalog);

    // Bundle product prices
    .price-box {
        .price-to {
            display: none;
        }

        .price-from {
            .price-label {
                order: 1;
                color: @secondary__color;
                font-weight: @font-weight__bold;

                &:after {
                    content: ':';
                }
            }
        }
    }
}
