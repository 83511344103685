
//
//  Links
//  ---------------------------------------------

.lib-bluebird-link() {
    .lib-link(
        @_link-color: @link__color,
        @_link-text-decoration: @link__text-decoration,
        @_link-color-visited: @link__visited__color,
        @_link-text-decoration-visited: @link__visited__text-decoration,
        @_link-color-hover: @link__hover__color,
        @_link-text-decoration-hover: @link__hover__text-decoration,
        @_link-color-active: @link__active__color,
        @_link-text-decoration-active: @link__active__text-decoration
    );
}




.lib-add-tolink() {
    display: block;
    width: 100%;

    // override luma styles
    span {
        overflow: auto;
        height: auto;
        position: static;
        width: auto;
        text-transform: lowercase;
        font-weight: normal;
        font-size: @font-size__s;
        letter-spacing: normal;
        color: @primary__color;
    }

    &:before {
        margin-right: 5px;
        content: '';
    }

    &:hover {
        span {
            color: @secondary__color;
        }
    }
}




.lib-compare-link() {
    .lib-add-tolink();

    &:before {
        .svg-compare-icon();
        .svg-compare-icon-dims();
    }

    &:hover {
        &:before {
            .svg-compare-icon-lightblue();
            .svg-compare-icon-lightblue-dims();
        }
    }
}
