
//
//  Lists
//  ---------------------------------------------

.lib-bluebird-usps() {
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            color: @primary__color;
            font-size: @font-size__m;
            font-family: @secondary__font-family;
            line-height: 1.7;
            position: relative;
            padding-left: @indent__base;

            &:before {
                .svg-check-icon-green();
                .svg-check-icon-green-dims();
                display: inline-block;
                content: '';
                margin-right: @indent__s;
                position: absolute;
                top: 8px;
                left: 0;
            }
        }
    }
}
