
//
//  Product Image
//  ---------------------------------------------

.product-page__media {

    .product-page__media-inner {
        .lib-clearfix();
        position: relative;
    }

    .fotorama__stage {
        border: 1px solid @border__color--lighter;
        border-radius: 5px;

        .mq(m; {
            margin-bottom: 2rem;
        });
    }

    .fotorama__nav-wrap {
        .fotorama__img {
            border: 1px solid @border__color--lighter !important;
            border-radius: 5px;
        }

        .fotorama__active {
            .fotorama__img {
                border-color: @secondary__color !important;
            }
        }
    }

    .fotorama__thumb-border {
        border-color: @secondary__color;
        border-radius: 5px;
        display: none;
    }

    .fotorama__nav--thumbs {
        .fotorama__nav__frame {
            margin-right: @indent__base;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.fotorama__nav__frame {
    cursor: pointer;
}

.fotorama__nav {
    .fotorama__dot {
        width: 10px;
        height: 10px;
    }

    .fotorama__active {
        .fotorama__dot {
            background-color: @secondary__color;
            border-color: @secondary__color;
        }
    }

    .fotorama__thumb {
        background-color: white;
    }
}
