//
//  Variables
//  ---------------------------------------------

@average-rating-stars__height: 14px;
@average-rating-stars__width: 74px;
@average-rating-stars-empty__background-image: '@{baseDir}images/stars-small-empty.png';
@average-rating-stars-full__background-image: '@{baseDir}images/stars-small-full.png';

@average-rating-stars-use-svg: false;
@average-rating-stars-svg__mask-image: '@{baseDir}images/stars.svg';
@average-rating-stars-svg__empty-color: #8D8D8D;
@average-rating-stars-svg__active-color: #F6E42B;
@average-rating-stars-svg__mask-position-y: center;

@widget-rating-amount__font-size: 4rem;
@widget-rating-amount__font-weight: @font-weight__extrabold;

@text-widget-rating-score__display: flex;
@text-widget-rating-score__flex-direction: column;
@text-widget-rating-score__margin: 6px 0 0;
@text-widget-rating-text__float: left;
@text-widget-rating-text__margin: 0 15px 0 30px;
@text-widget-rating-stars__padding: 12px 0 0;
@text-widget-rating-stars-text__font-size: @font-size__s;

@ie-fix-min-width: ~"screen and (min-width: 0\0)";
@ie-fix-screen: ~"screen\0";




//
//  Average Rating Details
//  ---------------------------------------------

.average-rating__details {
    margin-right: 40px;
    white-space: nowrap;
    position: relative;

    &:after {
        content: attr(data-rating-value);
        font-size: @font-size__m;
        font-weight: @font-weight__bold;
        top: -3px;
        position: absolute;
    }

    .mq(- l; {
        display: none;
    });
}

.average-rating__text {
    .mq(- l; {
        display: none;
    });
}

.average-rating__based-on-amount {
    font-weight: @font-weight__bold;
}



//
//  Average Rating Stars
//  ---------------------------------------------

.average-rating__stars-wrapper {
    display: inline-block;
    height: @average-rating-stars__height;
    width: @average-rating-stars__width;
    margin-right: 8px;

    & when (@average-rating-stars-use-svg = false) {
        background: transparent url(@average-rating-stars-empty__background-image) no-repeat;
    }

    & when (@average-rating-stars-use-svg = true) {
        background-color: @average-rating-stars-svg__empty-color;
        .svg-mask-image()
    }

    @media @ie-fix-min-width, @ie-fix-screen {
        /* IE 8, 9 and 10 CSS styles go here */
        .svg-fallback-empty-stars();
    }
}

.average-rating__stars {
    display: block;
    height: @average-rating-stars__height;

    & when (@average-rating-stars-use-svg = false) {
        background: transparent url(@average-rating-stars-full__background-image) no-repeat;
    }

    & when (@average-rating-stars-use-svg = true) {
        background-color: @average-rating-stars-svg__active-color;
        .svg-mask-image()
    }

    @media @ie-fix-min-width, @ie-fix-screen {
        /* IE 8, 9 and 10 CSS styles go here */
        .svg-fallback-full-stars();
    }

}

/* IE Edge 12+ fix */
@supports (-ms-ime-align:auto) {
    .average-rating__stars-wrapper {
        .svg-fallback-empty-stars();
    }

    .average-rating__stars {
        .svg-fallback-full-stars();
    }
}



//
//  Average Rating Text
//  ---------------------------------------------

.widget-rating__amount {
    font-size: @widget-rating-amount__font-size;
    font-weight: @widget-rating-amount__font-weight;
}



//
//  Text Widget Rating
//  ---------------------------------------------

.text-widget__rating-text {
    float: @text-widget-rating-text__float;
    margin: @text-widget-rating-text__margin;
}

.text-widget__rating-stars {
    padding: @text-widget-rating-stars__padding;

    .average-rating__score {
        display: ~"-webkit-@{text-widget-rating-score__display}";
        display: ~"-ms-@{text-widget-rating-score__display}";
        display: @text-widget-rating-score__display;
        -webkit-flex-direction: @text-widget-rating-score__flex-direction;
        -ms-flex-direction: @text-widget-rating-score__flex-direction;
        flex-direction: @text-widget-rating-score__flex-direction;
        margin: @text-widget-rating-score__margin;
    }

    .average-rating__text {
        font-size: @text-widget-rating-stars-text__font-size;
    }
}

.svg-mask-image() {
    -webkit-mask:  url(@average-rating-stars-svg__mask-image) no-repeat;
    mask: url(@average-rating-stars-svg__mask-image) no-repeat;
    -webkit-mask-position-y: @average-rating-stars-svg__mask-position-y;
}

.svg-fallback-empty-stars() {
    background: transparent url(@average-rating-stars-empty__background-image) no-repeat !important;
}

.svg-fallback-full-stars() {
    background: transparent url(@average-rating-stars-full__background-image) no-repeat !important;
}
