
//
//  Order Status Mixins
//  ---------------------------------------------

.order-status-in-banner() {
    position: absolute;
    right: 0;
    width: 420px;
    background: white;
    padding: 30px 40px 0;
    bottom: 0;
    border-top-left-radius: 60px;

    button {
        .btn-responsive();
    }
}




//
//  Order Status Component
//  ---------------------------------------------

.order-status-widget {
    .fieldset {
        margin: 0 0 @indent__base;

        .field {
            margin: 0 0 @indent__xs;
        }
    }

    .form.form-orders-search {
        min-width: 0;
        width: auto;
    }
}

.order-status-widget__title {
    font-weight: @font-weight__bold;
    font-size: @h4__font-size;
    font-family: @secondary__font-family;
    color: @primary__color;
    margin-bottom: @indent__s;
    display: block;
}

.order-status-widget__button,
button.order-status-widget__button {
    .btn-success();

    .mq(- xs; {
        .btn-responsive();
    });
}


.order-actions-toolbar {
    .actions {
        a {
            .btn-primary();
        }
    }
}

.order-status {
    .lib-bluebird-status();
}

.cms-index-index {
    .page-section--top {
        .page-section__inner {
            position: relative;
        }

        .order-status-widget {
            display: block;
            float: right;
            width: 100%;
            margin-top: 40px;

            .mq(l; {
                .order-status-in-banner();
            });

            .mq(s m; {
                .order-status-in-banner();
            });
        }

        &.page-section--focus {
            .order-status-widget {
                .mq(m l; {
                    position: absolute;
                    right: @indent__base;
                    top: 0;
                    bottom: 0;
                    margin: @indent__base;
                    background: #fff;
                    padding: @indent__base 30px;
                    width: ~"calc(100% - 370px)";
                });

                .mq(- s; {
                    margin-top: 0;
                });
            }
        }
    }
}
