.fixed-button__container {
  position: fixed;
  z-index: 9999;
  -webkit-transform: translateZ(0) !important;
  bottom: 0;
  right: 0;
  width: 0;
  height: 100%;
}

.fixed-button__button {
  display: inline-block;
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  transform: rotate(180deg) translateY(-50%);
  writing-mode: vertical-lr;
  position: fixed;
  bottom: 50%;
  right: -2px;

  color: @color-white;
  width: 40px;
  padding: 12px 14px 12px 12px;
  background: @secondary__color;
  cursor: pointer;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;

  span {
    overflow-wrap: normal;
    word-break: normal;
    word-wrap: normal;
    white-space: nowrap;
    cursor: pointer;
  }

  &:hover {
    text-decoration: none;
    right: 0;
  }

  &:visited,
  &:active,
  &:focus,
  &:hover {
    color: @color-white;
  }

  svg {
    transform: rotate(180deg);
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
  }
}