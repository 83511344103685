
//
//  Product Add to Box
//  ---------------------------------------------


.product-page__addto-box {
    position: relative;
    .lib-clearfix();

    .mq(- m; {
        margin-top: @indent__s;
    });
}


.box-tocart {
    margin: 0;

    .action.tocart {
        .btn-cart();
        .btn-responsive();
        display: none;
    }
}

.product-info-main {
    border: 1px solid @border__color--lighter;
    border-radius: 4px;

    .product-info-price {
        //padding: @indent__base;
        padding: 30px @indent__base 15px;
        margin-bottom: 0;
        display: block;
        border-bottom: 0;

        .lib-product-price-box();

        .mq(- xs; {
            padding-top: 35px;
            .lib-product-price-box(product-small);
        });

        .price-box {
            margin: 0;
            padding: 0;
            width: auto;

            .old-price .price-container,
            .price-container {
                .price-excluding-tax,
                .price-including-tax {
                    margin: 0;
                }
            }

            .price-container,
            .price-including-tax + .price-excluding-tax {
                .price {
                    line-height: normal;
                }
            }
        }
    }

    .product.alert {
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

}

.product-social-links {
    padding: @indent__base;
    margin: @indent__s 0 0;
    border-bottom: 1px solid @border__color--lighter;
    border-top: 1px solid @border__color--lighter;
    text-align: left;
    display: none;
    .action.tocompare {
        .lib-compare-link();

        span {
            font-size: @font-size__m;
        }
    }
}

.product-page__usps {
    padding: @indent__base;
    border-bottom: 1px solid @border__color--lighter;

    .lib-bluebird-usps();
}

.product-page__payment {
    padding: @indent__base;

    p {
        .lib-unset-paragraph();
    }
}

.product-page__button-wrapper {
    padding: 0 @indent__base @indent__s;
}

.product-page__button-container {
    display: flex;
    align-items: flex-end;

    .product-options-bottom {
        margin-top: 0;
        margin-right: 15px;

        .box-tocart {
            margin-top: 0;
        }
    }

    button.product-page__button {
        .btn-cart();
        .btn-responsive();
        max-width: 360px;
        margin-bottom: @indent__base;
    }

    @media (max-width: 1110px) {
        button.product-page__button {
            font-size: 1.7rem;
        }
    }
}


@media (max-width: 1090px) {
  button.product-page__button {
      font-size: @font-size__m;
  }
}

@media (max-width: 1070px) {
  button.product-page__button {
      font-size: 1.5rem;
  }
}

@media (max-width: 1050px) {
  button.product-page__button {
      font-size: @font-size__s;
  }
}

@media (max-width: 1024px) {
  button.product-page__button {
      font-size: @font-size__l;
  }
}

@media (max-width: 370px) {
    button.product-page__button {
        padding-right: 0;
        padding-left: 0;

        span {
            display: block;
            width: 100%;
            text-align: center;
        }
    }
    button.product-page__button::before {
        content: "";
        background: none;
    }
}
