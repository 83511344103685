
//
//  Status
//  ---------------------------------------------

.lib-bluebird-status() {
    color: @text__color--secondary;
    background: @secondary__color;
    border-color: @secondary__color;
    border-radius: 30px;
}