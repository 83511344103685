.navigation {
    .mq(- m; {
        background-color: @color-white;
        display: none;
        height: 100vh;
        max-height: calc(~'100% - 67px'); // Mobile header height = 67px
        overflow-y: auto;
        padding-top: @indent__s;
        position: fixed;
        width: 100%;
        z-index: 100;
    });

    &--active {
        .mq(- m; {
            display: flex;
        });
    }

    > ul {
        .mq(- m; {
            left: 0;
            padding: 0 @indent__base;
            position: relative;
            top: 0;
            transition: left 120ms ease-in-out;
            width: 100%;
        });

        &.subnavigation--active {
            .mq(- m; {
                left: -100%;
            });

            > li.level0 {
                .mq(- m; {
                    position: static;
                });
            }

            .ui-menu-item--active {
                .submenu {
                    .mq(- m; {
                        border: 0;
                        display: block !important; // Override inline style
                        left: 100% !important; // Override inline style
                        top: 0 !important; // Override inline style
                    });
                }
            }
        }

        > li.level0 {
            .mq(- m; {
                border: 0;
                display: list-item;
                position: relative;
            });

            &.has-submenu {
                > a.level-top {
                    &::after {
                        .mq(- m; {
                            content: @icon-next;
                            font-family: 'luma-icons';
                            font-weight: @font-weight__regular;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        });
                    }
                }

                > ul.submenu {
                    .mq(- m; {
                        display: none;
                        overflow: visible;
                        padding: 0 @indent__base;
                        position: absolute;
                        transition: left 120ms ease-in-out;
                        width: 100%;
                        z-index: 10;
                    });

                    &.subnavigation--active {
                        .mq(- m; {
                            left: @indent__base !important; // Override long selector
                        });

                        .default-menu__submenu-wrapper,
                        .full-width-menu__submenu-wrapper {
                            .default-menu__container,
                            .full-width-menu__container {
                                > div.active,
                                .pagebuilder-column.active {
                                    ul.active {
                                        .mq(- m; {
                                            display: block;
                                            left: 100%;
                                            padding: 0 0 @indent__base;
                                            position: absolute;
                                            top: 0;
                                            width: calc(~'100% - @{indent__xl}');
                                        });

                                        li {
                                            .mq(- m; {
                                                display: block;
                                            });

                                            &:nth-child(2) {
                                                .mq(- m; {
                                                    padding-top: @indent__base;
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    > li {
                        .mq(- m; {
                            width: 100%;
                        });
                    }

                    .default-menu__submenu-wrapper,
                    .full-width-menu__submenu-wrapper {
                        .default-menu__container,
                        .full-width-menu__container {
                            h2,
                            h3,
                            h4,
                            h5 {
                                .mq(- m; {
                                    font-size: @font-size__m;
                                    font-style: normal;
                                    margin: 0;
                                    padding: @indent__base 0 0;
                                });

                                > a {
                                    .mq(- m; {
                                        color: @primary__color;
                                        padding: 0;
                                    });
                                }

                                + ul {
                                    .mq(- m; {
                                        border-bottom: 1px solid @color-black;
                                        padding: @indent__base 0;
                                    });

                                    li {
                                        .mq(- m; {
                                            display: block;
                                        });

                                        a:not(.go-back) {
                                            .mq(- m; {
                                                .lib-icon-font(
                                                    @icon-next,
                                                    @_icon-font: 'luma-icons',
                                                    @_icon-font-line-height: 20px,
                                                    @_icon-font-margin: 0 @indent__xs 0 0,
                                                    @_icon-font-size: @font-size__xs,
                                                    @_icon-font-position: before
                                                );
                                                display: block;
                                                font-weight: @font-weight__semibold;
                                                padding: @indent__s 0;
                                            });
                                        }
                                    }
                                }
                            }

                            h4 {
                                .mq(- m; {
                                    border-bottom: 1px solid @color-black;
                                    padding: @indent__base @indent__xl @indent__base 0;
                                    position: relative;
                                });

                                &::after {
                                    .mq(- m; {
                                        content: @icon-next;
                                        font-family: 'luma-icons';
                                        font-weight: @font-weight__regular;
                                        position: absolute;
                                        right: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    });
                                }

                                + ul {
                                    .mq(- m; {
                                        display: none;
                                    });
                                }
                            }
                        }
                    }
                }
            }

            > a.level-top {
                .mq(- m; {
                    border-bottom: 1px solid @color-black;
                    padding: @indent__base @indent__xl @indent__base 0;
                    text-transform: none;
                });
            }

            a.go-back {
                .mq(- m; {
                    border-bottom: 1px solid @color-black;
                    color: @color-gray34;
                    font-weight: @font-weight__bold;
                    padding: @indent__base 0;
                });

                &::before {
                    .mq(- m; {
                        content: @icon-next;
                        display: inline-block;
                        font-family: 'luma-icons';
                        font-weight: @font-weight__regular;
                        margin-right: @indent__sm;
                        transform: rotate(180deg);
                        vertical-align: middle;
                    });
                }
            }
        }
    }
}

.menu-trigger__link {
    align-items: center;
    display: flex;
    margin-left: @indent__s;

    .mq(m; {
        display: none;
    });

    &::before {
        .svg-menu-icon-darkblue();
        .svg-menu-icon-darkblue-dims();
        content: '';
        display: inline-block;
    }

    &:hover {
        &::before {
            .svg-menu-icon-blue();
            .svg-menu-icon-blue-dims();
        }
    }

    span {
        display: none;
    }
}
