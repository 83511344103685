//
//  Product Label
//  ---------------------------------------------

.product-label {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    background: none;
    border-bottom-left-radius: 30px;

    .product-label-attribute,
    .product-label-discount {
        display: block;
        min-width: 140px;
        height: 35px;
        text-align: center;
        border-bottom-left-radius: 30px;
        color: white;
        padding: 7px @indent__s;
        font-weight: @font-weight__bold;
    }
}
