
//
//  Layered Navigation
//  ---------------------------------------------

.filter {
    .block-subtitle {
        display: none;
    }
}

.filter-title {
    strong {
        .btn-primary();
    }
}

.filter-options-item {
    border-bottom: 0;

    .filter-options-title {
        text-transform: none;
        color: @primary__color;
        padding: 0;
        font-size: @font-size__l;
        font-weight: @font-weight__bold;
        font-family: @secondary__font-family;

        .label {
            width: ~"calc(100% - 30px)";
        }
    }

    .filter-options-content {
        padding: 0;
    }

    .arrow {
        margin-top: 3px;
    }

    .reset {
        display: none;
    }
}

.filter-options {
    .items {
        .item {
            input[type=checkbox] {
                display: none;

                &:checked + label {
                    font-weight: normal;
                }
            }

            label {
                width: 100%;
                display: block;
                font-size: 1.5rem;
                font-weight: normal;
                color: @primary__color;
                position: relative;
                padding-left: 35px;
                cursor: pointer;

                &:before {
                    content: '';
                    display: block;
                    background: white;
                    width: 20px;
                    height: 20px;
                    border: 1px solid @border__color;
                    border-radius: 4px;
                    position: absolute;
                    top: 1px;
                    left: 0;
                }
            }

            input[type=checkbox]:checked + label:before,
            label:hover:before {
                background: url(../svg/src/check-icon-blue.svg) no-repeat center center;
            }
        }
    }
}

.mq(- xxs; {
    .filter-title {
        strong {
            width: 100%;
        }
    }
});

.mq(- m; {
    .filter-options {
        display: block;

        .category-list,
        .filter-options-item
        {
            display: none;
        }
    }

    .filter.active {
        .block-subtitle {
            border-bottom: 0;
            padding: 8px @indent__s @indent__base;
        }

        .filter-title {
            strong {
                top: 4px;
                width: 100%;
                text-align: right;
            }

            &:hover {
                strong:after {
                    color: @secondary__color;
                }
            }
        }

        .filter-options {
            overflow: auto;
            padding: @indent__s;

            .category-list,
            .filter-options-item
            {
                display: block;
            }
        }
    }
});

// quickfix for hiding the results on the search result page
.catalogsearch-result-index .layered-navigation__filter-option-count {
    display: none;
}

// override styling
#layered-filter-block .button-list {
    display: none;
}


.filter-options-content {
    a {
        margin: unset;
        padding: unset;
    }
}
