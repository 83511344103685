
//
//  Buttons
//  ---------------------------------------------

button {
    &:not(.primary)&:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear) {
        .btn();
    }
}

