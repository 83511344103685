
//
//  Product Main
//  ---------------------------------------------

@columns-large: l;

.product-page {
    .page-section--content {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.product-page__main {
    .mq(@columns-large; {
        .lib-css(display, flex);
        .lib-css(flex-direction, row);
        .lib-css(justify-content, space-between);
    });

    h1.page-title {
        font-size: 3.4rem;
        margin-bottom: 1rem;

        .mq(- m; {
            font-size: 3rem;
        });
    }
}

.product-page__media,
.product-page__options,
.product-page__addto-box
{
    .lib-css(flex, 1);
    width: 100%;

    .mq(@columns-large; {
        max-width: (1/3)*100%;
    });
}

.product-page__details,
.product-page__product-relations {
    .lib-clearfix();

    .block{
        .block-title{
            strong{
                .lib-bluebird-heading(h2);
            }
        }

        .block-content{
            .block-actions{
                display: none;
            }
        }
    }
}

.product-page__details {
    .lib-clearfix();
    margin-top: 3rem;

    .mq(- m; {
        .lib-css(flex-direction, column-reverse);
    });
}

.product-page__media {
    .mq(xs @columns-large; {
        width: 30%;
        display: inline-block;
    });
}

.product-page__options {
    margin: @indent__base 0 30px;

    .mq(xs @columns-large; {
        width: 70%;
        float: right;
        margin: 0;
        padding: 0 @indent__base;
    });

    .mq(@columns-large; {
        padding: 0 15px;
        margin: 0 15px;
    });
}

.product-info-stock-sku > .stock {
        display: none;
}

.product {
    // attributes
    &.ean_number, &.sku {
        font-size: @font-size__l;
        font-weight: normal;
        color: @primary__color;

        strong.type {
            font-weight: normal;
            &:after {
                content: ':';
            }
        }

        .value {
            display: inline-block;
        }
    }
}

.product-page__meta-info {
    margin-bottom: 2rem;
}

// Override Luma styles
.page-layout-1column .product.media,
.page-layout-1column .product-info-main {
    width: 100%;
}
