@charset "UTF-8";
/**
 * Bluebird Techniek Specialist Theme
 * @author   Bluebird <techtwo.nl>
 * @version  0.1.0
 */
@font-face {
  font-family: 'Barlow';
  src: url('../fonts/barlow/barlow-v12-latin-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  src: url('../fonts/barlow/barlow-v12-latin-300italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  src: url('../fonts/barlow/barlow-v12-latin-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  src: url('../fonts/barlow/barlow-v12-latin-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  src: url('../fonts/barlow/barlow-v12-latin-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  src: url('../fonts/barlow/barlow-v12-latin-500italic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  src: url('../fonts/barlow/barlow-v12-latin-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  src: url('../fonts/barlow/barlow-v12-latin-700italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/ubuntu/ubuntu-v20-latin-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/ubuntu/ubuntu-v20-latin-300italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/ubuntu/ubuntu-v20-latin-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/ubuntu/ubuntu-v20-latin-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/ubuntu/ubuntu-v20-latin-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/ubuntu/ubuntu-v20-latin-500italic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/ubuntu/ubuntu-v20-latin-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/ubuntu/ubuntu-v20-latin-700italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #939494;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  color: #939494;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  color: #939494;
}
input:-moz-placeholder {
  /* Firefox 18- */
  color: #939494;
}
/**
// The Magento default media query variables:
@screen__xxs: 320px;
@screen__xs: 480px;
@screen__s: 640px;
@screen__m: 768px;
@screen__l: 1024px;
@screen__xl: 1440px;

 Example:
 .selector {
     ...
     .mq(sm; {
         width: 100px;
         height: 200px
     });
 }
 Output:
 .selector {
     ...
 }
 @media screen and (min-width: 768px) {
     .selector {
         width: 100px;
         height: 200px;
     }
 }
 -
 Example using max width:
 .selector {
     ...
     .mq(- sm; {
         width: 100px;
         height: 200px
     });
 }
 Output:
 .selector {
     ...
 }
 @media screen and (max-width: 767px) {
     .selector {
         width: 100px;
         height: 200px;
     }
 }
 -
 Example using both min and max width:
 .selector {
     ...
     .mq(sm md; {
         width: 100px;
         height: 200px
     });
 }
 Output:
 .selector {
     ...
 }
 @media screen and (min-width: 768px) and (max-width: 991px) {
     .selector {
         width: 100px;
         height: 200px;
     }
 }
*/
* {
  box-sizing: border-box;
}
body {
  -webkit-font-smoothing: antialiased;
}
body.navigation--open {
  overflow-y: hidden;
}
ul > li,
ol > li {
  margin-bottom: 0;
}
a,
a.link {
  color: #009EE6;
  text-decoration: none;
}
a:visited,
a.link:visited {
  color: #009EE6;
  text-decoration: none;
}
a:hover,
a.link:hover {
  color: #009EE6;
  text-decoration: underline;
}
a:active,
a.link:active {
  color: #009EE6;
  text-decoration: none;
}
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear) {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):visited,
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):hover,
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):visited,
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):focus,
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):hover,
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):visited,
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):hover,
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):visited,
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):focus,
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):hover,
button:not(.primary)button:not(.add):not(.action-primary):not(.action-secondary):not(.action-show):not(.action-close):not(.action-clear):active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #939494;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  color: #939494;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  color: #939494;
}
input:-moz-placeholder {
  /* Firefox 18- */
  color: #939494;
}
select,
input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="file"],
input[type="email"] {
  color: #0D314D;
  padding: 0 20px;
  font-size: 1.6rem;
  font-family: 'Barlow', sans-serif;
  border: 1px solid #9B9B9B;
  border-radius: 20px;
  box-shadow: none;
  height: 42px;
}
select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="url"]:focus,
input[type="tel"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="datetime"]:focus,
input[type="file"]:focus,
input[type="email"]:focus {
  border: 1px solid #009EE6;
  box-shadow: none;
}
select.readonly,
input[type="text"].readonly,
input[type="password"].readonly,
input[type="url"].readonly,
input[type="tel"].readonly,
input[type="search"].readonly,
input[type="number"].readonly,
input[type="datetime"].readonly,
input[type="file"].readonly,
input[type="email"].readonly {
  opacity: 1;
  background: lightgrey;
  pointer-events: none;
}
select.readonly:focus,
input[type="text"].readonly:focus,
input[type="password"].readonly:focus,
input[type="url"].readonly:focus,
input[type="tel"].readonly:focus,
input[type="search"].readonly:focus,
input[type="number"].readonly:focus,
input[type="datetime"].readonly:focus,
input[type="file"].readonly:focus,
input[type="email"].readonly:focus {
  border: 1px solid #9B9B9B;
  box-shadow: none;
}
input:not([disabled]) {
  color: #0D314D;
  padding: 0 20px;
  font-size: 1.6rem;
  font-family: 'Barlow', sans-serif;
  border: 1px solid #9B9B9B;
  box-shadow: none;
}
input:not([disabled]):focus {
  border: 1px solid #009EE6;
  box-shadow: none;
}
input:not([disabled]).readonly {
  opacity: 1;
  background: lightgrey;
  pointer-events: none;
}
input:not([disabled]).readonly:focus {
  border: 1px solid #9B9B9B;
  box-shadow: none;
}
textarea,
textarea:not([disabled]) {
  color: #0D314D;
  padding: 12px 20px;
  font-size: 1.6rem;
  font-family: 'Barlow', sans-serif;
  border: 1px solid #9B9B9B;
  border-radius: 20px;
  box-shadow: none;
}
textarea:focus,
textarea:not([disabled]):focus {
  border: 1px solid #009EE6;
  box-shadow: none;
}
textarea.readonly,
textarea:not([disabled]).readonly {
  opacity: 1;
  background: lightgrey;
  pointer-events: none;
}
textarea.readonly:focus,
textarea:not([disabled]).readonly:focus {
  border: 1px solid #9B9B9B;
  box-shadow: none;
}
body {
  font-family: 'Barlow', sans-serif;
  font-size: 1.4rem;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: 'Ubuntu', sans-serif;
}
h1,
.h1 {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 4.2rem;
  margin-top: 0;
  margin-bottom: 3rem;
}
h1 span:not(.base),
.h1 span:not(.base) {
  color: #009EE6;
}
h2,
.h2 {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 3.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
h2 span:not(.base),
.h2 span:not(.base) {
  color: #009EE6;
}
h3,
.h3 {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h3 span:not(.base),
.h3 span:not(.base) {
  color: #009EE6;
}
h4,
.h4 {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 2.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h4 span:not(.base),
.h4 span:not(.base) {
  color: #009EE6;
}
h5,
.h5 {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h5 span:not(.base),
.h5 span:not(.base) {
  color: #009EE6;
}
h6,
.h6 {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h6 span:not(.base),
.h6 span:not(.base) {
  color: #009EE6;
}
.subtitle {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #009EE6;
}
.subtitle span:not(.base) {
  color: #009EE6;
}
.mage-dropdown-dialog .ui-dialog-buttonpane {
  position: relative;
}
.mage-dropdown-dialog .ui-dialog-buttonpane .action.close {
  z-index: 10;
  position: absolute;
  top: 28px;
  right: 5px;
  box-shadow: none;
  background: transparent;
  border: 0;
}
.mage-dropdown-dialog .ui-dialog-buttonpane .action.close .ui-button-text {
  display: none;
}
.mage-dropdown-dialog .ui-dialog-buttonpane .action.close:after {
  display: block;
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 57.24508050089445%;
  width: 20px;
  height: 20px;
}
.mage-dropdown-dialog .ui-dialog-buttonpane .action.close:hover {
  box-shadow: none;
  background: transparent;
  border: 0;
}
.mage-dropdown-dialog .ui-dialog-buttonpane .action.close:hover:after {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 60.82289803220036%;
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 767px) {
  .mage-dropdown-dialog .ui-dialog-buttonpane {
    position: absolute;
    right: 0;
    margin-top: -7px;
    margin-right: 10px;
  }
}
@-webkit-keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-moz-keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-webkit-keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}
@-moz-keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}
@keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}
.fa-phone {
  transform: rotateY(180deg);
}
.page-main {
  font-size: 1.6rem;
}
.page-main .block {
  margin-bottom: 0;
}
.has-page-sections .page-main {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}
.page-main__inner,
.page-section__inner {
  *zoom: 1;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
}
.page-main__inner:before,
.page-section__inner:before,
.page-main__inner:after,
.page-section__inner:after {
  content: " ";
  display: table;
}
.page-main__inner:after,
.page-section__inner:after {
  clear: both;
}
.page-main__inner:before,
.page-section__inner:before,
.page-main__inner:after,
.page-section__inner:after {
  content: '';
  display: table;
}
.page-main__inner:after,
.page-section__inner:after {
  clear: both;
}
@media screen and (max-width: 767px) {
  .page-main__inner,
  .page-section__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.page-section {
  padding: 60px 0;
}
.page-section--top {
  padding-top: 0;
}
.cms-index-index .page-section--top {
  padding-bottom: 0;
}
@media screen and (min-width: 768px) {
  .cms-index-index .page-section--top .page-section__inner {
    min-height: 250px;
  }
}
.cms-index-index .page-section--content {
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .cms-index-index .page-section--content {
    padding-top: 0;
  }
}
.page-section--aside {
  background-color: #F2F3F4;
  padding: 40px 0;
}
.page-section__title {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 3.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.page-section__title span:not(.base) {
  color: #009EE6;
}
.row-full-width-inner > figure[data-appearance="full-width"] {
  object-fit: cover;
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw !important;
  margin-right: -50vw !important;
}
@media screen and (min-width: 768px) {
  .row-full-width-inner > figure[data-appearance="full-width"] {
    width: calc(100vw - 4px);
  }
}
.row-full-width-inner > figure[data-appearance="full-width"] img {
  width: 100%;
}
[data-content-type='row'][data-appearance='full-width'] {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw !important;
  margin-right: -50vw !important;
}
@media screen and (min-width: 768px) {
  [data-content-type='row'][data-appearance='full-width'] {
    width: calc(100vw - 8px);
  }
}
[data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
  padding: 25px;
  box-sizing: border-box;
}
.columns {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.columns:after {
  clear: both;
  content: ' ';
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.columns .main {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
  min-width: 0;
  min-height: 0;
}
.columns .sidebar {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}
.columns .sidebar-additional {
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}
.sidebar + .sidebar {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .columns {
    display: block;
  }
  .columns .column.main {
    min-height: 300px;
  }
  .page-layout-1column .columns .column.main {
    width: 100%;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .page-layout-3columns .columns .column.main {
    width: 50%;
    display: inline-block;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .page-layout-2columns-left .columns .column.main {
    width: 75%;
    float: right;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .page-layout-2columns-right .columns .column.main {
    width: 75%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .page-layout-2columns-left .columns .column.main {
    padding-left: 30px;
  }
  .page-layout-2columns-right .columns .column.main {
    padding-right: 30px;
  }
  .page-layout-3columns .columns .column.main {
    float: left;
  }
  .columns .sidebar {
    padding-right: 30px;
  }
  .page-layout-3columns .columns .sidebar {
    width: 25%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .page-layout-2columns-left .columns .sidebar {
    width: 25%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .page-layout-2columns-right .columns .sidebar {
    width: 25%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .page-layout-2columns-left .columns .sidebar {
    clear: left;
    padding-right: 0;
  }
  .page-layout-2columns-right .columns .sidebar {
    padding-right: 0;
    padding-left: 30px;
  }
  .page-layout-3columns .columns .sidebar-additional {
    padding-right: 0;
    padding-left: 30px;
  }
}
@media screen and (max-width: 767px) {
  h1.page-title {
    font-size: 3rem;
  }
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.container:before,
.container:after {
  content: '';
  display: table;
}
.container:after {
  clear: both;
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.container-fluid:before,
.container-fluid:after {
  content: '';
  display: table;
}
.container-fluid:after {
  clear: both;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row:before,
.row:after {
  content: '';
  display: table;
}
.row:after {
  clear: both;
}
.row:before,
.row:after {
  content: '';
  display: table;
}
.row:after {
  clear: both;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media screen and (min-width: 480px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media screen and (min-width: 768px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media screen and (min-width: 1024px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
.btn,
button.btn,
a.btn {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.btn:visited,
button.btn:visited,
a.btn:visited,
.btn:focus,
button.btn:focus,
a.btn:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.btn:hover,
button.btn:hover,
a.btn:hover,
.btn:active,
button.btn:active,
a.btn:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.btn:visited,
button.btn:visited,
a.btn:visited,
.btn:focus,
button.btn:focus,
a.btn:focus,
.btn:hover,
button.btn:hover,
a.btn:hover,
.btn:active,
button.btn:active,
a.btn:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.btn-primary,
button.btn-primary,
a.btn-primary {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.btn-primary:visited,
button.btn-primary:visited,
a.btn-primary:visited,
.btn-primary:focus,
button.btn-primary:focus,
a.btn-primary:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.btn-primary:hover,
button.btn-primary:hover,
a.btn-primary:hover,
.btn-primary:active,
button.btn-primary:active,
a.btn-primary:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.btn-primary:visited,
button.btn-primary:visited,
a.btn-primary:visited,
.btn-primary:focus,
button.btn-primary:focus,
a.btn-primary:focus,
.btn-primary:hover,
button.btn-primary:hover,
a.btn-primary:hover,
.btn-primary:active,
button.btn-primary:active,
a.btn-primary:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.btn-secondary,
button.btn-secondary,
a.btn-secondary {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #009EE6;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #009EE6;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.btn-secondary:visited,
button.btn-secondary:visited,
a.btn-secondary:visited,
.btn-secondary:focus,
button.btn-secondary:focus,
a.btn-secondary:focus {
  background: #009EE6;
  color: white;
  text-decoration: none;
  border: 2px solid #009EE6;
}
.btn-secondary:hover,
button.btn-secondary:hover,
a.btn-secondary:hover,
.btn-secondary:active,
button.btn-secondary:active,
a.btn-secondary:active {
  background: #008ccd;
  color: white;
  text-decoration: none;
  border: 2px solid #008ccd;
}
.btn-secondary:visited,
button.btn-secondary:visited,
a.btn-secondary:visited,
.btn-secondary:focus,
button.btn-secondary:focus,
a.btn-secondary:focus,
.btn-secondary:hover,
button.btn-secondary:hover,
a.btn-secondary:hover,
.btn-secondary:active,
button.btn-secondary:active,
a.btn-secondary:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.btn-success,
button.btn-success,
a.btn-success {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.btn-success:visited,
button.btn-success:visited,
a.btn-success:visited,
.btn-success:focus,
button.btn-success:focus,
a.btn-success:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.btn-success:hover,
button.btn-success:hover,
a.btn-success:hover,
.btn-success:active,
button.btn-success:active,
a.btn-success:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.btn-success:visited,
button.btn-success:visited,
a.btn-success:visited,
.btn-success:focus,
button.btn-success:focus,
a.btn-success:focus,
.btn-success:hover,
button.btn-success:hover,
a.btn-success:hover,
.btn-success:active,
button.btn-success:active,
a.btn-success:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.btn-alt,
button.btn-alt,
a.btn-alt {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: transparent;
  color: #47BB0E;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.btn-alt:visited,
button.btn-alt:visited,
a.btn-alt:visited,
.btn-alt:focus,
button.btn-alt:focus,
a.btn-alt:focus {
  background: transparent;
  color: #47BB0E;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.btn-alt:hover,
button.btn-alt:hover,
a.btn-alt:hover,
.btn-alt:active,
button.btn-alt:active,
a.btn-alt:active {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.btn-alt:visited,
button.btn-alt:visited,
a.btn-alt:visited,
.btn-alt:focus,
button.btn-alt:focus,
a.btn-alt:focus,
.btn-alt:hover,
button.btn-alt:hover,
a.btn-alt:hover,
.btn-alt:active,
button.btn-alt:active,
a.btn-alt:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.btn-responsive,
button.btn-responsive,
a.btn-responsive {
  width: 100%;
}
.action.primary,
a.action.primary,
button.action.primary,
.sidebar .action.tocart {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.action.primary:visited,
a.action.primary:visited,
button.action.primary:visited,
.sidebar .action.tocart:visited,
.action.primary:focus,
a.action.primary:focus,
button.action.primary:focus,
.sidebar .action.tocart:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.action.primary:hover,
a.action.primary:hover,
button.action.primary:hover,
.sidebar .action.tocart:hover,
.action.primary:active,
a.action.primary:active,
button.action.primary:active,
.sidebar .action.tocart:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.action.primary:visited,
a.action.primary:visited,
button.action.primary:visited,
.sidebar .action.tocart:visited,
.action.primary:focus,
a.action.primary:focus,
button.action.primary:focus,
.sidebar .action.tocart:focus,
.action.primary:hover,
a.action.primary:hover,
button.action.primary:hover,
.sidebar .action.tocart:hover,
.action.primary:active,
a.action.primary:active,
button.action.primary:active,
.sidebar .action.tocart:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.action.primary:visited,
a.action.primary:visited,
button.action.primary:visited,
.sidebar .action.tocart:visited,
.action.primary:focus,
a.action.primary:focus,
button.action.primary:focus,
.sidebar .action.tocart:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.action.primary:hover,
a.action.primary:hover,
button.action.primary:hover,
.sidebar .action.tocart:hover,
.action.primary:active,
a.action.primary:active,
button.action.primary:active,
.sidebar .action.tocart:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.action.primary:visited,
a.action.primary:visited,
button.action.primary:visited,
.sidebar .action.tocart:visited,
.action.primary:focus,
a.action.primary:focus,
button.action.primary:focus,
.sidebar .action.tocart:focus,
.action.primary:hover,
a.action.primary:hover,
button.action.primary:hover,
.sidebar .action.tocart:hover,
.action.primary:active,
a.action.primary:active,
button.action.primary:active,
.sidebar .action.tocart:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.fieldset > .field > .label,
.fieldset > .fields > .field > .label {
  font-family: 'Ubuntu', sans-serif;
  color: #0D314D;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0 0 2px;
}
.fieldset > .field,
.fieldset > .fields > .field {
  margin: 0 0 10px;
}
.fieldset > .field.required > .label:after,
.fieldset > .fields > .field.required > .label:after,
.fieldset > .field._required > .label:after,
.fieldset > .fields > .field._required > .label:after {
  color: #0D314D;
  margin: 0 0 0 2px;
  font-size: 1.6rem;
}
.field .control.has-tooltip {
  position: relative;
  padding-right: 30px;
}
.file-uploader input[type="file"] {
  padding: 10px 20px;
}
.fieldset .error-message {
  color: #d0021b;
  font-size: 1.2rem;
}
.page-header .switcher .options,
.page-footer .switcher .options {
  display: inline-block;
  position: relative;
}
.page-header .switcher .options:before,
.page-footer .switcher .options:before,
.page-header .switcher .options:after,
.page-footer .switcher .options:after {
  content: '';
  display: table;
}
.page-header .switcher .options:after,
.page-footer .switcher .options:after {
  clear: both;
}
.page-header .switcher .options .action.toggle,
.page-footer .switcher .options .action.toggle {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}
.page-header .switcher .options .action.toggle:after,
.page-footer .switcher .options .action.toggle:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
  line-height: 22px;
  color: inherit;
  content: '\e607';
  font-family: 'icons-blank-theme';
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}
.page-header .switcher .options .action.toggle:hover:after,
.page-footer .switcher .options .action.toggle:hover:after {
  color: inherit;
}
.page-header .switcher .options .action.toggle:active:after,
.page-footer .switcher .options .action.toggle:active:after {
  color: inherit;
}
.page-header .switcher .options .action.toggle.active,
.page-footer .switcher .options .action.toggle.active {
  display: inline-block;
  text-decoration: none;
}
.page-header .switcher .options .action.toggle.active:after,
.page-footer .switcher .options .action.toggle.active:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
  line-height: 22px;
  color: inherit;
  content: '\e618';
  font-family: 'icons-blank-theme';
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}
.page-header .switcher .options .action.toggle.active:hover:after,
.page-footer .switcher .options .action.toggle.active:hover:after {
  color: inherit;
}
.page-header .switcher .options .action.toggle.active:active:after,
.page-footer .switcher .options .action.toggle.active:active:after {
  color: inherit;
}
.page-header .switcher .options ul.dropdown,
.page-footer .switcher .options ul.dropdown {
  margin: 0;
  padding: 0;
  list-style: none none;
  background: #FFFFFF;
  border: 1px solid #bbb;
  margin-top: 4px;
  min-width: 100%;
  z-index: 100;
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}
.page-header .switcher .options ul.dropdown li,
.page-footer .switcher .options ul.dropdown li {
  margin: 0;
  padding: 3px 5px;
}
.page-header .switcher .options ul.dropdown li:hover,
.page-footer .switcher .options ul.dropdown li:hover {
  background: #e8e8e8;
  cursor: pointer;
}
.page-header .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before,
.page-header .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
  border-bottom-style: solid;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.page-header .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before {
  border: 6px solid;
  border-color: transparent transparent #FFFFFF transparent;
  z-index: 99;
}
.page-header .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
  border: 7px solid;
  border-color: transparent transparent #bbb transparent;
  z-index: 98;
}
.page-header .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before {
  left: 10px;
  top: -12px;
}
.page-header .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
  left: 9px;
  top: -14px;
}
.page-header .switcher .options.active,
.page-footer .switcher .options.active {
  overflow: visible;
}
.page-header .switcher .options.active ul.dropdown,
.page-footer .switcher .options.active ul.dropdown {
  display: block;
}
.page-header .switcher .options .action.toggle:after,
.page-footer .switcher .options .action.toggle:after,
.page-header .switcher .options .action.toggle.active:after,
.page-footer .switcher .options .action.toggle.active:after {
  display: inline-block;
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 12.062937062937063%;
  width: 10px;
  height: 7px;
  margin-left: 6px;
  vertical-align: middle;
}
.page-header .switcher .options .action.toggle.active:after,
.page-footer .switcher .options .action.toggle.active:after {
  transform: rotate(180deg);
}
.page-header .switcher .options ul.dropdown,
.page-footer .switcher .options ul.dropdown {
  min-width: 0;
  right: 0;
  top: 35px;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  padding: 10px;
}
.page-header .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before {
  border: 12px solid;
  border-color: transparent transparent white transparent;
  top: -24px;
  left: auto;
  right: 22px;
}
.page-header .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
  display: none;
}
.page-header .switcher .options ul.dropdown li:hover,
.page-footer .switcher .options ul.dropdown li:hover {
  background: inherit;
  text-decoration: underline;
}
.page-header .switcher .options ul.dropdown li a.switcher__link,
.page-footer .switcher .options ul.dropdown li a.switcher__link {
  display: flex;
  align-items: center;
  padding: 5px;
}
.page-header .switcher .options ul.dropdown li .switcher__lang,
.page-footer .switcher .options ul.dropdown li .switcher__lang {
  margin-left: 10px;
}
.switcher__image {
  width: 28px;
  height: 20px;
  display: inline-block;
  background-size: cover;
  vertical-align: bottom;
  border-radius: 4px;
}
.switcher-trigger .switcher__image {
  margin-top: 4px;
}
.block.newsletter {
  margin-bottom: 0;
  width: 100%;
}
@media screen and (min-width: 640px) {
  .block.newsletter {
    width: 100%;
  }
}
.block.newsletter .form.subscribe {
  position: relative;
}
.block.newsletter .field.newsletter .control:before {
  display: none;
}
.block.newsletter input {
  padding-left: 20px;
  padding-right: 140px;
  width: calc(100% - 2px);
}
.block.newsletter .actions {
  position: absolute;
  width: auto;
  top: 0;
  right: 0;
}
.block.newsletter .action.subscribe {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #009EE6;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #009EE6;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.block.newsletter .action.subscribe:visited,
.block.newsletter .action.subscribe:focus {
  background: #009EE6;
  color: white;
  text-decoration: none;
  border: 2px solid #009EE6;
}
.block.newsletter .action.subscribe:hover,
.block.newsletter .action.subscribe:active {
  background: #008ccd;
  color: white;
  text-decoration: none;
  border: 2px solid #008ccd;
}
.block.newsletter .action.subscribe:visited,
.block.newsletter .action.subscribe:focus,
.block.newsletter .action.subscribe:hover,
.block.newsletter .action.subscribe:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.block.newsletter .action.subscribe:visited,
.block.newsletter .action.subscribe:focus {
  background: #009EE6;
  color: white;
  text-decoration: none;
  border: 2px solid #009EE6;
}
.block.newsletter .action.subscribe:hover,
.block.newsletter .action.subscribe:active {
  background: #008ccd;
  color: white;
  text-decoration: none;
  border: 2px solid #008ccd;
}
.block.newsletter .action.subscribe:visited,
.block.newsletter .action.subscribe:focus,
.block.newsletter .action.subscribe:hover,
.block.newsletter .action.subscribe:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.company-account-index .company-create__message--success {
  color: #000000;
}
.company-account-index .company-create__message--success div[data-element="main"] div[data-element="inner"] {
  padding: 0 !important;
  margin: 0 !important;
}
.company-role-edit .form-edit-role .note {
  display: none;
}
.block-collapsible-nav .item.current a,
.block-collapsible-nav .item.current > strong {
  border-color: #0D314D;
}
.login-container .block.block-new-company {
  margin-top: 3rem;
}
.account .title-actions-links {
  margin-top: 0;
}
@media screen and (min-width: 768px) {
  .account .title-actions-links {
    margin-top: 0;
  }
}
.account .page-main .page-title-wrapper .page-title {
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  .account .page-main .page-title-wrapper .page-title {
    margin-bottom: 2rem;
  }
}
.account .page-main .column.main .block {
  margin-bottom: 50px;
}
.account .page-main .column.main a {
  text-decoration: underline;
}
.account .page-main .column.main a:hover {
  text-decoration: none;
}
.account .page-main .column.main .actions-toolbar button.action {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.account .page-main .column.main .actions-toolbar button.action:visited,
.account .page-main .column.main .actions-toolbar button.action:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.account .page-main .column.main .actions-toolbar button.action:hover,
.account .page-main .column.main .actions-toolbar button.action:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.account .page-main .column.main .actions-toolbar button.action:visited,
.account .page-main .column.main .actions-toolbar button.action:focus,
.account .page-main .column.main .actions-toolbar button.action:hover,
.account .page-main .column.main .actions-toolbar button.action:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.account .page-main .column.main .actions-toolbar button.action:visited,
.account .page-main .column.main .actions-toolbar button.action:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.account .page-main .column.main .actions-toolbar button.action:hover,
.account .page-main .column.main .actions-toolbar button.action:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.account .page-main .column.main .actions-toolbar button.action:visited,
.account .page-main .column.main .actions-toolbar button.action:focus,
.account .page-main .column.main .actions-toolbar button.action:hover,
.account .page-main .column.main .actions-toolbar button.action:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.account .page-main .column.main .actions-toolbar a {
  text-decoration: none;
}
.account .search-sku button[type='submit']:after {
  line-height: 42px;
  margin-left: -8px;
}
.create.account .field.reseller-id {
  display: none;
}
.price-label {
  width: 100%;
}
.price-box.price-final_price {
  display: flex;
  flex-direction: column;
}
.old-price .price-container {
  display: flex;
  flex-direction: column-reverse;
}
.old-price .price-container .price {
  color: #009EE6;
}
.old-price .price-container .old-price-excl-vat {
  line-height: normal;
}
.old-price .price-container .old-price-excl-vat .price {
  color: #009EE6;
}
.old-price .price-container .price-excluding-tax,
.old-price .price-container .price-including-tax {
  margin: 2px 0;
}
.old-price .price-container .price-excluding-tax:before {
  display: none;
}
.old-price .price-container .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #939494;
  text-transform: lowercase;
}
.old-price .price-container .price-including-tax .price {
  color: #666;
}
.old-price .price-container .price-including-tax:before {
  display: none;
}
.old-price .price-container .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #939494;
  text-transform: lowercase;
}
.old-price .price-container .price-label {
  display: none;
}
.old-price .price-container .price {
  text-decoration: line-through;
  font-weight: normal;
  color: #666;
}
.old-price .price-container .price-wrapper:after {
  text-decoration: line-through;
}
.price-tier_price .prices-tier.items {
  margin: 20px 0 15px 0;
}
.price-tier_price .prices-tier.items .item {
  color: #0D314D;
  font-size: 1.3rem;
  margin: 0;
}
@media screen and (max-width: 1023px) {
  .price-tier_price .prices-tier.items .item {
    font-size: 1.6rem;
  }
}
.price-tier_price .prices-tier.items .item .price-container {
  display: inline;
}
.price-tier_price .prices-tier.items .item .price-tier_price .price-wrapper {
  display: inline;
}
.price-tier_price .prices-tier.items .item .price-tier_price .price-wrapper .price {
  color: #0D314D;
  font-size: 1.6rem;
}
.price-tier_price .prices-tier.items .item .benefit {
  color: #47BB0E;
}
.product-info-price .benefit {
  display: none;
}
.product-info-price .prices-tier.items {
  padding: 0;
  margin: 2rem 0 0;
}
.product-info-price .prices-tier.items .item,
.product-info-price .prices-tier.items .price {
  font-size: 1.6rem !important;
  font-weight: normal !important;
}
.product-info-price .prices-tier.items .price-excluding-tax .price {
  font-size: 1.6rem !important;
  font-weight: 700 !important;
}
.product-info-price .prices-tier .price-including-tax {
  display: none !important;
}
.product-info-price .prices-tier .price-container .price-including-tax + .price-excluding-tax:before {
  display: none;
}
.product-info-price .prices-tier .price-container .price-including-tax + .price-excluding-tax:last-child:after {
  content: ' ' attr(data-label);
}
.product-info-main .product-info-price .price-including-tax + .price-excluding-tax {
  font-size: 1.6rem !important;
}
.prod-qty {
  font-weight: 700;
}
.product-item .product-item-details .product-item-name {
  margin: 15px 0 10px;
}
.product-item .product-item-details .product-item-name a {
  font-size: 2rem;
  font-family: 'Ubuntu', sans-serif;
  color: #000;
}
.product-item .product-item-details .product-item-name a:hover {
  text-decoration: none;
  color: #009EE6;
}
.product-item .product-item-details .product-item-actions {
  display: block;
}
.product-item .product-item-details .product-item-actions .actions-primary {
  display: block;
}
.product-item .product-item-details .product-item-actions .actions-primary .tocart {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  position: relative;
  padding-right: 65px;
  padding-left: 25px;
  text-align: left;
  width: 100%;
  max-width: 320px;
}
.product-item .product-item-details .product-item-actions .actions-primary .tocart:visited,
.product-item .product-item-details .product-item-actions .actions-primary .tocart:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.product-item .product-item-details .product-item-actions .actions-primary .tocart:hover,
.product-item .product-item-details .product-item-actions .actions-primary .tocart:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.product-item .product-item-details .product-item-actions .actions-primary .tocart:visited,
.product-item .product-item-details .product-item-actions .actions-primary .tocart:focus,
.product-item .product-item-details .product-item-actions .actions-primary .tocart:hover,
.product-item .product-item-details .product-item-actions .actions-primary .tocart:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.product-item .product-item-details .product-item-actions .actions-primary .tocart:visited,
.product-item .product-item-details .product-item-actions .actions-primary .tocart:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.product-item .product-item-details .product-item-actions .actions-primary .tocart:hover,
.product-item .product-item-details .product-item-actions .actions-primary .tocart:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.product-item .product-item-details .product-item-actions .actions-primary .tocart:visited,
.product-item .product-item-details .product-item-actions .actions-primary .tocart:focus,
.product-item .product-item-details .product-item-actions .actions-primary .tocart:hover,
.product-item .product-item-details .product-item-actions .actions-primary .tocart:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.product-item .product-item-details .product-item-actions .actions-primary .tocart:before {
  content: "";
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 24.057450628366247%;
  width: 34px;
  height: 22px;
  display: block;
  position: absolute;
  right: 20px;
  top: 9px;
}
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart) {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  width: 100%;
  max-width: 320px;
}
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):visited,
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):hover,
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):visited,
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):focus,
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):hover,
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):visited,
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):hover,
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):visited,
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):focus,
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):hover,
.product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.product-item .product-item-details .product-item-actions .actions-secondary {
  display: none !important;
}
.product-item .product-item-photo {
  display: block;
}
.product-item .product-reviews-summary {
  display: none;
}
.product-item .price-box {
  margin: 10px 0 30px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.product-item .price-box .price-container {
  display: flex;
  flex-direction: column-reverse;
}
.product-item .price-box .price-container .price {
  color: #009EE6;
}
.product-item .price-box .price-container .old-price-excl-vat {
  line-height: normal;
}
.product-item .price-box .price-container .old-price-excl-vat .price {
  color: #009EE6;
  font-size: 2rem;
}
.product-item .price-box .price-container .price-excluding-tax,
.product-item .price-box .price-container .price-including-tax {
  margin: 2px 0;
}
.product-item .price-box .price-container .price-excluding-tax .price {
  font-size: 2rem;
}
.product-item .price-box .price-container .price-excluding-tax:before {
  display: none;
}
.product-item .price-box .price-container .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.2rem;
  text-transform: lowercase;
}
.product-item .price-box .price-container .price-including-tax .price {
  font-size: 1.5rem;
  color: #666;
}
.product-item .price-box .price-container .price-including-tax:before {
  display: none;
}
.product-item .price-box .price-container .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.1rem;
  text-transform: lowercase;
}
.product-item .price-box .old-price .price-container {
  display: flex;
  flex-direction: column-reverse;
}
.product-item .price-box .old-price .price-container .price {
  color: #009EE6;
}
.product-item .price-box .old-price .price-container .old-price-excl-vat {
  line-height: normal;
}
.product-item .price-box .old-price .price-container .old-price-excl-vat .price {
  color: #009EE6;
}
.product-item .price-box .old-price .price-container .price-excluding-tax,
.product-item .price-box .old-price .price-container .price-including-tax {
  margin: 2px 0;
}
.product-item .price-box .old-price .price-container .price-excluding-tax:before {
  display: none;
}
.product-item .price-box .old-price .price-container .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #939494;
  text-transform: lowercase;
}
.product-item .price-box .old-price .price-container .price-including-tax .price {
  color: #666;
}
.product-item .price-box .old-price .price-container .price-including-tax:before {
  display: none;
}
.product-item .price-box .old-price .price-container .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #939494;
  text-transform: lowercase;
}
.product-item .price-box .old-price .price-container .price-label {
  display: none;
}
.product-item .price-box .old-price .price-container .price {
  text-decoration: line-through;
  font-weight: normal;
  color: #666;
}
.product-item .price-box .old-price .price-container .price-wrapper:after {
  text-decoration: line-through;
}
.product-item .price-box .special-price {
  margin-left: 2px;
}
.product-item .price-box .old-price {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-item .price-box .old-price .price-container .price-excluding-tax:after,
.product-item .price-box .old-price .price-container .price-including-tax:after,
.product-item .price-box .old-price .price-container .price-excluding-tax:before,
.product-item .price-box .old-price .price-container .price-including-tax:before {
  display: none;
}
.product-item .price-box .price-to {
  display: none;
}
.product-item .price-box .price-from .price-label {
  order: 1;
  color: #009EE6;
  font-weight: 700;
}
.product-item .price-box .price-from .price-label:after {
  content: ':';
}
.breadcrumbs .items {
  font-size: 1.4rem;
  color: #363636;
}
.breadcrumbs a,
.breadcrumbs a:visited {
  color: #363636;
  text-decoration: underline;
}
.breadcrumbs a:hover {
  text-decoration: none;
  color: #009EE6;
}
.breadcrumbs .item:not(:last-child):after {
  margin: 3px 5px;
}
.action.print {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.action.print:visited,
.action.print:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.action.print:hover,
.action.print:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.action.print:visited,
.action.print:focus,
.action.print:hover,
.action.print:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.action.print:visited,
.action.print:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.action.print:hover,
.action.print:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.action.print:visited,
.action.print:focus,
.action.print:hover,
.action.print:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.comparison .product.info .price-box {
  margin: 10px 0 30px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.comparison .product.info .price-box .price-container {
  display: flex;
  flex-direction: column-reverse;
}
.comparison .product.info .price-box .price-container .price {
  color: #009EE6;
}
.comparison .product.info .price-box .price-container .old-price-excl-vat {
  line-height: normal;
}
.comparison .product.info .price-box .price-container .old-price-excl-vat .price {
  color: #009EE6;
  font-size: 2rem;
}
.comparison .product.info .price-box .price-container .price-excluding-tax,
.comparison .product.info .price-box .price-container .price-including-tax {
  margin: 2px 0;
}
.comparison .product.info .price-box .price-container .price-excluding-tax .price {
  font-size: 2rem;
}
.comparison .product.info .price-box .price-container .price-excluding-tax:before {
  display: none;
}
.comparison .product.info .price-box .price-container .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.2rem;
  text-transform: lowercase;
}
.comparison .product.info .price-box .price-container .price-including-tax .price {
  font-size: 1.5rem;
  color: #666;
}
.comparison .product.info .price-box .price-container .price-including-tax:before {
  display: none;
}
.comparison .product.info .price-box .price-container .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.1rem;
  text-transform: lowercase;
}
.comparison .product.info .price-box .old-price .price-container {
  display: flex;
  flex-direction: column-reverse;
}
.comparison .product.info .price-box .old-price .price-container .price {
  color: #009EE6;
}
.comparison .product.info .price-box .old-price .price-container .old-price-excl-vat {
  line-height: normal;
}
.comparison .product.info .price-box .old-price .price-container .old-price-excl-vat .price {
  color: #009EE6;
}
.comparison .product.info .price-box .old-price .price-container .price-excluding-tax,
.comparison .product.info .price-box .old-price .price-container .price-including-tax {
  margin: 2px 0;
}
.comparison .product.info .price-box .old-price .price-container .price-excluding-tax:before {
  display: none;
}
.comparison .product.info .price-box .old-price .price-container .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #939494;
  text-transform: lowercase;
}
.comparison .product.info .price-box .old-price .price-container .price-including-tax .price {
  color: #666;
}
.comparison .product.info .price-box .old-price .price-container .price-including-tax:before {
  display: none;
}
.comparison .product.info .price-box .old-price .price-container .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #939494;
  text-transform: lowercase;
}
.comparison .product.info .price-box .old-price .price-container .price-label {
  display: none;
}
.comparison .product.info .price-box .old-price .price-container .price {
  text-decoration: line-through;
  font-weight: normal;
  color: #666;
}
.comparison .product.info .price-box .old-price .price-container .price-wrapper:after {
  text-decoration: line-through;
}
.comparison .product.info .price-box .special-price {
  margin-left: 2px;
}
.comparison .product.info .price-box .old-price {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.comparison .product.info .price-box .old-price .price-container .price-excluding-tax:after,
.comparison .product.info .price-box .old-price .price-container .price-including-tax:after,
.comparison .product.info .price-box .old-price .price-container .price-excluding-tax:before,
.comparison .product.info .price-box .old-price .price-container .price-including-tax:before {
  display: none;
}
.comparison .action.tocart {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  position: relative;
  padding-right: 65px;
  padding-left: 25px;
  text-align: left;
}
.comparison .action.tocart:visited,
.comparison .action.tocart:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.comparison .action.tocart:hover,
.comparison .action.tocart:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.comparison .action.tocart:visited,
.comparison .action.tocart:focus,
.comparison .action.tocart:hover,
.comparison .action.tocart:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.comparison .action.tocart:visited,
.comparison .action.tocart:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.comparison .action.tocart:hover,
.comparison .action.tocart:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.comparison .action.tocart:visited,
.comparison .action.tocart:focus,
.comparison .action.tocart:hover,
.comparison .action.tocart:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.comparison .action.tocart:before {
  content: "";
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 24.057450628366247%;
  width: 34px;
  height: 22px;
  display: block;
  position: absolute;
  right: 20px;
  top: 9px;
}
.comparison .table-comparison .cell {
  padding: 10px;
  width: 210px;
}
.comparison .table-comparison tr:nth-child(even) {
  background: #F2F3F4;
}
.message.global.cookie {
  background: #B9C4CD;
  padding: 15px 0;
  z-index: 30;
}
.message.global.cookie .content {
  *zoom: 1;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
}
.message.global.cookie .content:before,
.message.global.cookie .content:after {
  content: " ";
  display: table;
}
.message.global.cookie .content:after {
  clear: both;
}
.message.global.cookie .content:before,
.message.global.cookie .content:after {
  content: '';
  display: table;
}
.message.global.cookie .content:after {
  clear: both;
}
@media screen and (max-width: 767px) {
  .message.global.cookie .content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.message.global.cookie .content:before,
.message.global.cookie .content:after {
  display: none;
}
.message.global.cookie .content p {
  margin-right: 10px;
}
.message.global.cookie .actions {
  margin-top: 0;
}
.message.global.cookie .action.allow {
  min-width: 240px;
}
@media screen and (min-width: 768px) {
  .message.global.cookie .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (max-width: 479px) {
  .message.global.cookie .action.allow {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .message.global.demo {
    font-size: 1.125rem;
  }
}
.cart.table-wrapper .actions-toolbar {
  min-height: 35px;
}
.cart.table-wrapper .col.qty .input-text {
  width: 54px;
}
.cart-summary._sticky {
  margin-top: 20px;
}
.cart-summary .checkout-methods-items .action.primary.checkout {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.cart-summary .checkout-methods-items .action.primary.checkout:visited,
.cart-summary .checkout-methods-items .action.primary.checkout:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.cart-summary .checkout-methods-items .action.primary.checkout:hover,
.cart-summary .checkout-methods-items .action.primary.checkout:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.cart-summary .checkout-methods-items .action.primary.checkout:visited,
.cart-summary .checkout-methods-items .action.primary.checkout:focus,
.cart-summary .checkout-methods-items .action.primary.checkout:hover,
.cart-summary .checkout-methods-items .action.primary.checkout:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.cart-summary .checkout-methods-items .action.primary.checkout:visited,
.cart-summary .checkout-methods-items .action.primary.checkout:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.cart-summary .checkout-methods-items .action.primary.checkout:hover,
.cart-summary .checkout-methods-items .action.primary.checkout:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.cart-summary .checkout-methods-items .action.primary.checkout:visited,
.cart-summary .checkout-methods-items .action.primary.checkout:focus,
.cart-summary .checkout-methods-items .action.primary.checkout:hover,
.cart-summary .checkout-methods-items .action.primary.checkout:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.page-main .cart-summary .block {
  display: none;
}
.checkout-cart-index .page-header__minicart .minicart-wrapper .action.showcart {
  pointer-events: none;
}
.checkout-cart-index .page-header__minicart .minicart-wrapper .action.showcart .counter-number:after {
  display: none;
}
.checkout-cart-index #shopping-cart-table .item-actions .actions-toolbar .fa-calendar-check,
.checkout-cart-index #shopping-cart-table .item-actions .actions-toolbar .fa-spinner {
  pointer-events: none;
  color: #757575;
  display: block;
  position: absolute;
  right: 15px;
  top: 35px;
}
.checkout-cart-index #shopping-cart-table .item-actions .actions-toolbar > i {
  color: #757575;
}
.checkout-cart-index #shopping-cart-table .item-actions .actions-toolbar .action.action-delete {
  right: 0;
  top: 30px;
}
.checkout-cart-index #shopping-cart-table .item-actions .actions-toolbar .action.action-edit {
  right: 45px;
  top: 30px;
  display: none;
}
.checkout-cart-index .ui-datepicker .ui-datepicker-header .ui-datepicker-prev .ui-icon-circle-triangle-w {
  border-color: transparent #009EE6 transparent transparent;
}
.checkout-cart-index .ui-datepicker .ui-datepicker-header .ui-datepicker-next .ui-icon-circle-triangle-e {
  border-color: transparent transparent transparent #009EE6;
}
.checkout-cart-index .ui-datepicker .ui-datepicker-calendar .ui-state-active {
  background: #66cfff;
}
.checkout-cart-index .ui-datepicker .ui-datepicker-calendar .ui-state-hover {
  background: #e6f7ff;
}
.checkout-cart-index .action-datepicker__wrapper {
  position: relative;
  max-width: 215px;
  float: right;
  margin-right: 70px;
}
@media screen and (max-width: 767px) {
  .checkout-cart-index .action-datepicker__wrapper {
    max-width: 100%;
  }
}
.checkout-cart-index .action-datepicker__wrapper .action-datepicker__input {
  cursor: pointer;
}
.checkout-cart-index .action-datepicker__wrapper .action-datepicker__label {
  color: #757575;
  font-size: 1.1rem;
  margin-left: 10px;
}
.checkout-cart-index .cart-discount .field {
  width: 100%;
  padding-right: 10px;
}
.checkout-cart-index .cart-discount .actions-toolbar {
  width: 220px;
}
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  width: 100%;
}
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:visited,
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:hover,
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:visited,
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:focus,
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:hover,
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:visited,
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:hover,
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:visited,
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:focus,
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:hover,
.checkout-cart-index .cart-discount .actions-toolbar .primary .action.primary:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.cart.table-wrapper .col.qty .input-text {
  color: #0D314D;
  padding: 0 20px;
  font-size: 1.6rem;
  font-family: 'Barlow', sans-serif;
  border: 1px solid #9B9B9B;
  border-radius: 5px;
  box-shadow: none;
  height: 42px;
  text-align: center;
  padding: 0 10px;
}
.cart.table-wrapper .col.qty .input-text:focus {
  border: 1px solid #009EE6;
  box-shadow: none;
}
.cart.table-wrapper .col.qty .input-text.readonly {
  opacity: 1;
  background: lightgrey;
  pointer-events: none;
}
.cart.table-wrapper .col.qty .input-text.readonly:focus {
  border: 1px solid #9B9B9B;
  box-shadow: none;
}
.cart.table-wrapper .col.qty .control.qty {
  margin-top: 10px;
}
.cart.table-wrapper .product-item-sku,
.cart.table-wrapper .customer-sku {
  display: block;
}
@media screen and (min-width: 768px) {
  .cart.table-wrapper .product-item-photo {
    max-width: 100px;
  }
}
.cart.item {
  background: white;
}
.cart.item .pricebox {
  display: flex;
  flex-direction: column-reverse;
}
.cart.item .pricebox .price {
  color: #009EE6;
}
.cart.item .pricebox .old-price-excl-vat {
  line-height: normal;
}
.cart.item .pricebox .old-price-excl-vat .price {
  color: #009EE6;
  font-size: 1.8rem;
}
.cart.item .pricebox .price-excluding-tax,
.cart.item .pricebox .price-including-tax {
  margin: 2px 0;
}
.cart.item .pricebox .price-excluding-tax .price {
  font-size: 1.8rem;
}
.cart.item .pricebox .price-excluding-tax:before {
  display: none;
}
.cart.item .pricebox .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.1rem;
  text-transform: lowercase;
}
.cart.item .pricebox .price-including-tax .price {
  font-size: 1.4rem;
  color: #666;
}
.cart.item .pricebox .price-including-tax:before {
  display: none;
}
.cart.item .pricebox .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1rem;
  text-transform: lowercase;
}
.cart-discount .block.discount {
  width: 100%;
}
.crosssell .block-title strong {
  color: #0D314D;
  font-size: 2.2rem;
  font-family: 'Ubuntu', sans-serif;
}
.opc-progress-bar-item._active::before {
  background: #0D314D;
  border-color: #0D314D;
}
.opc-progress-bar-item._active > span:before {
  background: #0D314D;
  border-color: #0D314D;
}
.opc-progress-bar-item._active > span:after {
  border-color: #0D314D;
}
.opc-wrapper .shipping-address-item.selected-item {
  border-color: #0D314D;
}
.opc-wrapper .shipping-address-item.selected-item:after {
  background: #0D314D;
}
.paypal-review-discount .block > .title strong,
.cart-discount .block > .title strong {
  color: #009EE6;
}
.cart-totals .grand.excl + .grand.incl .mark,
.cart-totals .grand.excl + .grand.incl .amount,
.opc-block-summary .grand.excl + .grand.incl .mark,
.opc-block-summary .grand.excl + .grand.incl .amount {
  border-top: 0;
  font-size: 1.6rem;
  padding-top: 0;
}
.cart-totals .grand.excl + .grand.incl .mark strong,
.cart-totals .grand.excl + .grand.incl .amount strong,
.opc-block-summary .grand.excl + .grand.incl .mark strong,
.opc-block-summary .grand.excl + .grand.incl .amount strong {
  font-weight: 400;
}
.checkout-index-index .page-header__minicart .minicart-wrapper .action.showcart {
  pointer-events: none;
}
.checkout-index-index .page-header__minicart .minicart-wrapper .action.showcart .counter-number:after {
  display: none;
}
.checkout-index-index .checkout-shipping-address .field .control._with-tooltip input {
  width: calc(100% - 36px);
}
.checkout-index-index .minicart-items .subtotal {
  display: flex;
  flex-direction: column-reverse;
}
.checkout-index-index .minicart-items .subtotal .price {
  color: #009EE6;
}
.checkout-index-index .minicart-items .subtotal .old-price-excl-vat {
  line-height: normal;
}
.checkout-index-index .minicart-items .subtotal .old-price-excl-vat .price {
  color: #009EE6;
  font-size: 1.8rem;
}
.checkout-index-index .minicart-items .subtotal .price-excluding-tax,
.checkout-index-index .minicart-items .subtotal .price-including-tax {
  margin: 2px 0;
}
.checkout-index-index .minicart-items .subtotal .price-excluding-tax .price {
  font-size: 1.8rem;
}
.checkout-index-index .minicart-items .subtotal .price-excluding-tax:before {
  display: none;
}
.checkout-index-index .minicart-items .subtotal .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.1rem;
  text-transform: lowercase;
}
.checkout-index-index .minicart-items .subtotal .price-including-tax .price {
  font-size: 1.4rem;
  color: #666;
}
.checkout-index-index .minicart-items .subtotal .price-including-tax:before {
  display: none;
}
.checkout-index-index .minicart-items .subtotal .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1rem;
  text-transform: lowercase;
}
.checkout-index-index .opc-block-summary .minicart-items-wrapper {
  max-height: 700px;
}
.checkout-index-index .opc-block-summary .product.options span span {
  color: #009EE6;
  text-decoration: underline;
}
.checkout-index-index .opc-block-summary .product-item .product-item-name-block {
  vertical-align: top;
}
.checkout-index-index .opc-block-summary .product-item .price {
  font-weight: 700;
}
.checkout-index-index .checkout-container {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .checkout-index-index .checkout-container {
    margin-top: 0;
  }
}
.checkout-index-index .authentication-wrapper {
  margin-top: 0;
}
@media screen and (max-width: 767px) {
  .checkout-index-index .authentication-wrapper {
    margin-top: 6px;
  }
}
@media screen and (max-width: 767px) {
  .checkout-index-index .opc-estimated-wrapper .minicart-wrapper {
    padding: 6px 10px 0 20px;
  }
}
.checkout-index-index .file-uploader input[type="file"] {
  width: 100%;
}
.checkout-index-index .field-tooltip .label > span {
  display: none;
}
.checkout-index-index .field-tooltip .field-tooltip-action {
  margin-top: 5px;
}
.checkout-index-index .field-tooltip .field-tooltip-content:before,
.checkout-index-index .field-tooltip .field-tooltip-content:after {
  top: 17px;
}
@media screen and (max-width: 767px) {
  .checkout-index-index .field-tooltip .field-tooltip-content {
    right: -10px;
    top: 40px;
    left: auto;
  }
  .checkout-index-index .field-tooltip .field-tooltip-content:before,
  .checkout-index-index .field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    margin-top: -21px;
    right: 10px;
    left: auto;
    top: 0;
  }
  .checkout-index-index .field-tooltip .field-tooltip-content:before {
    border-bottom-color: #666;
  }
  .checkout-index-index .field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px;
  }
}
@media screen and (max-width: 480px) {
  .checkout-index-index .payment-methods .buckaroo_magento2_ideal .buckaroo_magento2_image_title::before,
  .checkout-index-index .payment-methods .buckaroo_magento2_idealprocessing .buckaroo_magento2_image_title::before {
    background: url('../images/buckaroo/buckaroo_magento2_ideal_title.png') no-repeat;
    float: left;
    margin-right: 10px;
    margin-top: -1px;
    background-size: auto 25px;
    width: 40px;
    height: 30px;
    content: ' ';
  }
}
.checkout-index-index .payment-methods .buckaroo_magento2_ideal .buckaroo_magento2_image_title::after,
.checkout-index-index .payment-methods .buckaroo_magento2_idealprocessing .buckaroo_magento2_image_title::after {
  background: url('../images/buckaroo/buckaroo_magento2_ideal_title.png') no-repeat;
  float: right;
  margin-right: 10px;
  margin-top: -1px;
  background-size: auto 25px;
  width: 30px;
  height: 25px;
  content: ' ';
}
@media screen and (max-width: 480px) {
  .checkout-index-index .payment-methods .buckaroo_magento2_creditcard .buckaroo_magento2_image_title::before,
  .checkout-index-index .payment-methods .buckaroo_magento2_creditcards .buckaroo_magento2_image_title::before {
    background: url('../images/buckaroo/buckaroo_magento2_creditcard_title.png') no-repeat;
    margin-top: 0;
    float: left;
    margin-right: 10px;
    background-size: auto 20px;
    width: 40px;
    height: 30px;
    content: ' ';
  }
}
.checkout-index-index .payment-methods .buckaroo_magento2_creditcard .buckaroo_magento2_image_title::after,
.checkout-index-index .payment-methods .buckaroo_magento2_creditcards .buckaroo_magento2_image_title::after {
  background: url('../images/buckaroo/buckaroo_magento2_creditcard_title.png') no-repeat;
  margin-top: -1px;
  float: right;
  margin-right: 10px;
  background-size: auto 22px;
  width: 35px;
  height: 25px;
  content: ' ';
}
@media screen and (max-width: 480px) {
  .checkout-index-index .payment-methods .buckaroo_magento2_paypal .buckaroo_magento2_image_title::before {
    background: url('../images/buckaroo/buckaroo_magento2_paypal_title.png') no-repeat;
    float: left;
    margin-right: 10px;
    margin-top: -4px;
    background-size: auto 30px;
    width: 40px;
    height: 30px;
    content: ' ';
  }
}
.checkout-index-index .payment-methods .buckaroo_magento2_paypal .buckaroo_magento2_image_title::after {
  background: url('../images/buckaroo/buckaroo_magento2_paypal_title.png') no-repeat;
  float: right;
  margin-right: 10px;
  margin-top: -4px;
  background-size: auto 30px;
  width: 30px;
  height: 30px;
  content: ' ';
}
@media screen and (max-width: 480px) {
  .checkout-index-index .payment-methods .buckaroo_magento2_mrcash .buckaroo_magento2_image_title::before {
    background: url('../images/buckaroo/buckaroo_magento2_mrcash_title.svg') no-repeat;
    float: left;
    margin-right: 10px;
    margin-top: -4px;
    background-size: auto 30px;
    width: 40px;
    height: 30px;
    content: ' ';
  }
}
.checkout-index-index .payment-methods .buckaroo_magento2_mrcash .buckaroo_magento2_image_title::after {
  background: url('../images/buckaroo/buckaroo_magento2_mrcash_title.svg') no-repeat;
  float: right;
  margin-right: 10px;
  margin-top: -4px;
  background-size: auto 30px;
  width: 40px;
  height: 30px;
  content: ' ';
}
.checkout-index-index .payment-methods label.buckaroo_magento2_image_title.label {
  position: relative;
  padding-left: 45px;
}
.checkout-index-index .payment-methods .buckaroo_magento2_image_title:after,
.checkout-index-index .payment-methods .buckaroo_magento2_image_title:before {
  position: absolute;
  left: 0;
  top: 0;
  background-size: contain;
  background-position: center top;
  width: 38px;
}
.checkout-index-index .field.street .field.additional {
  margin-top: 0;
  padding-left: 10px;
  width: 100px;
}
.checkout-index-index .field.street > .control {
  display: flex;
}
.checkout-index-index .field.street > .control [name*=".street.0"] {
  flex: 10 5 auto;
}
.checkout-index-index .field.street > .control [name*=".street.1"] {
  flex: 0 10 auto;
}
.checkout-index-index .field.street > .control [name*=".street.2"] {
  flex: 0 10 auto;
}
.checkout-index-index .fieldset.address .input-text[value=""] {
  border-color: #9B9B9B;
}
.checkout-index-index .fieldset.address .input-text[aria-invalid="false"]:not([value=""]) {
  background-image: url('../svg/src/check-icon-green.svg');
  background-position: center right 10px;
  background-repeat: no-repeat;
  border-color: #47BB0E;
}
.checkout-index-index .fieldset.address .input-text[aria-invalid="true"] {
  background-image: url('../svg/src/delete-icon-red.svg');
  background-position: center right 10px;
  background-repeat: no-repeat;
  border-color: #d0021b;
}
@media screen and (min-width: 768px) {
  .checkout-index-index .form-discount {
    max-width: none;
    display: flex;
    justify-content: space-between;
  }
  .checkout-index-index .form-discount .payment-option-inner {
    width: calc(100% - 230px);
  }
  .checkout-index-index .form-discount .actions-toolbar {
    width: 220px;
  }
  .checkout-index-index .form-discount .actions-toolbar > .primary,
  .checkout-index-index .form-discount .actions-toolbar > .primary .action {
    width: 100%;
  }
}
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:visited,
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:hover,
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:visited,
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:focus,
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:hover,
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:visited,
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:hover,
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:visited,
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:focus,
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:hover,
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  position: relative;
  padding-right: 65px;
  padding-left: 25px;
  text-align: left;
}
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:visited,
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:hover,
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:visited,
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:focus,
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:hover,
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:visited,
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:hover,
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:visited,
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:focus,
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:hover,
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.checkout-index-index .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:before {
  content: "";
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 24.057450628366247%;
  width: 34px;
  height: 22px;
  display: block;
  position: absolute;
  right: 20px;
  top: 9px;
}
.checkout-index-index .checkout-agreements-block .action-show {
  line-height: 1;
  color: #0D314D;
}
.checkout-index-index .checkout-agreements-block .action-show:focus {
  color: #0D314D;
}
.checkout-index-index .checkout-agreements-block .action-show:hover {
  text-decoration: underline;
}
.checkout-index-index .experius_postcode_fieldset strong {
  display: block;
  margin: 15px 0;
}
.form.form-shipping-address #experius-postcode-fieldset {
  margin-left: 0;
}
.form.form-shipping-address #experius-postcode-fieldset .field[name*='experius_postcode_postcode'],
.form.form-shipping-address #experius-postcode-fieldset .field[name*='experius_postcode_housenumber'],
.form.form-shipping-address #experius-postcode-fieldset .field[name*='experius_postcode_housenumber_addition'] {
  width: auto;
}
.form.form-shipping-address #experius-postcode-fieldset .field {
  float: none;
  padding-right: 0;
}
/**
Microdata (how they call it)
*/
.checkout-index-index {
  /**
    * Store credit.
    */
}
.checkout-index-index .form-shipping-address .field.street .field .label {
  display: none;
}
.checkout-index-index .checkout-shipping-method #label_carrier_bestway_tablerate {
  position: relative;
}
@media screen and (max-width: 767px) {
  .checkout-index-index .checkout-shipping-method #label_carrier_bestway_tablerate {
    padding-bottom: 50px;
  }
}
.checkout-index-index .checkout-shipping-method #label_carrier_bestway_tablerate:after {
  display: block;
  content: attr(data-track-trace);
}
.checkout-index-index .checkout-shipping-method #label_carrier_bestway_tablerate:before {
  display: block;
  content: attr(data-most-chosen);
  color: #3ea30c;
  position: absolute;
}
@media screen and (max-width: 767px) {
  .checkout-index-index .checkout-shipping-method #label_carrier_bestway_tablerate:before {
    left: 10px;
    bottom: 28px;
    height: 22px;
  }
}
@media screen and (min-width: 768px) {
  .checkout-index-index .checkout-shipping-method #label_carrier_bestway_tablerate:before {
    right: -250px;
    top: 30px;
  }
}
.checkout-index-index .payment-methods .buckaroo_magento2_ideal {
  position: relative;
}
.checkout-index-index .payment-methods .buckaroo_magento2_ideal .checkout-payment-label-microdata {
  color: #3ea30c;
  float: right;
  margin-bottom: 10px;
}
.checkout-index-index .payment-methods .actions-toolbar > .primary .checkout-payment-text {
  text-align: right;
  float: right;
  display: block;
  margin: 10px 0;
}
.checkout-index-index .checkout-payment-method .form-purchase-order {
  margin-top: 1em;
}
.product-label {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: none;
  border-bottom-left-radius: 30px;
}
.product-label .product-label-attribute,
.product-label .product-label-discount {
  display: block;
  min-width: 140px;
  height: 35px;
  text-align: center;
  border-bottom-left-radius: 30px;
  color: white;
  padding: 7px 10px;
  font-weight: 700;
}
.sidebar__title {
  font-size: 2.2rem;
  color: #0D314D;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  margin-bottom: 10px;
  margin-top: 14px;
}
.sidebar__title--filter {
  display: none;
}
@media screen and (min-width: 768px) {
  .sidebar__title--filter {
    display: block;
  }
}
.sidebar-additional {
  margin-bottom: 40px;
}
.banner-widget {
  max-width: 1920px;
  margin: 0 auto;
}
@media screen and (max-width: 1023px) {
  .banner-widget__image-wrapper {
    min-height: 200px;
  }
}
.page-section--top .page-section__inner {
  max-width: none;
  padding: 0;
}
.page-section--top .page-section__inner .banner-widget__inner {
  align-items: flex-end;
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
  min-height: 430px;
  padding: 0 20px;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .page-section--top .page-section__inner .banner-widget__inner {
    align-items: center;
    min-height: 200px;
  }
}
.page-section--top .page-section__inner .banner-widget p {
  font-family: Ubuntu, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
}
@media screen and (max-width: 767px) {
  .page-section--top .page-section__inner .banner-widget p {
    font-size: 3.4rem;
  }
}
.page-section--top .page-section__inner .banner-widget strong {
  font-weight: 700;
  color: #009EE6;
}
.page-section--top .page-section__inner .banner-widget span {
  display: inline;
  background: #FFFFFF;
  padding: 10px 14px;
}
.banner-widget__buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .banner-widget__buttons {
    margin-bottom: 24px;
  }
}
a.banner-widget__button {
  transition: all 0.3s 0s;
  background: rgba(255, 255, 255, 0.7);
  color: #0D314D;
  display: block;
  font-size: 1.8rem;
  margin: 0 12px 10px;
  min-width: calc(25% - 24px);
  padding: 8px 20px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  a.banner-widget__button {
    margin: 0 12px 24px;
  }
}
a.banner-widget__button::after {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 71.52899824253076%;
  width: 12px;
  height: 10px;
  transform: none;
  transition: transform 0.3s 0s;
  content: '';
  display: inline-block;
  margin: 0 0 0 10px;
  position: relative;
}
a.banner-widget__button:hover {
  background: #ffffff;
  text-decoration: none;
  transform: translateY(-3px) scale(1.05);
}
a.banner-widget__button:hover::after {
  transform: translateX(5px);
}
.cms-index-index .sections.nav-sections {
  display: none;
}
.order-status-widget .fieldset {
  margin: 0 0 20px;
}
.order-status-widget .fieldset .field {
  margin: 0 0 5px;
}
.order-status-widget .form.form-orders-search {
  min-width: 0;
  width: auto;
}
.order-status-widget__title {
  font-weight: 700;
  font-size: 2.2rem;
  font-family: 'Ubuntu', sans-serif;
  color: #0D314D;
  margin-bottom: 10px;
  display: block;
}
.order-status-widget__button,
button.order-status-widget__button {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.order-status-widget__button:visited,
button.order-status-widget__button:visited,
.order-status-widget__button:focus,
button.order-status-widget__button:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.order-status-widget__button:hover,
button.order-status-widget__button:hover,
.order-status-widget__button:active,
button.order-status-widget__button:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.order-status-widget__button:visited,
button.order-status-widget__button:visited,
.order-status-widget__button:focus,
button.order-status-widget__button:focus,
.order-status-widget__button:hover,
button.order-status-widget__button:hover,
.order-status-widget__button:active,
button.order-status-widget__button:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.order-status-widget__button:visited,
button.order-status-widget__button:visited,
.order-status-widget__button:focus,
button.order-status-widget__button:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.order-status-widget__button:hover,
button.order-status-widget__button:hover,
.order-status-widget__button:active,
button.order-status-widget__button:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.order-status-widget__button:visited,
button.order-status-widget__button:visited,
.order-status-widget__button:focus,
button.order-status-widget__button:focus,
.order-status-widget__button:hover,
button.order-status-widget__button:hover,
.order-status-widget__button:active,
button.order-status-widget__button:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
@media screen and (max-width: 479px) {
  .order-status-widget__button,
  button.order-status-widget__button {
    width: 100%;
  }
}
.order-actions-toolbar .actions a {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.order-actions-toolbar .actions a:visited,
.order-actions-toolbar .actions a:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.order-actions-toolbar .actions a:hover,
.order-actions-toolbar .actions a:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.order-actions-toolbar .actions a:visited,
.order-actions-toolbar .actions a:focus,
.order-actions-toolbar .actions a:hover,
.order-actions-toolbar .actions a:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.order-actions-toolbar .actions a:visited,
.order-actions-toolbar .actions a:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.order-actions-toolbar .actions a:hover,
.order-actions-toolbar .actions a:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.order-actions-toolbar .actions a:visited,
.order-actions-toolbar .actions a:focus,
.order-actions-toolbar .actions a:hover,
.order-actions-toolbar .actions a:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.order-status {
  color: white;
  background: #009EE6;
  border-color: #009EE6;
  border-radius: 30px;
}
.cms-index-index .page-section--top .page-section__inner {
  position: relative;
}
.cms-index-index .page-section--top .order-status-widget {
  display: block;
  float: right;
  width: 100%;
  margin-top: 40px;
}
@media screen and (min-width: 1024px) {
  .cms-index-index .page-section--top .order-status-widget {
    position: absolute;
    right: 0;
    width: 420px;
    background: white;
    padding: 30px 40px 0;
    bottom: 0;
    border-top-left-radius: 60px;
  }
  .cms-index-index .page-section--top .order-status-widget button {
    width: 100%;
  }
}
@media screen and (min-width: 640px) and (max-width: 767px) {
  .cms-index-index .page-section--top .order-status-widget {
    position: absolute;
    right: 0;
    width: 420px;
    background: white;
    padding: 30px 40px 0;
    bottom: 0;
    border-top-left-radius: 60px;
  }
  .cms-index-index .page-section--top .order-status-widget button {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .cms-index-index .page-section--top.page-section--focus .order-status-widget {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: 20px;
    background: #fff;
    padding: 20px 30px;
    width: calc(100% - 370px);
  }
}
@media screen and (max-width: 639px) {
  .cms-index-index .page-section--top.page-section--focus .order-status-widget {
    margin-top: 0;
  }
}
.minicart-items-wrapper .product-item .product-item-inner {
  align-items: flex-start;
  display: flex;
}
a.pagebuilder-button-primary,
button.pagebuilder-button-primary,
div.pagebuilder-button-primary {
  padding: 10px 16px;
  background-color: #47BB0E;
  border: 1px solid #47BB0E;
  border-radius: 20px;
  cursor: pointer !important;
  font-weight: 700;
}
a.pagebuilder-button-primary:focus,
button.pagebuilder-button-primary:focus,
div.pagebuilder-button-primary:focus {
  background: #47BB0E;
  border: 1px solid #47BB0E;
  color: #ffffff;
}
a.pagebuilder-button-primary:active,
button.pagebuilder-button-primary:active,
div.pagebuilder-button-primary:active {
  background: #47BB0E;
  border: 1px solid #47BB0E;
  color: #ffffff;
}
a.pagebuilder-button-primary:visited,
button.pagebuilder-button-primary:visited,
div.pagebuilder-button-primary:visited {
  background: #47BB0E;
  border: 1px solid #47BB0E;
  color: #ffffff;
}
a.pagebuilder-button-primary:hover,
button.pagebuilder-button-primary:hover,
div.pagebuilder-button-primary:hover {
  background: #3ea30c;
  border: 1px solid #3ea30c;
  color: #ffffff;
}
[data-content-type='text'] h2 {
  font-size: 2rem;
  color: #0D314D;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
[data-content-type='text'] h3 {
  font-size: 1.4rem;
  color: #0D314D;
}
[data-content-type='text'] h4 {
  font-size: 1.4rem;
  font-style: italic;
  color: #0D314D;
}
h2[data-content-type='heading'] {
  font-size: 2rem;
  color: #0D314D;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h3[data-content-type='heading'] {
  font-size: 1.4rem;
  color: #0D314D;
}
h4[data-content-type='heading'] {
  font-size: 1.4rem;
  font-style: italic;
  color: #0D314D;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-name {
  margin: 15px 0 10px;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-name a {
  font-size: 2rem;
  font-family: 'Ubuntu', sans-serif;
  color: #000;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-name a:hover {
  text-decoration: none;
  color: #009EE6;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions {
  display: block;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary {
  display: block;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  position: relative;
  padding-right: 65px;
  padding-left: 25px;
  text-align: left;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  max-width: 320px;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:visited,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:hover,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:visited,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:focus,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:hover,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:visited,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:hover,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:visited,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:focus,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:hover,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:before {
  content: "";
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 24.057450628366247%;
  width: 34px;
  height: 22px;
  display: block;
  position: absolute;
  right: 20px;
  top: 9px;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .tocart:before {
  display: none;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart) {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  width: 100%;
  max-width: 320px;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):visited,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):hover,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):visited,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):focus,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):hover,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):visited,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):hover,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):visited,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):focus,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):hover,
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-primary .action.primary:not(.tocart):active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-details .product-item-actions .actions-secondary {
  display: none;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-item-photo {
  display: block;
}
.pagebuilder-column [data-content-type='products'] .product-item .product-reviews-summary {
  display: none;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box {
  margin: 10px 0 30px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container {
  display: flex;
  flex-direction: column-reverse;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container .price {
  color: #009EE6;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container .old-price-excl-vat {
  line-height: normal;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container .old-price-excl-vat .price {
  color: #009EE6;
  font-size: 2rem;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container .price-excluding-tax,
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container .price-including-tax {
  margin: 2px 0;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container .price-excluding-tax .price {
  font-size: 2rem;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container .price-excluding-tax:before {
  display: none;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.2rem;
  text-transform: lowercase;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container .price-including-tax .price {
  font-size: 1.5rem;
  color: #666;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container .price-including-tax:before {
  display: none;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-container .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.1rem;
  text-transform: lowercase;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container {
  display: flex;
  flex-direction: column-reverse;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price {
  color: #009EE6;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .old-price-excl-vat {
  line-height: normal;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .old-price-excl-vat .price {
  color: #009EE6;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-excluding-tax,
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-including-tax {
  margin: 2px 0;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-excluding-tax:before {
  display: none;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #939494;
  text-transform: lowercase;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-including-tax .price {
  color: #666;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-including-tax:before {
  display: none;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #939494;
  text-transform: lowercase;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-label {
  display: none;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price {
  text-decoration: line-through;
  font-weight: normal;
  color: #666;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-wrapper:after {
  text-decoration: line-through;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .special-price {
  margin-left: 2px;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-excluding-tax:after,
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-including-tax:after,
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-excluding-tax:before,
.pagebuilder-column [data-content-type='products'] .product-item .price-box .old-price .price-container .price-including-tax:before {
  display: none;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-to {
  display: none;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-from .price-label {
  order: 1;
  color: #009EE6;
  font-weight: 700;
}
.pagebuilder-column [data-content-type='products'] .product-item .price-box .price-from .price-label:after {
  content: ':';
}
[data-content-type='row'][data-appearance='contained'] {
  padding: 0 20px;
}
.brand-slider .slick-track {
  display: flex;
  align-items: center;
}
.brand-slider ul {
  position: relative;
  padding: 0;
  margin: 30px 0 0;
  display: none;
  list-style-type: none;
}
.brand-slider ul.slick-initialized {
  display: block;
}
.brand-slider .brand-slider-nav {
  position: absolute;
  top: 50%;
  margin-top: -14px;
}
.brand-slider .brand-slider-prev {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 31.941923774954628%;
  width: 17px;
  height: 28px;
  left: 0;
}
.brand-slider .brand-slider-prev:hover {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 37.023593466424686%;
  width: 17px;
  height: 28px;
}
.brand-slider .brand-slider-next {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 42.10526315789474%;
  width: 17px;
  height: 28px;
  right: 0;
}
.brand-slider .brand-slider-next:hover {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 47.186932849364794%;
  width: 17px;
  height: 28px;
}
.brand-slider .slick-list {
  overflow: hidden;
  text-align: center;
  margin: 0 40px;
}
.brand-slider .slick-slide {
  list-style-type: none;
  display: inline-block;
  padding: 0 25px;
  box-sizing: border-box;
}
.brand-slider .slick-slide img {
  vertical-align: middle;
  max-width: 120px;
}
.mp-tooltipped:after {
  background: none;
}
.swatch-attribute {
  margin: 0 -10px;
}
.swatch-attribute .swatch-option.image:not(.disabled):hover,
.swatch-attribute .swatch-option.color:not(.disabled):hover,
.swatch-attribute .swatch-option.selected:hover,
.swatch-attribute .swatch-option.selected {
  outline: 2px solid #009EE6;
}
.swatch-attribute .swatch-option.text {
  height: auto;
}
.social-button .requisition-list-button {
  text-transform: lowercase;
  font-weight: normal;
  letter-spacing: normal;
  color: #0D314D;
  font-size: 1.6rem;
  font-family: 'Barlow', sans-serif;
  display: block;
  line-height: normal;
}
.social-button .requisition-list-button:hover,
.social-button .requisition-list-button:focus {
  color: #009EE6;
}
.social-button .requisition-list-button:before {
  margin-right: 5px;
}
.requisition-list-action .action.toggle {
  margin-bottom: 5px;
  line-height: normal;
}
.requisition-list-action .action.toggle.active:after,
.requisition-list-action .action.toggle:after {
  display: none;
}
.requisition-grid .col-checkbox {
  display: block;
}
.requisition-grid .requisition-list-title {
  position: relative;
}
.requisition-grid .actions-toolbar {
  position: relative;
}
.requisition-list-action .action.toggle {
  margin-bottom: 10px;
}
.block-requisition-management .requisition-list-title h1 {
  font-weight: 700;
  margin-right: 20px;
}
.block-requisition-management .requisition-list-title .action.edit {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  margin-top: -15px;
}
.block-requisition-management .requisition-list-title .action.edit:visited,
.block-requisition-management .requisition-list-title .action.edit:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.block-requisition-management .requisition-list-title .action.edit:hover,
.block-requisition-management .requisition-list-title .action.edit:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.block-requisition-management .requisition-list-title .action.edit:visited,
.block-requisition-management .requisition-list-title .action.edit:focus,
.block-requisition-management .requisition-list-title .action.edit:hover,
.block-requisition-management .requisition-list-title .action.edit:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.block-requisition-management .requisition-list-title .action.edit:visited,
.block-requisition-management .requisition-list-title .action.edit:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.block-requisition-management .requisition-list-title .action.edit:hover,
.block-requisition-management .requisition-list-title .action.edit:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.block-requisition-management .requisition-list-title .action.edit:visited,
.block-requisition-management .requisition-list-title .action.edit:focus,
.block-requisition-management .requisition-list-title .action.edit:hover,
.block-requisition-management .requisition-list-title .action.edit:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.requisition-view-links .print {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.requisition-view-links .print:visited,
.requisition-view-links .print:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.requisition-view-links .print:hover,
.requisition-view-links .print:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.requisition-view-links .print:visited,
.requisition-view-links .print:focus,
.requisition-view-links .print:hover,
.requisition-view-links .print:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.requisition-view-links .print:visited,
.requisition-view-links .print:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.requisition-view-links .print:hover,
.requisition-view-links .print:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.requisition-view-links .print:visited,
.requisition-view-links .print:focus,
.requisition-view-links .print:hover,
.requisition-view-links .print:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.requisition-toolbar-actions span {
  font-size: 1.4rem;
  font-weight: 100;
}
.requisition-toolbar-actions > .action.remove-selected,
.requisition-toolbar-actions .requisition-list-action > .action {
  color: #009EE6;
}
.requisition-toolbar-actions > .action.remove-selected:hover,
.requisition-toolbar-actions .requisition-list-action > .action:hover {
  text-decoration: underline;
}
.requisition-grid .actions-toolbar > .action-edit:hover:before,
.requisition-grid .actions-toolbar > .action-edit:before {
  color: white;
}
.requisition-grid .actions-toolbar > .action-delete {
  margin-left: 5px;
}
.requisition-grid .actions-toolbar > .action-delete:hover:before,
.requisition-grid .actions-toolbar > .action-delete:before {
  color: white;
}
.requisition-grid .item .col .product-item-subtotal {
  height: auto;
}
.requisition-toolbar .requisition-list-action .action.toggle {
  margin-bottom: -3px;
  font-weight: 100;
}
@media screen and (max-width: 767px) {
  .account .page-main .column.main .requisition-content .actions-toolbar {
    margin-top: 20px;
  }
}
@media screen and (max-width: 639px) {
  .account .page-main .column.main .requisition-content .actions-toolbar {
    margin-top: -30px;
  }
}
.account .page-main .column.main .requisition-content .actions-toolbar button.action {
  padding: 10px 12px;
}
.account .page-main .column.main .requisition-content a.print {
  text-decoration: none;
}
.page-layout-1column .block.widget .products-grid .product-item:nth-child(3n + 1) {
  margin-left: 0;
}
.products-grid .ias-no-more {
  display: block;
  padding: 0 15px;
}
.products-grid .product-items:not(.widget-product-grid) {
  margin: 0 -10px;
}
@media screen and (min-width: 1024px) {
  .products-grid .product-items:not(.widget-product-grid) {
    margin: 0 -15px;
  }
}
.products-grid .product-items:not(.widget-product-grid) .product-item {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
  padding: 0 10px;
}
@media screen and (min-width: 1024px) {
  .products-grid .product-items:not(.widget-product-grid) .product-item {
    width: 33.33333333%;
    padding: 0 15px;
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 899px) {
  .products-grid .product-items:not(.widget-product-grid) .product-item {
    width: 50%;
  }
}
@media screen and (max-width: 479px) {
  .products-grid .product-items:not(.widget-product-grid) .product-item {
    width: 100%;
  }
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-info:not(:hover):not(.active) .product-item-inner,
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-inner {
  position: static;
  display: block;
  height: auto;
  width: auto;
  overflow: unset;
  box-shadow: none;
  background: none;
  clip: auto;
  left: auto;
  right: auto;
  z-index: auto;
  margin: 0;
  padding: 0;
  border: 0;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-info {
  border: 1px solid #B9C4CD;
  border-radius: 5px;
  width: 100%;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-info:hover,
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-info.active {
  margin: 0;
  padding: 0;
  box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.3);
  position: static;
}
@media screen and (min-width: 768px) {
  .products-grid .product-items:not(.widget-product-grid) .product-item .product-item-info:hover,
  .products-grid .product-items:not(.widget-product-grid) .product-item .product-item-info.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .products-grid .product-items:not(.widget-product-grid) .product-item .product-item-info:hover .product-item-actions .actions-secondary,
  .products-grid .product-items:not(.widget-product-grid) .product-item .product-item-info.active .product-item-actions .actions-secondary {
    display: block;
  }
  .products-grid .product-items:not(.widget-product-grid) .product-item .product-item-info:hover .product-image-wrapper,
  .products-grid .product-items:not(.widget-product-grid) .product-item .product-item-info.active .product-image-wrapper {
    z-index: 1;
  }
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-image-wrapper {
  margin: 0 20px;
  z-index: 1;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-photo {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-name {
  margin: 10px 20px;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .price-box {
  margin: 10px 20px;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-details {
  position: relative;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-details {
  text-align: center;
  position: absolute;
  margin-top: 70px;
  width: 100%;
  z-index: 10;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions {
  margin: 0;
  border-top: 1px solid #B9C4CD;
  z-index: 5;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-primary {
  width: 100%;
  margin: 20px auto;
  padding: 0 20px 20px;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-secondary {
  display: none;
  width: 100%;
  border-top: 1px solid #B9C4CD;
  padding-left: 20px;
  padding: 10px 20px;
  text-align: center;
  background: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 5;
  width: calc(100% + 2px);
  position: absolute;
  border: 1px solid #B9C4CD;
  margin-left: -1px;
  margin-right: -1px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-secondary .action.towishlist,
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-secondary .wishlist.split.button {
  display: none;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-secondary .action.tocompare {
  display: block;
  width: 100%;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-secondary .action.tocompare span {
  overflow: auto;
  height: auto;
  position: static;
  width: auto;
  text-transform: lowercase;
  font-weight: normal;
  font-size: 1.4rem;
  letter-spacing: normal;
  color: #0D314D;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-secondary .action.tocompare:before {
  margin-right: 5px;
  content: '';
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-secondary .action.tocompare:hover span {
  color: #009EE6;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-secondary .action.tocompare:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 51.15452930728242%;
  width: 21px;
  height: 16px;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-secondary .action.tocompare:hover:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 53.99644760213144%;
  width: 21px;
  height: 16px;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-secondary .requisition-list-button.toggle {
  margin-top: 5px;
}
.products-grid .product-items:not(.widget-product-grid) .product-item .product-item-actions .actions-secondary .requisition-list-button.toggle span {
  font-size: 1.4rem;
}
.products-grid .ias-load-more,
.products-grid .ias-load-prev {
  display: block !important;
  margin: 30px auto 15px;
}
.products-grid .ias-load-more:hover,
.products-grid .ias-load-prev:hover {
  display: block;
}
.products-grid .ias-spinner {
  margin: 30px 0;
  text-align: center;
}
.products-grid .ias-spinner img {
  display: inline-block;
  max-width: 50px;
}
.products-grid .ias-spinner span {
  margin-top: 20px;
  display: block;
}
.quickorder-index-index .ui-front {
  z-index: 1;
}
.quickorder-index-index > .loading-mask {
  display: none !important;
}
.quickorder-index-index .product-length-attributes {
  display: flex;
  flex-direction: column;
}
.quickorder-index-index .box-upload {
  margin-bottom: 30px;
}
.quickorder-index-index .fieldset .fields .actions-toolbar {
  top: 18px;
}
@media screen and (min-width: 640px) {
  .quickorder-index-index .fieldset .fields .actions-toolbar {
    position: absolute;
    right: 0;
    top: 9px;
  }
}
.quickorder-index-index .fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.quickorder-index-index .fields .length,
.quickorder-index-index .fields .sku {
  padding-right: 10px;
}
.quickorder-index-index .fields .qty {
  width: 100px;
  display: inline-block;
}
.quickorder-index-index .fields .length {
  width: 240px;
}
.quickorder-index-index .action.remove:hover {
  color: #0D314D;
}
.quickorder-index-index .upload-container .action-upload {
  cursor: pointer;
}
.quickorder-index-index .product-block:not(:empty) {
  border-radius: 20px;
  margin-top: 4px;
  overflow: hidden;
  border: none;
}
.column .sku {
  margin-right: 0;
  padding-right: 0;
}
.column .fieldset > .field .fields.deletable-item,
.column .fieldset .fields.deletable-item {
  margin-bottom: 15px;
  z-index: 1;
}
.column .fieldset > .field .fields.deletable-item input:disabled,
.column .fieldset .fields.deletable-item input:disabled {
  background-color: #f5f5f5;
}
@media screen and (max-width: 639px) {
  .column .fieldset > .field .fields.deletable-item,
  .column .fieldset .fields.deletable-item {
    display: flex;
    flex-direction: column;
  }
}
.column .qty .qty {
  padding: 0 10px;
}
.quote-status {
  color: white;
  background: #009EE6;
  border-color: #009EE6;
  border-radius: 30px;
}
.quote-actions-toolbar #deleteLink {
  display: none;
}
@media screen and (min-width: 768px) {
  .contact-page {
    display: flex;
    justify-content: space-between;
  }
  .contact-page__form,
  .contact-page__content {
    flex: 1 1 50%;
  }
  .contact-page__form {
    margin-right: 20px;
  }
}
.contact-page__form {
  margin-bottom: 40px;
}
.quote-actions-toolbar a {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.quote-actions-toolbar a:visited,
.quote-actions-toolbar a:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.quote-actions-toolbar a:hover,
.quote-actions-toolbar a:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.quote-actions-toolbar a:visited,
.quote-actions-toolbar a:focus,
.quote-actions-toolbar a:hover,
.quote-actions-toolbar a:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.quote-actions-toolbar a:visited,
.quote-actions-toolbar a:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.quote-actions-toolbar a:hover,
.quote-actions-toolbar a:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.quote-actions-toolbar a:visited,
.quote-actions-toolbar a:focus,
.quote-actions-toolbar a:hover,
.quote-actions-toolbar a:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.quote-grid-filters-wrap > button.action {
  font-size: 1.4rem;
  line-height: 1;
  text-decoration: underline;
  color: #009EE6;
  font-family: 'Barlow', sans-serif;
  font-weight: 100;
}
.quote-grid-filters-wrap > button.action:hover {
  text-decoration: none;
  color: #009EE6;
}
.tooltip-bottom {
  position: relative;
}
.tooltip-bottom .tooltip-content {
  background: #FFFFFF;
  max-width: 360px;
  min-width: 210px;
  padding: 12px 16px;
  z-index: 100;
  display: none;
  position: absolute;
  text-align: left;
  color: #0D314D;
  line-height: 1.4;
  border: 1px solid #bbb;
  margin-top: 5px;
  left: 0;
  top: 100%;
}
.tooltip-bottom .tooltip-content:after,
.tooltip-bottom .tooltip-content:before {
  border: solid transparent;
  content: '';
  height: 0;
  position: absolute;
  width: 0;
}
.tooltip-bottom .tooltip-content:after {
  border-width: 5px;
  border-color: transparent;
}
.tooltip-bottom .tooltip-content:before {
  border-width: 6px;
  border-color: transparent;
}
.tooltip-bottom .tooltip-content:after,
.tooltip-bottom .tooltip-content:before {
  bottom: 100%;
}
.tooltip-bottom .tooltip-content:after {
  border-bottom-color: #FFFFFF;
  left: 15px;
  margin-left: -5px;
}
.tooltip-bottom .tooltip-content:before {
  border-bottom-color: #bbb;
  left: 15px;
  margin-left: -6px;
}
.tooltip-bottom .tooltip-toggle {
  cursor: help;
}
.tooltip-bottom .tooltip-toggle:hover + .tooltip-content,
.tooltip-bottom .tooltip-toggle:focus + .tooltip-content,
.tooltip-bottom:hover .tooltip-content {
  display: block;
}
.tooltip-bottom .tooltip-content {
  margin-right: 10px;
  min-width: 200px;
  max-width: 500px;
  width: 50vw;
}
.tooltip-bottom .tooltip-content p {
  font-weight: 300;
}
.tooltip-info {
  margin-right: 5px;
}
.tooltip-info:hover {
  text-decoration: none;
}
.cms-index-index .page-section__inner {
  max-width: none;
  padding: 0;
}
.cms-index-index .widget__inner {
  max-width: 1310px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}
.cms-index-index .widget__inner:not(.row) {
  max-width: 1280px;
}
.widget.block-products-list .block-title strong,
.widget.block-viewed-products-grid .block-title strong {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 3.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.widget.block-products-list .block-title strong span:not(.base),
.widget.block-viewed-products-grid .block-title strong span:not(.base) {
  color: #009EE6;
}
.widget.block-products-list .products-grid,
.widget.block-viewed-products-grid .products-grid {
  margin-top: 30px;
}
.widget.block-products-list .product-items.widget-product-grid,
.widget.block-viewed-products-grid .product-items.widget-product-grid {
  display: block;
  margin: 0 -10px;
}
.widget.block-products-list .product-items.widget-product-grid:before,
.widget.block-viewed-products-grid .product-items.widget-product-grid:before,
.widget.block-products-list .product-items.widget-product-grid:after,
.widget.block-viewed-products-grid .product-items.widget-product-grid:after {
  content: '';
  display: table;
}
.widget.block-products-list .product-items.widget-product-grid:after,
.widget.block-viewed-products-grid .product-items.widget-product-grid:after {
  clear: both;
}
.widget.block-products-list .product-items.widget-product-grid .product-item,
.widget.block-viewed-products-grid .product-items.widget-product-grid .product-item {
  position: relative;
  width: 25%;
  margin: 0 0 20px;
  padding: 0 10px;
  display: block;
  float: left;
}
@media screen and (max-width: 1023px) {
  .widget.block-products-list .product-items.widget-product-grid .product-item,
  .widget.block-viewed-products-grid .product-items.widget-product-grid .product-item {
    width: 50%;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 479px) {
  .widget.block-products-list .product-items.widget-product-grid .product-item,
  .widget.block-viewed-products-grid .product-items.widget-product-grid .product-item {
    width: 100%;
  }
}
.widget.block-products-list .product-items.widget-product-grid .product-item .product-item-info:hover,
.widget.block-viewed-products-grid .product-items.widget-product-grid .product-item .product-item-info:hover {
  background: none;
  box-shadow: none;
  border: none;
  margin: 0px;
  padding: 0px;
}
.widget.block-products-list .product-items.widget-product-grid .product-item .product-image-container,
.widget.block-viewed-products-grid .product-items.widget-product-grid .product-item .product-image-container {
  position: relative;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.widget.block-products-list .product-items.widget-product-grid .product-item .product-image-container:hover,
.widget.block-viewed-products-grid .product-items.widget-product-grid .product-item .product-image-container:hover {
  box-shadow: 3px 3px 10px -4px rgba(0, 0, 0, 0.75);
}
.widget.block-products-list .product-items.widget-product-grid .product-item .product-image-wrapper,
.widget.block-viewed-products-grid .product-items.widget-product-grid .product-item .product-image-wrapper {
  background: white;
}
.widget.block-products-list .product-items.widget-product-grid .product-item .product-image-photo,
.widget.block-viewed-products-grid .product-items.widget-product-grid .product-item .product-image-photo {
  position: absolute;
}
.widget.block-viewed-products-grid .product-item .price-box {
  height: 50px;
}
.cms-index-index .widget.block-products-list .product-items.widget-product-grid .product-item:first-child .product-image-container:before,
.cms-index-index .widget.block-products-list .product-items.widget-product-grid .product-item:last-child .product-image-container:before {
  content: "";
  display: block;
  height: 45px;
  width: 30px;
  position: absolute;
  z-index: -1;
}
.cms-index-index .widget.block-products-list .product-items.widget-product-grid .product-item:first-child .product-image-container:before {
  background: #0D314D;
  bottom: -15px;
  left: -15px;
  border-bottom-right-radius: 10px;
}
@media screen and (max-width: 1023px) {
  .cms-index-index .widget.block-products-list .product-items.widget-product-grid .product-item:first-child .product-image-container:before {
    display: none;
  }
}
.cms-index-index .widget.block-products-list .product-items.widget-product-grid .product-item:last-child .product-image-container:before {
  background: #009EE6;
  top: -15px;
  right: -15px;
  border-top-right-radius: 15px;
}
@media screen and (max-width: 1023px) {
  .cms-index-index .widget.block-products-list .product-items.widget-product-grid .product-item:last-child .product-image-container:before {
    display: none;
  }
}
.product-block-widget {
  margin: 20px 0 30px;
}
.product-block-widget__tile {
  border: solid 1px #B9C4CD;
}
.product-block-widget .widget.block {
  margin-bottom: 0;
}
.product-block-widget .product-block-widget__link:hover {
  text-decoration: none;
}
.product-block-widget .product-block-widget__link .product-block-widget__text {
  color: #333333;
}
.product-block-widget .product-block-widget__title {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 2.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: block;
  text-align: center;
  word-wrap: break-word;
  font-size: 1.5rem;
}
.product-block-widget .product-block-widget__title span:not(.base) {
  color: #009EE6;
}
.product-block-widget .product-block-widget__image {
  margin: 0;
  width: 100%;
  display: block;
}
.product-block-widget .product-block-widget__text {
  margin-bottom: 20px;
  border-top: 0;
  padding: 32px 23px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
}
.product-block-widget .product-block-widget__text p:last-of-type {
  margin-bottom: 2rem;
}
.product-block-widget .product-block-widget__text ul {
  padding: 0;
}
.product-block-widget .product-block-widget__text ul li {
  list-style-type: none;
  margin-bottom: 0.5rem;
}
.product-block-widget .product-block-widget__text ul li a {
  font-size: 2rem;
  color: #000;
}
.product-block-widget .product-block-widget__text ul li a:hover {
  color: #009EE6;
}
.product-block-widget .action.primary {
  background: transparent;
  color: #0D314D;
  border: solid 2px #009EE6;
  font-size: 1.6rem;
}
.product-block-widget .action.primary:after {
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 69.77152899824253%;
  width: 12px;
  height: 10px;
  display: inline-block;
  position: relative;
  transform: none;
  margin: 0 0 0 10px;
  transition: transform 0.3s 0s;
}
.product-block-widget .action.primary:visited {
  background: transparent;
  color: #0D314D;
  border: solid 2px #009EE6;
}
.product-block-widget .action.primary:hover,
.product-block-widget .action.primary:active {
  background: #0D314D;
  color: #FFFFFF;
  border: solid 2px #0D314D;
}
.product-block-widget .action.primary:hover:after,
.product-block-widget .action.primary:active:after {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 71.52899824253076%;
}
.product-block-widget .product-block-widget__view-all-link {
  margin-top: auto;
}
.product-block-widget--contact .product-block-widget .product-block-widget__text strong {
  font-size: 2rem;
  font-weight: 700;
  color: #0D314D;
}
.product-block-widget--contact .product-block-widget .product-block-widget__text a {
  color: #009EE6;
}
.content-widget .content-widget__row {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
}
@media screen and (min-width: 768px) {
  .content-widget .content-widget__row {
    flex-direction: row;
  }
  .content-widget .content-widget__row > * {
    flex: 1 1 100%;
  }
}
.content-widget .content-widget__image {
  width: 100%;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 767px) {
  .content-widget .content-widget__image {
    min-height: 25vh;
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 768px) {
  .content-widget .content-widget__image {
    margin-right: 90px;
  }
}
.content-widget .subtitle {
  text-transform: uppercase;
}
.content-widget .subtitle + h2 {
  margin-top: 0;
}
.content-widget p + a.primary {
  margin-top: 16px;
}
.icons-widget {
  background: #F2F3F4;
}
.icons-widget .icons-widget__row {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
}
@media screen and (min-width: 768px) {
  .icons-widget .icons-widget__row {
    flex-direction: row;
  }
  .icons-widget .icons-widget__row > * {
    flex: 1 1 100%;
  }
}
.icons-widget .icons-widget__icons {
  display: flex;
  flex-direction: row;
}
@media screen and (min-width: 768px) {
  .icons-widget .icons-widget__icons {
    margin-left: 90px;
  }
}
@media screen and (max-width: 767px) {
  .icons-widget .icons-widget__icons {
    margin-top: 4rem;
  }
}
.icons-widget .icons-widget__icons > * {
  flex: 1 1 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .icons-widget .icons-widget__icons > * {
    margin-top: auto;
    margin-bottom: auto;
  }
}
.icons-widget .icons-widget__icon a {
  display: block;
  color: #000;
}
.icons-widget .icons-widget__icon a:hover {
  text-decoration: none;
  color: #009EE6;
}
.icons-widget .icons-widget__icon a:hover .icon-widget__title:after {
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 71.52899824253076%;
  width: 12px;
  height: 10px;
  display: inline-block;
  position: relative;
  transform: none;
  margin: 0 0 0 10px;
  transition: transform 0.3s 0s;
}
.icons-widget .icon-widget__title {
  font-size: 1.8rem;
}
@media screen and (max-width: 767px) {
  .icons-widget .icon-widget__title {
    font-size: 1.6rem;
  }
}
.icons-widget .icons-widget__round {
  border-radius: 50%;
  background: #FFFFFF;
  width: 100px;
  height: 100px;
  margin: 0 auto 12px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .icons-widget .icons-widget__round {
    width: 75px;
    height: 75px;
  }
}
.icons-widget .icons-widget__round img {
  display: block;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.icons-widget .subtitle {
  text-transform: uppercase;
}
.icons-widget .subtitle + h2 {
  margin-top: 0;
}
.icons-widget p + a.primary {
  margin-top: 16px;
}
.fixed-button__container {
  position: fixed;
  z-index: 9999;
  -webkit-transform: translateZ(0) !important;
  bottom: 0;
  right: 0;
  width: 0;
  height: 100%;
}
.fixed-button__button {
  display: inline-block;
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  transform: rotate(180deg) translateY(-50%);
  writing-mode: vertical-lr;
  position: fixed;
  bottom: 50%;
  right: -2px;
  color: #FFFFFF;
  width: 40px;
  padding: 12px 14px 12px 12px;
  background: #009EE6;
  cursor: pointer;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.fixed-button__button span {
  overflow-wrap: normal;
  word-break: normal;
  word-wrap: normal;
  white-space: nowrap;
  cursor: pointer;
}
.fixed-button__button:hover {
  text-decoration: none;
  right: 0;
}
.fixed-button__button:visited,
.fixed-button__button:active,
.fixed-button__button:focus,
.fixed-button__button:hover {
  color: #FFFFFF;
}
.fixed-button__button svg {
  transform: rotate(180deg);
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
}
.page-header {
  position: relative;
}
.page-header.page-header--fixed {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 60;
}
.page-header a {
  color: #000;
}
.page-header a:hover,
.page-header a:active {
  color: #000;
  text-decoration: underline;
}
.page-header p {
  margin-bottom: 0;
}
.page-header ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.page-header ul li {
  display: inline-block;
  margin: 0;
}
.checkout-index-index .page-header .page-header__usps {
  width: 100%;
  position: relative;
}
.checkout-index-index .page-header .page-header__usps ul {
  justify-content: center;
}
.checkout-index-index .page-header .page-header__slogan {
  font-size: 2.4rem;
}
.page-header .page-header__top.panel.wrapper {
  background-color: #F2F3F4;
  color: #000;
  border-bottom: none;
}
@media screen and (max-width: 767px) {
  .page-header .page-header__top.panel.wrapper .page-header__quality-mark,
  .page-header .page-header__top.panel.wrapper .page-header__rating,
  .page-header .page-header__top.panel.wrapper .page-header__compare,
  .page-header .page-header__top.panel.wrapper .page-header__top-links {
    display: none;
  }
  .page-header .page-header__top.panel.wrapper .page-header__language {
    position: absolute;
    top: 80px;
    z-index: 40;
    right: 60px;
  }
  .page-header .page-header__top.panel.wrapper .switcher {
    display: block;
  }
}
.page-header .page-header__top-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-header .page-header__quality-mark {
  line-height: 1;
}
.page-header .page-header__rating {
  margin-top: -4px;
}
.page-header .page-header__links {
  display: flex;
}
.page-header .page-header__language {
  margin-left: 20px;
}
.page-header .page-header__language .switcher-label {
  display: none;
}
@media screen and (max-width: 1023px) {
  .page-header .page-header__logo {
    max-width: 200px;
  }
}
@media screen and (max-width: 767px) {
  .page-header .page-header__logo {
    max-width: 150px;
  }
}
@media screen and (max-width: 479px) {
  .page-header .page-header__logo {
    max-width: 130px;
  }
}
.page-header .page-header__logo .logo {
  max-width: 100%;
  margin: 0;
}
@media screen and (max-width: 1023px) {
  .page-header .page-header__logo .logo {
    max-width: 200px;
  }
}
.page-header .page-header__content {
  background: white;
}
@media screen and (min-width: 768px) {
  .page-header .page-header__content.header.content {
    padding: 0 20px;
  }
}
@media screen and (max-width: 767px) {
  .page-header .page-header__content {
    border-bottom: 1px solid #ccc;
  }
}
@media screen and (max-width: 767px) {
  .checkout-index-index .page-header .page-header__content {
    padding-top: 3px;
  }
}
.page-header .page-header__content-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 127px;
}
@media screen and (max-width: 767px) {
  .page-header .page-header__content-inner {
    height: 56px;
  }
}
@media screen and (min-width: 768px) {
  .page-header .page-header__search {
    margin-top: 10px;
    margin-left: 35px;
  }
}
@media screen and (min-width: 1024px) {
  .page-header .page-header__search {
    margin-top: 15px;
  }
}
.page-header .page-header__slogan {
  text-align: center;
  color: #0D314D;
  font-size: 1.7rem;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
}
@media screen and (max-width: 1023px) {
  .page-header .page-header__slogan {
    display: none;
  }
}
.page-header .page-header__actions {
  display: flex;
  font-family: 'Ubuntu', sans-serif;
  margin-top: 10px;
  min-width: 280px;
  justify-content: flex-end;
}
.page-header .page-header__actions > div[class^="page-header__"] {
  margin: 0 10px;
}
@media screen and (max-width: 767px) {
  .page-header .page-header__actions > div[class^="page-header__"] {
    margin: 0;
  }
}
.page-header .page-header__actions > div[class^="page-header__"]:first-child {
  margin-left: 0;
}
.page-header .page-header__actions > div[class^="page-header__"]:last-child {
  margin-left: 0;
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .page-header .page-header__actions {
    font-size: 1.2rem;
    margin: 0;
    min-width: unset;
  }
}
.page-header .page-header__mobile-search {
  display: none;
}
@media screen and (max-width: 767px) {
  .page-header .page-header__mobile-search {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .page-header .page-header__account,
  .page-header .page-header__mobile-search {
    min-width: 40px;
    text-align: center;
  }
  .page-header .page-header__account span,
  .page-header .page-header__mobile-search span {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .page-header .page-header__quick-order {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .page-header .page-header__middle {
    border-bottom: solid 1px #B9C4CD;
  }
}
.page-header .page-header__middle .page-header__bottom-inner {
  display: flex;
  align-items: center;
  height: 100%;
}
.page-header .page-header__bottom-inner {
  *zoom: 1;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  height: 100%;
}
.page-header .page-header__bottom-inner:before,
.page-header .page-header__bottom-inner:after {
  content: " ";
  display: table;
}
.page-header .page-header__bottom-inner:after {
  clear: both;
}
.page-header .page-header__bottom-inner:before,
.page-header .page-header__bottom-inner:after {
  content: '';
  display: table;
}
.page-header .page-header__bottom-inner:after {
  clear: both;
}
@media screen and (max-width: 767px) {
  .page-header .page-header__bottom-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 768px) {
  .page-header .page-header__bottom-inner {
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  .page-header .page-header__bottom-absolute {
    border-bottom: 1px solid #e8e8e8;
    height: 50px;
  }
}
.page-header .page-header__rating {
  display: flex;
  align-items: center;
  padding-right: 10px;
  height: 100%;
  background: white;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .page-header .page-header__rating {
    display: none;
  }
}
.page-header .page-header__usps {
  position: absolute;
  right: 20px;
  font-size: 1.2em;
  top: 0;
  width: calc(100% - 50px);
}
.page-header .page-header__usps ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  flex-wrap: wrap;
  overflow: hidden;
}
.page-header .page-header__usps li {
  position: relative;
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
  margin-left: 40px;
  font-size: 0.9em;
}
.page-header .page-header__usps li:first-child {
  margin-left: 0;
}
.page-header .page-header__usps li:before {
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 27.41652021089631%;
  width: 13px;
  height: 10px;
  display: block;
  position: absolute;
  top: 22px;
  left: 0;
}
@media screen and (max-width: 767px) {
  .page-header .page-header__usps {
    display: none;
  }
}
.page-header .page-header__menu {
  height: 50px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .page-header {
    border-bottom: 0;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .page-header__quick-order {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .page-products .page-header__quick-order,
  .product-page .page-header__quick-order,
  .quickorder-index-index .page-header__quick-order,
  .checkout-cart-index .page-header__quick-order {
    display: block;
  }
}
.average-rating__details {
  margin-right: 40px;
  white-space: nowrap;
  position: relative;
}
.average-rating__details:after {
  content: attr(data-rating-value);
  font-size: 1.6rem;
  font-weight: 700;
  top: -3px;
  position: absolute;
}
@media screen and (max-width: 1023px) {
  .average-rating__details {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .average-rating__text {
    display: none;
  }
}
.average-rating__based-on-amount {
  font-weight: 700;
}
.average-rating__stars-wrapper {
  display: inline-block;
  height: 14px;
  width: 74px;
  margin-right: 8px;
  background: transparent url('../images/stars-small-empty.png') no-repeat;
}
@media screen and (min-width: 0\0), screen\0 {
  .average-rating__stars-wrapper {
    /* IE 8, 9 and 10 CSS styles go here */
    background: transparent url('../images/stars-small-empty.png') no-repeat !important;
  }
}
.average-rating__stars {
  display: block;
  height: 14px;
  background: transparent url('../images/stars-small-full.png') no-repeat;
}
@media screen and (min-width: 0\0), screen\0 {
  .average-rating__stars {
    /* IE 8, 9 and 10 CSS styles go here */
    background: transparent url('../images/stars-small-full.png') no-repeat !important;
  }
}
/* IE Edge 12+ fix */
@supports (-ms-ime-align:auto) {
  .average-rating__stars-wrapper {
    background: transparent url('../images/stars-small-empty.png') no-repeat !important;
  }
  .average-rating__stars {
    background: transparent url('../images/stars-small-full.png') no-repeat !important;
  }
}
.widget-rating__amount {
  font-size: 4rem;
  font-weight: 800;
}
.text-widget__rating-text {
  float: left;
  margin: 0 15px 0 30px;
}
.text-widget__rating-stars {
  padding: 12px 0 0;
}
.text-widget__rating-stars .average-rating__score {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 6px 0 0;
}
.text-widget__rating-stars .average-rating__text {
  font-size: 1.4rem;
}
.block-search {
  width: 100%;
  padding-left: 0;
  margin-bottom: 10px;
  float: none;
}
.block-search input {
  border: 1px solid #B9C4CD;
  border-radius: 30px;
  padding-right: 100px;
  font-size: 1.6rem;
  font-weight: 300;
  font-family: 'Barlow', sans-serif;
}
.block-search input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #939494 !important;
}
.block-search input::-moz-placeholder {
  /* Firefox 19+ */
  color: #939494 !important;
}
.block-search input:-ms-input-placeholder {
  /* IE 10+ */
  color: #939494 !important;
}
.block-search input:-moz-placeholder {
  /* Firefox 18- */
  color: #939494 !important;
}
.block-search input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
.block-search .action.search {
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 20px;
  border: 2px solid #0D314D;
  background: #0D314D;
  color: white;
  opacity: 1 !important;
  padding: 0 20px;
  cursor: pointer;
  font-size: 1.8rem;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}
.block-search .action.search > span {
  position: relative;
}
.block-search .action.search:before {
  display: none;
}
.block-search .action.search:after {
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 92.47311827956989%;
  width: 21px;
  height: 21px;
  display: block;
  position: absolute;
  top: 7px;
  right: 14px;
}
.block-search .action.search:hover,
.block-search .action.search:active,
.block-search .action.search:focus {
  background: #092337;
  border: 2px solid #092337;
}
@media screen and (min-width: 1024px) {
  .block-search .action.search:after {
    display: none;
  }
  .block-search .control:after {
    content: '';
    background: url("../svg/dist/css/css-sprite.svg") no-repeat;
    background-position: 0 88.58695652173913%;
    width: 26px;
    height: 27px;
    display: block;
    position: absolute;
    top: 7px;
    right: 98px;
  }
}
.block-search .control {
  border-top: 0;
}
.block-search .label {
  display: none;
}
@media screen and (min-width: 1024px) {
  .block-search {
    min-width: 390px;
  }
}
@media screen and (max-width: 1023px) {
  .block-search .action.search {
    padding: 0 25px;
  }
  .block-search .action.search > span {
    display: none;
  }
  .block-search input {
    padding-right: 60px;
  }
}
#minisearch-autocomplete-top-search {
  border: 1px solid #b9c4cd;
  background-color: white;
  padding: .5em;
}
.copyright {
  display: none !important;
}
.minicart-wrapper {
  display: inline-block;
  position: relative;
}
.minicart-wrapper:before,
.minicart-wrapper:after {
  content: '';
  display: table;
}
.minicart-wrapper:after {
  clear: both;
}
.minicart-wrapper false {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}
.minicart-wrapper false:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
  line-height: 22px;
  color: inherit;
  content: '\e607';
  font-family: 'icons-blank-theme';
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}
.minicart-wrapper false:hover:after {
  color: inherit;
}
.minicart-wrapper false:active:after {
  color: inherit;
}
.minicart-wrapper false.active {
  display: inline-block;
  text-decoration: none;
}
.minicart-wrapper false.active:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
  line-height: 22px;
  color: inherit;
  content: '\e618';
  font-family: 'icons-blank-theme';
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}
.minicart-wrapper false.active:hover:after {
  color: inherit;
}
.minicart-wrapper false.active:active:after {
  color: inherit;
}
.minicart-wrapper .block-minicart {
  margin: 0;
  padding: 0;
  list-style: none none;
  background: #FFFFFF;
  border: 1px solid #bbb;
  margin-top: 4px;
  min-width: 100%;
  z-index: 100;
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}
.minicart-wrapper .block-minicart li {
  margin: 0;
  padding: 3px 5px;
}
.minicart-wrapper .block-minicart li:hover {
  background: #e8e8e8;
  cursor: pointer;
}
.minicart-wrapper .block-minicart:before,
.minicart-wrapper .block-minicart:after {
  border-bottom-style: solid;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.minicart-wrapper .block-minicart:before {
  border: 6px solid;
  border-color: transparent transparent #FFFFFF transparent;
  z-index: 99;
}
.minicart-wrapper .block-minicart:after {
  border: 7px solid;
  border-color: transparent transparent #bbb transparent;
  z-index: 98;
}
.minicart-wrapper .block-minicart:before {
  left: 10px;
  top: -12px;
}
.minicart-wrapper .block-minicart:after {
  left: 9px;
  top: -14px;
}
.minicart-wrapper.active {
  overflow: visible;
}
.minicart-wrapper.active .block-minicart {
  display: block;
}
.minicart-wrapper false:after,
.minicart-wrapper false.active:after {
  display: inline-block;
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 12.062937062937063%;
  width: 10px;
  height: 7px;
  margin-left: 6px;
  vertical-align: middle;
}
.minicart-wrapper false.active:after {
  transform: rotate(180deg);
}
.minicart-wrapper .block-minicart {
  min-width: 340px;
  right: 0;
  top: 60px;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  padding: 0;
}
.minicart-wrapper .block-minicart:before {
  border: 12px solid;
  border-color: transparent transparent #0D314D transparent;
  top: -24px;
  left: auto;
  right: 10px;
}
.minicart-wrapper .block-minicart:after {
  display: none;
}
.minicart-wrapper .block-minicart .block-title {
  background: #0D314D;
  color: white;
  padding: 17px 20px;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.minicart-wrapper .block-minicart .block-content {
  color: #000;
  padding: 0;
}
.minicart-wrapper .block-minicart .block-title {
  display: block;
}
.minicart-wrapper .block-minicart .block-title .qty {
  display: none;
}
.minicart-wrapper .block-minicart .block-content {
  padding: 17px 20px;
}
.minicart-wrapper .block-minicart .block-content .action.close {
  z-index: 10;
  position: absolute;
  top: 11px;
  right: 1px;
  box-shadow: none;
  background: transparent;
  border: 0;
}
.minicart-wrapper .block-minicart .block-content .action.close .ui-button-text {
  display: none;
}
.minicart-wrapper .block-minicart .block-content .action.close:after {
  display: block;
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 57.24508050089445%;
  width: 20px;
  height: 20px;
}
.minicart-wrapper .block-minicart .block-content .action.close:hover {
  box-shadow: none;
  background: transparent;
  border: 0;
}
.minicart-wrapper .block-minicart .block-content .action.close:hover:after {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 60.82289803220036%;
  width: 20px;
  height: 20px;
}
.minicart-wrapper .block-minicart .block-content .action.close:before {
  display: none;
}
.minicart-wrapper .block-minicart .block-content > .actions > .primary {
  margin: 0 0 15px;
}
.minicart-wrapper .block-minicart .block-content .items-total {
  display: none;
}
.minicart-wrapper .block-minicart .block-content .subtotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}
.minicart-wrapper .block-minicart .block-content .subtotal .label:after {
  display: none;
}
.minicart-wrapper .block-minicart .block-content .action.primary.checkout {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:visited,
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:hover,
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:visited,
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:focus,
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:hover,
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:visited,
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:hover,
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:visited,
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:focus,
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:hover,
.minicart-wrapper .block-minicart .block-content .action.primary.checkout:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.minicart-wrapper .block-minicart .subtitle.empty {
  padding: 17px 20px 0;
}
.minicart-wrapper .action.showcart:before,
.minicart-wrapper .action.showcart.active:before {
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 19.529837251356238%;
  width: 40px;
  height: 26px;
  transform: scale(0.9);
}
@media screen and (min-width: 768px) {
  .minicart-wrapper .action.showcart:before,
  .minicart-wrapper .action.showcart.active:before {
    transform: scale(0.7);
  }
}
.minicart-wrapper .action.showcart .counter-number,
.minicart-wrapper .action.showcart.active .counter-number {
  text-shadow: none;
}
@media screen and (max-width: 767px) {
  .minicart-wrapper .action.showcart .counter-number,
  .minicart-wrapper .action.showcart.active .counter-number {
    color: transparent;
  }
}
.minicart-wrapper .action.showcart .counter-number:after,
.minicart-wrapper .action.showcart.active .counter-number:after {
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 12.062937062937063%;
  width: 10px;
  height: 7px;
  display: inline-block;
  margin-left: 6px;
}
.minicart-wrapper .action.showcart .counter.qty,
.minicart-wrapper .action.showcart.active .counter.qty,
.minicart-wrapper .action.showcart .counter.qty.empty,
.minicart-wrapper .action.showcart.active .counter.qty.empty {
  color: #000;
  background: transparent;
  min-width: 0;
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
}
@media screen and (max-width: 767px) {
  .minicart-wrapper .action.showcart .counter.qty,
  .minicart-wrapper .action.showcart.active .counter.qty,
  .minicart-wrapper .action.showcart .counter.qty.empty,
  .minicart-wrapper .action.showcart.active .counter.qty.empty {
    margin-left: -20px;
    margin-right: 5px;
  }
}
.minicart-wrapper .action.showcart .counter.qty.empty,
.minicart-wrapper .action.showcart.active .counter.qty.empty {
  color: transparent;
}
.minicart-wrapper .action.showcart .counter.qty.empty .counter-number:after,
.minicart-wrapper .action.showcart.active .counter.qty.empty .counter-number:after {
  margin-left: 0;
}
.minicart-wrapper .action.showcart.active .counter-number:after {
  transform: rotate(180deg);
}
.minicart-wrapper .mini-checkout-extra-text {
  text-align: center;
  margin: 0 0 10px;
  font-size: 1.3rem;
}
@media screen and (max-width: 767px) {
  .minicart-wrapper {
    margin-top: 0;
    float: none;
    position: static;
  }
  .minicart-wrapper .block-minicart {
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
    min-width: 0;
    text-align: left;
  }
  .minicart-wrapper .block-minicart:before {
    right: 55px;
  }
}
.minicart-wrapper {
  margin: 0;
}
.minicart-wrapper .block-minicart .block-content {
  padding: 0;
  font-family: 'Barlow', sans-serif;
}
.minicart-wrapper .block-minicart .block-content .subtotal {
  padding: 15px 20px 0;
}
.minicart-wrapper .block-minicart .block-content .subtotal .label {
  font-size: 1.6rem;
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
}
.minicart-wrapper .block-minicart .block-content .subtotal .price-container {
  display: flex;
  flex-direction: column-reverse;
}
.minicart-wrapper .block-minicart .block-content .subtotal .price-container .price {
  color: #009EE6;
}
.minicart-wrapper .block-minicart .block-content .subtotal .price-container .old-price-excl-vat {
  line-height: normal;
}
.minicart-wrapper .block-minicart .block-content .subtotal .price-container .old-price-excl-vat .price {
  color: #009EE6;
  font-size: 2.4rem;
}
.minicart-wrapper .block-minicart .block-content .subtotal .price-container .price-excluding-tax,
.minicart-wrapper .block-minicart .block-content .subtotal .price-container .price-including-tax {
  margin: 2px 0;
}
.minicart-wrapper .block-minicart .block-content .subtotal .price-container .price-excluding-tax .price {
  font-size: 2.4rem;
}
.minicart-wrapper .block-minicart .block-content .subtotal .price-container .price-excluding-tax:before {
  display: none;
}
.minicart-wrapper .block-minicart .block-content .subtotal .price-container .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.2rem;
  text-transform: lowercase;
}
.minicart-wrapper .block-minicart .block-content .subtotal .price-container .price-including-tax .price {
  font-size: 1.6rem;
  color: #666;
}
.minicart-wrapper .block-minicart .block-content .subtotal .price-container .price-including-tax:before {
  display: none;
}
.minicart-wrapper .block-minicart .block-content .subtotal .price-container .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.1rem;
  text-transform: lowercase;
}
.minicart-wrapper .block-minicart .block-content .actions {
  padding: 0 20px;
}
.minicart-wrapper .block-minicart .block-content .actions .action.viewcart {
  font-size: 1.6rem;
  color: #009EE6;
}
.minicart-wrapper .block-minicart .minicart-items-wrapper {
  margin: 0;
  padding: 20px;
  border: 0;
  border-bottom: 1px solid #9B9B9B;
}
.minicart-wrapper .block-minicart li {
  padding: 15px 0;
}
.minicart-wrapper .block-minicart li:hover {
  background: inherit;
  cursor: inherit;
}
.minicart-items .subtotal {
  display: flex;
  flex-direction: column-reverse;
}
.minicart-items .subtotal .price {
  color: #009EE6;
}
.minicart-items .subtotal .old-price-excl-vat {
  line-height: normal;
}
.minicart-items .subtotal .old-price-excl-vat .price {
  color: #009EE6;
  font-size: 1.8rem;
}
.minicart-items .subtotal .price-excluding-tax,
.minicart-items .subtotal .price-including-tax {
  margin: 2px 0;
}
.minicart-items .subtotal .price-excluding-tax .price {
  font-size: 1.8rem;
}
.minicart-items .subtotal .price-excluding-tax:before {
  display: none;
}
.minicart-items .subtotal .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.1rem;
  text-transform: lowercase;
}
.minicart-items .subtotal .price-including-tax .price {
  font-size: 1.4rem;
  color: #666;
}
.minicart-items .subtotal .price-including-tax:before {
  display: none;
}
.minicart-items .subtotal .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1rem;
  text-transform: lowercase;
}
.minicart-items input.item-qty {
  color: #0D314D;
  padding: 0 20px;
  font-size: 1.6rem;
  font-family: 'Barlow', sans-serif;
  border: 1px solid #9B9B9B;
  border-radius: 5px;
  box-shadow: none;
  height: 42px;
  width: 40px;
  height: 34px;
  padding: 0;
  margin-right: 5px;
}
.minicart-items input.item-qty:focus {
  border: 1px solid #009EE6;
  box-shadow: none;
}
.minicart-items input.item-qty.readonly {
  opacity: 1;
  background: lightgrey;
  pointer-events: none;
}
.minicart-items input.item-qty.readonly:focus {
  border: 1px solid #9B9B9B;
  box-shadow: none;
}
.minicart-items .product-item-pricing {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.minicart-items .product-item-pricing .details-qty .label {
  display: none;
}
.minicart-items .product-item-pricing .details-qty:after {
  display: inline-block;
  content: 'x';
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.6rem;
}
.minicart-items .product-item-pricing .price-container {
  text-align: right;
}
.minicart-items .product-item-pricing .price-container .price-wrapper {
  display: flex;
  flex-direction: column-reverse;
}
.minicart-items .product-item-pricing .price-container .price-wrapper .price {
  color: #009EE6;
}
.minicart-items .product-item-pricing .price-container .price-wrapper .old-price-excl-vat {
  line-height: normal;
}
.minicart-items .product-item-pricing .price-container .price-wrapper .old-price-excl-vat .price {
  color: #009EE6;
  font-size: 1.8rem;
}
.minicart-items .product-item-pricing .price-container .price-wrapper .price-excluding-tax,
.minicart-items .product-item-pricing .price-container .price-wrapper .price-including-tax {
  margin: 2px 0;
}
.minicart-items .product-item-pricing .price-container .price-wrapper .price-excluding-tax .price {
  font-size: 1.8rem;
}
.minicart-items .product-item-pricing .price-container .price-wrapper .price-excluding-tax:before {
  display: none;
}
.minicart-items .product-item-pricing .price-container .price-wrapper .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.1rem;
  text-transform: lowercase;
}
.minicart-items .product-item-pricing .price-container .price-wrapper .price-including-tax .price {
  font-size: 1.4rem;
  color: #666;
}
.minicart-items .product-item-pricing .price-container .price-wrapper .price-including-tax:before {
  display: none;
}
.minicart-items .product-item-pricing .price-container .price-wrapper .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1rem;
  text-transform: lowercase;
}
@media screen and (max-width: 479px) {
  .minicart-items .product-item-pricing {
    display: block;
  }
  .minicart-items .product-item-pricing .price-container {
    margin-bottom: 10px;
  }
}
.minicart-items .product-item-details {
  position: relative;
}
.minicart-items .product-item-details .details-qty {
  margin-top: 0;
}
.minicart-items .product-item-details .product.actions {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.minicart-items .product-item-details .product.actions .primary {
  display: none;
}
.minicart-items .product-item-name {
  font-family: 'Ubuntu', sans-serif;
}
.minicart-items .product-item-name a,
.minicart-items .product-item-name a:hover {
  color: #0D314D;
}
.minicart-items .product-item .product-item-details .product-item-name {
  margin: 0 22px 10px 0;
}
.minicart-items .action.delete:before {
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 96.23655913978494%;
  width: 18px;
  height: 21px;
}
.minicart-items .action.delete:hover:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 100%;
  width: 18px;
  height: 21px;
}
.minicart-items .update-cart-item {
  position: absolute;
  margin-left: 15px;
}
@media screen and (min-width: 768px) {
  .compare.wrapper .action.compare {
    line-height: inherit;
  }
}
.account-dropdown {
  display: inline-block;
  position: relative;
}
.account-dropdown:before,
.account-dropdown:after {
  content: '';
  display: table;
}
.account-dropdown:after {
  clear: both;
}
.account-dropdown .dropdown__trigger {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}
.account-dropdown .dropdown__trigger:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
  line-height: 22px;
  color: inherit;
  content: '\e607';
  font-family: 'icons-blank-theme';
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}
.account-dropdown .dropdown__trigger:hover:after {
  color: inherit;
}
.account-dropdown .dropdown__trigger:active:after {
  color: inherit;
}
.account-dropdown .dropdown__trigger.active {
  display: inline-block;
  text-decoration: none;
}
.account-dropdown .dropdown__trigger.active:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
  line-height: 22px;
  color: inherit;
  content: '\e618';
  font-family: 'icons-blank-theme';
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}
.account-dropdown .dropdown__trigger.active:hover:after {
  color: inherit;
}
.account-dropdown .dropdown__trigger.active:active:after {
  color: inherit;
}
.account-dropdown .dropdown__content {
  margin: 0;
  padding: 0;
  list-style: none none;
  background: #FFFFFF;
  border: 1px solid #bbb;
  margin-top: 4px;
  min-width: 100%;
  z-index: 100;
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}
.account-dropdown .dropdown__content li {
  margin: 0;
  padding: 3px 5px;
}
.account-dropdown .dropdown__content li:hover {
  background: #e8e8e8;
  cursor: pointer;
}
.account-dropdown .dropdown__content:before,
.account-dropdown .dropdown__content:after {
  border-bottom-style: solid;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.account-dropdown .dropdown__content:before {
  border: 6px solid;
  border-color: transparent transparent #FFFFFF transparent;
  z-index: 99;
}
.account-dropdown .dropdown__content:after {
  border: 7px solid;
  border-color: transparent transparent #bbb transparent;
  z-index: 98;
}
.account-dropdown .dropdown__content:before {
  left: 10px;
  top: -12px;
}
.account-dropdown .dropdown__content:after {
  left: 9px;
  top: -14px;
}
.account-dropdown.active {
  overflow: visible;
}
.account-dropdown.active .dropdown__content {
  display: block;
}
.account-dropdown .dropdown__trigger:after,
.account-dropdown .dropdown__trigger.active:after {
  display: inline-block;
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 12.062937062937063%;
  width: 10px;
  height: 7px;
  margin-left: 6px;
  vertical-align: middle;
}
.account-dropdown .dropdown__trigger.active:after {
  transform: rotate(180deg);
}
.account-dropdown .dropdown__content {
  min-width: 340px;
  right: 0;
  top: 60px;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  padding: 0;
}
.account-dropdown .dropdown__content:before {
  border: 12px solid;
  border-color: transparent transparent #0D314D transparent;
  top: -24px;
  left: auto;
  right: 39px;
}
.account-dropdown .dropdown__content:after {
  display: none;
}
.account-dropdown .dropdown__content .dropdown__header {
  background: #0D314D;
  color: white;
  padding: 17px 20px;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.account-dropdown .dropdown__content .dropdown__body {
  color: #000;
  padding: 0;
}
.account-dropdown .account-dropdown__trigger:before {
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 0;
  width: 23px;
  height: 27px;
  vertical-align: middle;
  display: inline-block;
  margin: 0 auto 2px;
}
@media screen and (min-width: 768px) {
  .account-dropdown .account-dropdown__trigger:before {
    transform: scale(0.7);
  }
}
@media screen and (max-width: 1439px) {
  .account-dropdown .account-dropdown__trigger .text {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .account-dropdown .account-dropdown__trigger.active:after,
  .account-dropdown .account-dropdown__trigger:after {
    display: none;
  }
}
.account-dropdown.active .account-dropdown__trigger:after {
  transform: rotate(180deg);
}
.account-dropdown .account-dropdown__login {
  padding: 17px 20px;
}
.account-dropdown .account-dropdown__btn {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}
.account-dropdown .account-dropdown__btn:visited,
.account-dropdown .account-dropdown__btn:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.account-dropdown .account-dropdown__btn:hover,
.account-dropdown .account-dropdown__btn:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.account-dropdown .account-dropdown__btn:visited,
.account-dropdown .account-dropdown__btn:focus,
.account-dropdown .account-dropdown__btn:hover,
.account-dropdown .account-dropdown__btn:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.account-dropdown .account-dropdown__btn:visited,
.account-dropdown .account-dropdown__btn:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.account-dropdown .account-dropdown__btn:hover,
.account-dropdown .account-dropdown__btn:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.account-dropdown .account-dropdown__btn:visited,
.account-dropdown .account-dropdown__btn:focus,
.account-dropdown .account-dropdown__btn:hover,
.account-dropdown .account-dropdown__btn:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.account-dropdown .account-dropdown__remind {
  display: block;
  text-align: center;
  margin-top: 10px;
  font-size: 1.6rem;
  color: #009EE6;
  text-decoration: none;
}
.account-dropdown .account-dropdown__remind:visited {
  color: #009EE6;
  text-decoration: none;
}
.account-dropdown .account-dropdown__remind:hover {
  color: #009EE6;
  text-decoration: underline;
}
.account-dropdown .account-dropdown__remind:active {
  color: #009EE6;
  text-decoration: none;
}
.account-dropdown .account-dropdown__register {
  border-top: 1px solid #B9C4CD;
}
.account-dropdown a.account-dropdown__register-url {
  display: block;
  padding: 17px 20px;
  text-align: center;
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-size: 2rem;
  font-weight: 700;
}
.account-dropdown a.account-dropdown__register-url span {
  display: block;
  font-family: 'Barlow', sans-serif;
  color: #009EE6;
  font-weight: normal;
}
.account-dropdown a.account-dropdown__register-url:hover {
  text-decoration: none;
}
.account-dropdown a.account-dropdown__register-url:hover span {
  text-decoration: underline;
}
.account-dropdown .header.links li {
  display: block;
  padding: 0;
}
.account-dropdown .header.links a {
  display: block;
  padding: 8px 20px;
}
.account-dropdown.account-dropdown--logged-in .dropdown__content .dropdown__body {
  padding: 10px 0;
}
@media screen and (max-width: 767px) {
  .account-dropdown {
    position: static;
  }
  .account-dropdown .dropdown__content {
    left: 10px;
    right: 10px;
    min-width: 0;
    text-align: left;
  }
  .account-dropdown .dropdown__content:before {
    right: 92px;
    top: -23px;
  }
}
.quick-order-icon {
  display: block;
}
.quick-order-icon:before {
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 67.02898550724638%;
  width: 19px;
  height: 27px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 auto;
  transform: scale(0.8);
}
.quick-order-icon:hover {
  text-decoration: none;
}
@media screen and (max-width: 1439px) {
  .quick-order-icon .quick-order-icon__label {
    display: none;
  }
}
.mobile-search-icon {
  display: block;
}
.mobile-search-icon:before {
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 88.58695652173913%;
  width: 26px;
  height: 27px;
  display: block;
  margin: 0 auto 2px;
}
.mobile-search-icon:hover {
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .block-search {
    display: none;
    position: absolute;
    top: 67px;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 50;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    background: #B9C4CD;
  }
  .block-search .control input {
    position: static;
    margin: 0;
  }
  .block-search .action.search {
    position: absolute;
    display: block;
    box-shadow: none;
    height: 40px;
    padding: 0 30px;
    top: 10px;
    right: 10px;
  }
  .block-search .action.search:after {
    right: 18px;
    top: 8px;
  }
  .block-search.block-search--active {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .navigation {
    align-items: center;
    background-color: #FFFFFF;
    display: flex;
    font-size: 1.6rem;
    font-family: 'Barlow', sans-serif;
    padding: 0 20px;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 {
    margin: 0 15px 0 0;
    position: static;
  }
}
.navigation ul.navigation-wrapper li.level0:not(.ui-menu-item) {
  display: none;
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0.parent:hover > a.level-top span::after,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > a.level-top span::after {
    top: -2px;
    transform: rotate(180deg);
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu {
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.default-menu__submenu > li,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.default-menu__submenu > li,
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.full-width-menu__submenu > li,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.full-width-menu__submenu > li {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.default-menu__submenu > li .default-menu__container,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.default-menu__submenu > li .default-menu__container,
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.full-width-menu__submenu > li .default-menu__container,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.full-width-menu__submenu > li .default-menu__container,
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.default-menu__submenu > li .full-width-menu__container,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.default-menu__submenu > li .full-width-menu__container,
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.full-width-menu__submenu > li .full-width-menu__container,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.full-width-menu__submenu > li .full-width-menu__container {
    columns: 4;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.default-menu__submenu > li .default-menu__container > div:not(.default-menu__static):not(.full-width-menu__static),
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.default-menu__submenu > li .default-menu__container > div:not(.default-menu__static):not(.full-width-menu__static),
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.full-width-menu__submenu > li .default-menu__container > div:not(.default-menu__static):not(.full-width-menu__static),
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.full-width-menu__submenu > li .default-menu__container > div:not(.default-menu__static):not(.full-width-menu__static),
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.default-menu__submenu > li .full-width-menu__container > div:not(.default-menu__static):not(.full-width-menu__static),
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.default-menu__submenu > li .full-width-menu__container > div:not(.default-menu__static):not(.full-width-menu__static),
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.full-width-menu__submenu > li .full-width-menu__container > div:not(.default-menu__static):not(.full-width-menu__static),
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.full-width-menu__submenu > li .full-width-menu__container > div:not(.default-menu__static):not(.full-width-menu__static) {
    break-inside: avoid-column;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.default-menu__submenu > li .default-menu__container .default-menu__static,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.default-menu__submenu > li .default-menu__container .default-menu__static,
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.full-width-menu__submenu > li .default-menu__container .default-menu__static,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.full-width-menu__submenu > li .default-menu__container .default-menu__static,
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.default-menu__submenu > li .full-width-menu__container .default-menu__static,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.default-menu__submenu > li .full-width-menu__container .default-menu__static,
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.full-width-menu__submenu > li .full-width-menu__container .default-menu__static,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.full-width-menu__submenu > li .full-width-menu__container .default-menu__static,
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.default-menu__submenu > li .default-menu__container .full-width-menu__static,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.default-menu__submenu > li .default-menu__container .full-width-menu__static,
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.full-width-menu__submenu > li .default-menu__container .full-width-menu__static,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.full-width-menu__submenu > li .default-menu__container .full-width-menu__static,
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.default-menu__submenu > li .full-width-menu__container .full-width-menu__static,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.default-menu__submenu > li .full-width-menu__container .full-width-menu__static,
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu.full-width-menu__submenu > li .full-width-menu__container .full-width-menu__static,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu.full-width-menu__submenu > li .full-width-menu__container .full-width-menu__static {
    break-inside: avoid;
    width: 400%;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0.parent:hover > .submenu:not(.default-menu__submenu):not(.full-width-menu__submenu) > li,
  .navigation ul.navigation-wrapper li.level0.has-submenu:hover > .submenu:not(.default-menu__submenu):not(.full-width-menu__submenu) > li {
    flex: 0 0 25%;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0.parent > a.level-top,
  .navigation ul.navigation-wrapper li.level0.has-submenu > a.level-top {
    padding-right: 15px;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0.parent > a.level-top span,
  .navigation ul.navigation-wrapper li.level0.has-submenu > a.level-top span {
    display: inline-block;
    text-decoration: none;
  }
  .navigation ul.navigation-wrapper li.level0.parent > a.level-top span:after,
  .navigation ul.navigation-wrapper li.level0.has-submenu > a.level-top span:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    line-height: 20px;
    color: inherit;
    content: '\e622';
    font-family: 'luma-icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0.parent > a.level-top span::after,
  .navigation ul.navigation-wrapper li.level0.has-submenu > a.level-top span::after {
    font-weight: 700;
    position: relative;
    right: -5px;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0.active > a.level-top {
    border-bottom: none;
    color: #000000;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > a.level-top {
    color: #0D314D;
    font-weight: 400;
    line-height: 6.6rem;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > a.level-top:hover {
    color: #0D314D;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li.ui-menu-item {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h2 > a,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h3 > a,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h4 > a,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h5 > a {
    color: #0D314D;
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h2,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h3,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h4,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h5,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li.parent > a {
    border-bottom: 1px solid #B9C4CD;
    font-style: normal;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li.active > a,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li.level1.active > a {
    border-left: none;
    color: #000000;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h2,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h3,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h4,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li h5,
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li.level1 > a {
    color: #0D314D;
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0 20px 10px;
    padding: 10px 0;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li a {
    color: #666;
    transition: color 200ms ease-out;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li a:hover {
    background: none;
    color: #000000;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li ul {
    border: none;
    box-shadow: none;
    display: block;
    margin-bottom: 10px;
    position: static;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li ul > li {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li ul > li.parent > a {
    font-weight: 500;
    padding: 10px 20px;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li ul > li.active > a {
    border-left: none;
    color: #000000;
  }
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li ul > li:not(.parent) {
    padding: 5px 20px;
  }
}
.navigation ul.navigation-wrapper li.level0 > ul.submenu > li ul > li:not(.parent) > a {
  position: relative;
  padding-left: 20px;
}
@media screen and (min-width: 768px) {
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li ul > li:not(.parent) > a {
    display: inline-block;
    text-decoration: none;
  }
  .navigation ul.navigation-wrapper li.level0 > ul.submenu > li ul > li:not(.parent) > a:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    line-height: 20px;
    color: inherit;
    content: '\e608';
    font-family: 'luma-icons';
    margin: 0 5px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
}
.navigation ul.navigation-wrapper li.level0 > ul.submenu > li ul > li:not(.parent) > a::before {
  position: absolute;
  left: 0;
  top: 3px;
}
.navigation ul.navigation-wrapper li.level0 > ul.submenu > li ul > li:not(.parent) > * {
  padding: 0;
}
@media screen and (max-width: 767px) {
  .navigation {
    background-color: #FFFFFF;
    display: none;
    height: 100vh;
    max-height: calc(100% - 67px);
    overflow-y: auto;
    padding-top: 10px;
    position: fixed;
    width: 100%;
    z-index: 100;
  }
}
@media screen and (max-width: 767px) {
  .navigation--active {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul {
    left: 0;
    padding: 0 20px;
    position: relative;
    top: 0;
    transition: left 120ms ease-in-out;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul.subnavigation--active {
    left: -100%;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul.subnavigation--active > li.level0 {
    position: static;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul.subnavigation--active .ui-menu-item--active .submenu {
    border: 0;
    display: block !important;
    left: 100% !important;
    top: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0 {
    border: 0;
    display: list-item;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > a.level-top::after {
    content: '\e608';
    font-family: 'luma-icons';
    font-weight: 400;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu {
    display: none;
    overflow: visible;
    padding: 0 20px;
    position: absolute;
    transition: left 120ms ease-in-out;
    width: 100%;
    z-index: 10;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active {
    left: 20px !important;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .default-menu__container > div.active ul.active,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .default-menu__container > div.active ul.active,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .full-width-menu__container > div.active ul.active,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .full-width-menu__container > div.active ul.active,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .default-menu__container .pagebuilder-column.active ul.active,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .default-menu__container .pagebuilder-column.active ul.active,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .full-width-menu__container .pagebuilder-column.active ul.active,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .full-width-menu__container .pagebuilder-column.active ul.active {
    display: block;
    left: 100%;
    padding: 0 0 20px;
    position: absolute;
    top: 0;
    width: calc(100% - 40px);
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .default-menu__container > div.active ul.active li,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .default-menu__container > div.active ul.active li,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .full-width-menu__container > div.active ul.active li,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .full-width-menu__container > div.active ul.active li,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .default-menu__container .pagebuilder-column.active ul.active li,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .default-menu__container .pagebuilder-column.active ul.active li,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .full-width-menu__container .pagebuilder-column.active ul.active li,
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .full-width-menu__container .pagebuilder-column.active ul.active li {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .default-menu__container > div.active ul.active li:nth-child(2),
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .default-menu__container > div.active ul.active li:nth-child(2),
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .full-width-menu__container > div.active ul.active li:nth-child(2),
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .full-width-menu__container > div.active ul.active li:nth-child(2),
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .default-menu__container .pagebuilder-column.active ul.active li:nth-child(2),
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .default-menu__container .pagebuilder-column.active ul.active li:nth-child(2),
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .default-menu__submenu-wrapper .full-width-menu__container .pagebuilder-column.active ul.active li:nth-child(2),
  .navigation > ul > li.level0.has-submenu > ul.submenu.subnavigation--active .full-width-menu__submenu-wrapper .full-width-menu__container .pagebuilder-column.active ul.active li:nth-child(2) {
    padding-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu > li {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h2,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h2,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h2,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h2,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h3,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h3,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h3,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h3,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h4,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h4,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h4,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h4,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h5,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h5,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h5,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h5 {
    font-size: 1.6rem;
    font-style: normal;
    margin: 0;
    padding: 20px 0 0;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h2 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h2 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h2 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h2 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h3 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h3 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h3 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h3 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h4 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h4 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h4 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h4 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h5 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h5 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h5 > a,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h5 > a {
    color: #0D314D;
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h2 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h2 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h2 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h2 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h3 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h3 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h3 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h3 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h4 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h4 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h4 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h4 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h5 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h5 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h5 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h5 + ul {
    border-bottom: 1px solid #000000;
    padding: 20px 0;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h2 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h2 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h2 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h2 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h3 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h3 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h3 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h3 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h4 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h4 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h4 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h4 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h5 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h5 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h5 + ul li,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h5 + ul li {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h2 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h2 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h2 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h2 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h3 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h3 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h3 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h3 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h4 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h4 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h4 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h4 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h5 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h5 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h5 + ul li a:not(.go-back),
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h5 + ul li a:not(.go-back) {
    display: inline-block;
    text-decoration: none;
    display: block;
    font-weight: 600;
    padding: 10px 0;
  }
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h2 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h2 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h2 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h2 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h3 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h3 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h3 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h3 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h4 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h4 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h4 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h4 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h5 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h5 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h5 + ul li a:not(.go-back):before,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h5 + ul li a:not(.go-back):before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    line-height: 20px;
    color: inherit;
    content: '\e608';
    font-family: 'luma-icons';
    margin: 0 5px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h4,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h4,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h4,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h4 {
    border-bottom: 1px solid #000000;
    padding: 20px 40px 20px 0;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h4::after,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h4::after,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h4::after,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h4::after {
    content: '\e608';
    font-family: 'luma-icons';
    font-weight: 400;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .default-menu__container h4 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .default-menu__container h4 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .default-menu__submenu-wrapper .full-width-menu__container h4 + ul,
  .navigation > ul > li.level0.has-submenu > ul.submenu .full-width-menu__submenu-wrapper .full-width-menu__container h4 + ul {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0 > a.level-top {
    border-bottom: 1px solid #000000;
    padding: 20px 40px 20px 0;
    text-transform: none;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0 a.go-back {
    border-bottom: 1px solid #000000;
    color: #575757;
    font-weight: 700;
    padding: 20px 0;
  }
}
@media screen and (max-width: 767px) {
  .navigation > ul > li.level0 a.go-back::before {
    content: '\e608';
    display: inline-block;
    font-family: 'luma-icons';
    font-weight: 400;
    margin-right: 15px;
    transform: rotate(180deg);
    vertical-align: middle;
  }
}
.menu-trigger__link {
  align-items: center;
  display: flex;
  margin-left: 10px;
}
@media screen and (min-width: 768px) {
  .menu-trigger__link {
    display: none;
  }
}
.menu-trigger__link::before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 78.17531305903398%;
  width: 28px;
  height: 20px;
  content: '';
  display: inline-block;
}
.menu-trigger__link:hover::before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 74.59749552772809%;
  width: 28px;
  height: 20px;
}
.menu-trigger__link span {
  display: none;
}
.page-footer {
  background: #F2F3F4;
  font-size: 1.6rem;
}
.page-footer .page-footer__content {
  color: #0D314D;
}
.page-footer .page-footer__content:before,
.page-footer .page-footer__content:after {
  content: '';
  display: table;
}
.page-footer .page-footer__content:after {
  clear: both;
}
.page-footer .page-footer__content .footer.content {
  padding: 40px 0;
}
.page-footer .page-footer__content .footer.content:before,
.page-footer .page-footer__content .footer.content:after {
  content: '';
  display: table;
}
.page-footer .page-footer__content .footer.content:after {
  clear: both;
}
.page-footer .page-footer__content .footer__columns {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1023px) {
  .page-footer .page-footer__content .footer__columns {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 639px) {
  .page-footer .page-footer__content .footer__columns {
    flex-direction: column;
  }
}
.page-footer .page-footer__content .footer__col {
  flex: 1 1 100%;
}
.page-footer .page-footer__content .footer__col a {
  cursor: pointer;
}
.page-footer .page-footer__content .footer__col p {
  margin-bottom: 30px;
  line-height: 1.8;
}
@media screen and (max-width: 639px) {
  .page-footer .page-footer__content .footer__col p {
    margin-bottom: 15px;
  }
}
.page-footer .page-footer__content .footer__col h4 {
  font-size: 2.2rem;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  margin-top: 0;
  margin-bottom: 20px;
}
@media screen and (max-width: 639px) {
  .page-footer .page-footer__content .footer__col h4 {
    margin-bottom: 10px;
  }
}
.page-footer .page-footer__content .footer__col svg {
  margin-right: 10px;
  font-size: 1.8rem;
}
.page-footer .page-footer__content .footer__col ul li {
  padding: 3px 0;
}
.page-footer .page-footer__content .footer__col ul li:before {
  display: inline-block;
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 17.513134851138354%;
  width: 6px;
  height: 8px;
  margin-right: 10px;
}
.page-footer .page-footer__content .footer__col ul li a {
  color: #0D314D;
}
@media screen and (min-width: 1024px) {
  .page-footer .page-footer__content .footer__col.footer__col--third {
    flex: 1 1 150%;
  }
}
@media screen and (max-width: 1023px) {
  .page-footer .page-footer__content .footer__col {
    flex: 1 1 50%;
    width: 50%;
    margin-bottom: 20px;
  }
  .page-footer .page-footer__content .footer__col:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 639px) {
  .page-footer .page-footer__content .footer__col {
    flex: 1 1 100%;
    width: 100%;
  }
  .page-footer .page-footer__content .footer__col.footer__col--third {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 479px) {
  .page-footer .page-footer__content .footer__col.footer__col--fourth {
    text-align: center;
  }
}
.page-footer .page-footer__content .footer__col--no-title {
  padding-top: 44px;
}
@media screen and (max-width: 639px) {
  .page-footer .page-footer__content .footer__col--no-title {
    padding-top: 0;
  }
}
.page-footer__newsletter {
  padding: 50px 0;
  background: #0D314D;
  color: #FFFFFF;
}
.footer__newsletter {
  *zoom: 1;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
}
.footer__newsletter:before,
.footer__newsletter:after {
  content: " ";
  display: table;
}
.footer__newsletter:after {
  clear: both;
}
.footer__newsletter:before,
.footer__newsletter:after {
  content: '';
  display: table;
}
.footer__newsletter:after {
  clear: both;
}
@media screen and (max-width: 767px) {
  .footer__newsletter {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.footer__newsletter-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .footer__newsletter-inner {
    flex-direction: column;
    align-items: baseline;
  }
}
.footer__newsletter-title {
  flex: 1 1 60%;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 2.6rem;
}
.footer__newsletter-title strong {
  color: #009EE6;
}
.footer__newsletter-title p {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .footer__newsletter-title {
    margin-bottom: 20px;
    flex: 1 1 100%;
    width: 100%;
    font-size: 1.8rem;
  }
}
.footer__newsletter-form {
  flex: 1 1 40%;
}
@media screen and (min-width: 768px) {
  .footer__newsletter-form {
    margin-left: 40px;
  }
}
.footer__newsletter-form input {
  border-color: white;
  background-clip: unset;
}
.footer__newsletter-form input:active {
  border-color: #009EE6;
}
@media screen and (max-width: 767px) {
  .footer__newsletter-form {
    flex: 1 1 100%;
    width: 100%;
  }
}
.footer__col .payment-methods__container {
  width: 100%;
}
.footer__col .payment-methods__container ul {
  list-style: none;
  padding: 0;
  max-width: 230px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
@media screen and (min-width: 768px) {
  .footer__col .payment-methods__container ul {
    max-width: 70%;
  }
}
@media screen and (min-width: 1024px) {
  .footer__col .payment-methods__container ul {
    max-width: 100%;
  }
}
.footer__col .payment-methods__container ul li {
  margin-right: 0;
}
.footer__col .payment-methods__container ul li:before {
  content: none !important;
}
.filter-content .filter-options .filter-options-item.active .filter-options-title::after {
  content: '\e621' !important;
}
.filter-content .filter-options .filter-options-item .filter-options-title::after {
  content: '\e622' !important;
}
.filter-content .filter-options .filter-options-item .filter-options-content {
  display: block;
}
.filter-content .filter-options .filter-options-item .filter-options-content a:hover {
  background: none;
  cursor: pointer;
}
.filter-content .filter-options .filter-options-item .filter-options-content .field.search {
  display: none;
}
.hiddenToolbar {
  display: none;
}
.category-list {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  color: #0D314D;
}
.category-list a {
  color: #0D314D;
  padding: 2px 0;
  display: block;
}
.category-list a:hover {
  color: #009EE6;
}
.category-list ul {
  padding-left: 40px;
}
.category-list ul.category-list__first-depth {
  padding-left: 0;
}
.category-list ul li.active > a {
  color: #009EE6;
}
.category-list .level0 {
  display: none;
}
.category-list .level0 > a {
  font-size: 2.2rem;
  position: relative;
  padding-left: 25px;
}
.category-list .level0 > a:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 69.77152899824253%;
  width: 12px;
  height: 10px;
  content: '';
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  transition: transform 0.5s;
}
.category-list .level0 .submenu {
  display: none;
}
.category-list .level0.active,
.category-list .level0.has-active {
  display: block;
}
.category-list .level0.active .submenu,
.category-list .level0.has-active .submenu {
  display: block;
}
.filter .block-subtitle {
  display: none;
}
.filter-title strong {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #0D314D;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #0D314D;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.filter-title strong:visited,
.filter-title strong:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.filter-title strong:hover,
.filter-title strong:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.filter-title strong:visited,
.filter-title strong:focus,
.filter-title strong:hover,
.filter-title strong:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.filter-title strong:visited,
.filter-title strong:focus {
  background: #0D314D;
  color: white;
  text-decoration: none;
  border: 2px solid #0D314D;
}
.filter-title strong:hover,
.filter-title strong:active {
  background: #092337;
  color: white;
  text-decoration: none;
  border: 2px solid #092337;
}
.filter-title strong:visited,
.filter-title strong:focus,
.filter-title strong:hover,
.filter-title strong:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.filter-options-item {
  border-bottom: 0;
}
.filter-options-item .filter-options-title {
  text-transform: none;
  color: #0D314D;
  padding: 0;
  font-size: 1.8rem;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
}
.filter-options-item .filter-options-title .label {
  width: calc(100% - 30px);
}
.filter-options-item .filter-options-content {
  padding: 0;
}
.filter-options-item .arrow {
  margin-top: 3px;
}
.filter-options-item .reset {
  display: none;
}
.filter-options .items .item input[type=checkbox] {
  display: none;
}
.filter-options .items .item input[type=checkbox]:checked + label {
  font-weight: normal;
}
.filter-options .items .item label {
  width: 100%;
  display: block;
  font-size: 1.5rem;
  font-weight: normal;
  color: #0D314D;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
}
.filter-options .items .item label:before {
  content: '';
  display: block;
  background: white;
  width: 20px;
  height: 20px;
  border: 1px solid #9B9B9B;
  border-radius: 4px;
  position: absolute;
  top: 1px;
  left: 0;
}
.filter-options .items .item input[type=checkbox]:checked + label:before,
.filter-options .items .item label:hover:before {
  background: url(../svg/src/check-icon-blue.svg) no-repeat center center;
}
@media screen and (max-width: 379px) {
  .filter-title strong {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .filter-options {
    display: block;
  }
  .filter-options .category-list,
  .filter-options .filter-options-item {
    display: none;
  }
  .filter.active .block-subtitle {
    border-bottom: 0;
    padding: 8px 10px 20px;
  }
  .filter.active .filter-title strong {
    top: 4px;
    width: 100%;
    text-align: right;
  }
  .filter.active .filter-title:hover strong:after {
    color: #009EE6;
  }
  .filter.active .filter-options {
    overflow: auto;
    padding: 10px;
  }
  .filter.active .filter-options .category-list,
  .filter.active .filter-options .filter-options-item {
    display: block;
  }
}
.catalogsearch-result-index .layered-navigation__filter-option-count {
  display: none;
}
#layered-filter-block .button-list {
  display: none;
}
.filter-options-content a {
  margin: unset;
  padding: unset;
}
.selected-items__title {
  color: #0D314D;
  font-size: 1.8rem;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  margin-bottom: 10px;
}
#layered-selected-filters-block .selected-items-list .selected-item {
  padding: 0;
  background-color: transparent;
  border: 0;
  display: block;
  line-height: normal;
  margin-bottom: 5px;
}
#layered-selected-filters-block .selected-items-list .selected-item:hover {
  text-decoration: none;
}
#layered-selected-filters-block .selected-items-list .selected-item .action-clear {
  font-size: 1.4rem;
  text-decoration: underline;
}
#layered-selected-filters-block .selected-items-list .selected-item .action-clear:hover {
  text-decoration: none;
}
@media screen and (min-width: 380px) and (max-width: 767px) {
  #layered-selected-filters-block .selected-items-list .selected-item[data-role="selected-item"] {
    float: left;
  }
}
#layered-selected-filters-block .selected-items-list .selected-item:last-child {
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  #layered-selected-filters-block .selected-items-list .selected-item:last-child {
    display: inline-block;
    clear: both;
    float: none;
    width: 100%;
  }
  #layered-selected-filters-block .selected-items-list .selected-item:last-child:before,
  #layered-selected-filters-block .selected-items-list .selected-item:last-child:after {
    content: '';
    display: table;
  }
  #layered-selected-filters-block .selected-items-list .selected-item:last-child:after {
    clear: both;
  }
}
#layered-selected-filters-block .selected-items-list .selected-item .action-close {
  background: #009EE6;
  color: white;
  padding: 6px 15px;
  font-size: 1.6rem;
  border-radius: 40px;
  margin-right: 10px;
  position: relative;
  padding-right: 35px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  #layered-selected-filters-block .selected-items-list .selected-item .action-close {
    width: 80%;
  }
}
@media screen and (min-width: 380px) and (max-width: 767px) {
  #layered-selected-filters-block .selected-items-list .selected-item .action-close {
    width: auto;
  }
}
#layered-selected-filters-block .selected-items-list .selected-item .action-close > span {
  width: auto;
  position: relative;
  height: auto;
  overflow: unset;
  margin: 0;
  clip: unset;
  font-size: 1.6rem;
  padding: 0;
  float: left;
}
#layered-selected-filters-block .selected-items-list .selected-item .action-close:before {
  display: none;
}
#layered-selected-filters-block .selected-items-list .selected-item .action-close:after {
  display: inline-block;
  content: '';
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 84.12698412698413%;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  float: right;
  margin-top: 3px;
  position: absolute;
  right: 15px;
}
#layered-selected-filters-block .selected-items-list .selected-item .action-close:hover,
#layered-selected-filters-block .selected-items-list .selected-item .action-close:active,
#layered-selected-filters-block .selected-items-list .selected-item .action-close:focus {
  background: #008ccd;
}
.toolbar-products {
  text-align: left;
}
.toolbar-products .toolbar-amount {
  display: none;
}
.toolbar-products label {
  font-family: 'Ubuntu', sans-serif;
  color: #0D314D;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0 0 2px;
}
.toolbar-products select {
  background-color: #FFFFFF;
  height: 37px;
  width: 140px;
  padding: 0 0 0 12px;
}
.toolbar-products .toolbar-sorter {
  float: left;
  position: relative;
  right: auto;
  top: auto;
}
@media screen and (max-width: 479px) {
  .toolbar-products .toolbar-sorter {
    width: 100%;
    margin-top: 10px;
  }
  .toolbar-products .toolbar-sorter .sorter-options {
    width: 120px;
  }
}
.toolbar-products .toolbar-sorter .sort-asc:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 4.838709677419355%;
  width: 18px;
  height: 21px;
  color: transparent;
  margin-left: 10px;
}
.toolbar-products .toolbar-sorter .sort-asc:hover:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 8.602150537634408%;
  width: 18px;
  height: 21px;
}
.toolbar-products .toolbar-sorter .sort-desc:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 4.838709677419355%;
  transform: rotate(180deg);
  width: 18px;
  height: 21px;
  color: transparent;
  margin-left: 10px;
}
.toolbar-products .toolbar-sorter .sort-desc:hover:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 8.602150537634408%;
  width: 18px;
  height: 21px;
  transform: rotate(180deg);
}
.toolbar-products .pages {
  width: 100%;
  text-align: center;
}
.toolbar-products .pages .pages-item-next,
.toolbar-products .pages .pages-item-previous {
  background: none;
}
.toolbar-products .pages .pages-item-next span.label,
.toolbar-products .pages .pages-item-previous span.label {
  display: none;
}
.toolbar-products .pages .pages-item-next span,
.toolbar-products .pages .pages-item-previous span {
  width: auto;
  position: relative;
  height: auto;
  font-family: 'Ubuntu', sans-serif;
  color: #0D314D;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0 0 2px;
}
.toolbar-products .pages .pages-item-next .action:hover span,
.toolbar-products .pages .pages-item-previous .action:hover span {
  color: #009EE6;
}
.toolbar-products .pages .pages-item-next .action,
.toolbar-products .pages .pages-item-previous .action {
  border: none;
  background: none;
  line-height: 1.6rem;
  box-shadow: none;
}
.toolbar-products .pages .pages-item-next .action:after,
.toolbar-products .pages .pages-item-previous .action:after {
  position: relative;
  width: auto;
  height: auto;
}
.toolbar-products .pages .pages-item-next .action.next:after {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 13.403880070546737%;
  width: 14px;
  height: 12px;
  display: inline-block;
  content: '';
  margin-left: 10px;
}
.toolbar-products .pages .pages-item-next .action.next:before {
  display: none;
}
.toolbar-products .pages .pages-item-next .action.next:hover:after {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 15.520282186948853%;
  width: 14px;
  height: 12px;
}
.toolbar-products .pages .pages-item-previous .action.previous:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 13.403880070546737%;
  width: 14px;
  height: 12px;
  transform: rotate(180deg);
  content: '';
  margin-right: 10px;
}
.toolbar-products .pages .pages-item-previous .action.previous:hover:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 15.520282186948853%;
  width: 14px;
  height: 12px;
}
.toolbar-products .pages .item,
.toolbar-products .pages a.page {
  font-family: 'Ubuntu', sans-serif;
  color: #0D314D;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0 0 2px;
}
.toolbar-products .pages .item:active,
.toolbar-products .pages a.page:active {
  color: #0D314D;
}
.toolbar-products .pages .item:visited,
.toolbar-products .pages a.page:visited {
  color: #0D314D;
}
.toolbar-products .pages .item:hover,
.toolbar-products .pages a.page:hover {
  color: #009EE6;
}
.toolbar-products .pages .item.current strong.page {
  font-family: 'Ubuntu', sans-serif;
  color: #009EE6;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0 0 2px;
  background: none;
}
.page-products .toolbar-products .limiter {
  display: block;
  float: right;
}
@media screen and (max-width: 479px) {
  .page-products .toolbar-products .limiter {
    width: 100%;
  }
}
.page-products .toolbar-products .limiter select {
  width: 60px;
}
.page-products .toolbar-products .limiter .limiter-text {
  display: none;
}
.page-products .toolbar-products:last-child .limiter {
  display: none;
}
.products.wrapper ~ .toolbar .field.limiter {
  display: none;
}
.catalog-product-view .products-grid .product-items .product-item {
  width: 25%;
}
@media screen and (max-width: 767px) {
  .catalog-product-view .products-grid .product-items .product-item {
    width: 50%;
  }
}
@media screen and (max-width: 479px) {
  .catalog-product-view .products-grid .product-items .product-item {
    width: 100%;
  }
}
.catalog-product-view .products-grid .product-items .product-item .product-item-info .field.choice {
  display: none;
}
.page-with-filter > .page-wrapper .breadcrumbs {
  order: 3;
}
.page-with-filter > .page-wrapper .category-view {
  order: 2;
}
.page-with-filter > .page-wrapper .page-main {
  order: 1;
}
.page-with-filter > .page-wrapper #maincontent {
  order: 4;
}
.page-with-filter > .page-wrapper .page-footer {
  order: 5;
}
.product-page__addto-box {
  position: relative;
}
.product-page__addto-box:before,
.product-page__addto-box:after {
  content: '';
  display: table;
}
.product-page__addto-box:after {
  clear: both;
}
@media screen and (max-width: 767px) {
  .product-page__addto-box {
    margin-top: 10px;
  }
}
.box-tocart {
  margin: 0;
}
.box-tocart .action.tocart {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  position: relative;
  padding-right: 65px;
  padding-left: 25px;
  text-align: left;
  width: 100%;
  display: none;
}
.box-tocart .action.tocart:visited,
.box-tocart .action.tocart:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.box-tocart .action.tocart:hover,
.box-tocart .action.tocart:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.box-tocart .action.tocart:visited,
.box-tocart .action.tocart:focus,
.box-tocart .action.tocart:hover,
.box-tocart .action.tocart:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.box-tocart .action.tocart:visited,
.box-tocart .action.tocart:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.box-tocart .action.tocart:hover,
.box-tocart .action.tocart:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.box-tocart .action.tocart:visited,
.box-tocart .action.tocart:focus,
.box-tocart .action.tocart:hover,
.box-tocart .action.tocart:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.box-tocart .action.tocart:before {
  content: "";
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 24.057450628366247%;
  width: 34px;
  height: 22px;
  display: block;
  position: absolute;
  right: 20px;
  top: 9px;
}
.product-info-main {
  border: 1px solid #b9c4cc;
  border-radius: 4px;
}
.product-info-main .product-info-price {
  padding: 30px 20px 15px;
  margin-bottom: 0;
  display: block;
  border-bottom: 0;
}
.product-info-main .product-info-price .price-box {
  margin: 10px 0 30px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.product-info-main .product-info-price .price-box .price-container {
  display: flex;
  flex-direction: column-reverse;
}
.product-info-main .product-info-price .price-box .price-container .price {
  color: #009EE6;
}
.product-info-main .product-info-price .price-box .price-container .old-price-excl-vat {
  line-height: normal;
}
.product-info-main .product-info-price .price-box .price-container .old-price-excl-vat .price {
  color: #009EE6;
  font-size: 2.8rem;
}
.product-info-main .product-info-price .price-box .price-container .price-excluding-tax,
.product-info-main .product-info-price .price-box .price-container .price-including-tax {
  margin: 2px 0;
}
.product-info-main .product-info-price .price-box .price-container .price-excluding-tax .price {
  font-size: 2.8rem;
}
.product-info-main .product-info-price .price-box .price-container .price-excluding-tax:before {
  display: none;
}
.product-info-main .product-info-price .price-box .price-container .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.4rem;
  text-transform: lowercase;
}
.product-info-main .product-info-price .price-box .price-container .price-including-tax .price {
  font-size: 1.8rem;
  color: #666;
}
.product-info-main .product-info-price .price-box .price-container .price-including-tax:before {
  display: none;
}
.product-info-main .product-info-price .price-box .price-container .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #4a4a4a;
  font-size: 1.2rem;
  text-transform: lowercase;
}
.product-info-main .product-info-price .price-box .old-price .price-container {
  display: flex;
  flex-direction: column-reverse;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price {
  color: #009EE6;
}
.product-info-main .product-info-price .price-box .old-price .price-container .old-price-excl-vat {
  line-height: normal;
}
.product-info-main .product-info-price .price-box .old-price .price-container .old-price-excl-vat .price {
  color: #009EE6;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price-excluding-tax,
.product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax {
  margin: 2px 0;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price-excluding-tax:before {
  display: none;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price-excluding-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #939494;
  text-transform: lowercase;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax .price {
  color: #666;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax:before {
  display: none;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax:after {
  display: none;
  display: inline-block;
  content: attr(data-label);
  margin-left: 5px;
  color: #939494;
  text-transform: lowercase;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price-label {
  display: none;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price {
  text-decoration: line-through;
  font-weight: normal;
  color: #666;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price-wrapper:after {
  text-decoration: line-through;
}
.product-info-main .product-info-price .price-box .special-price {
  margin-left: 2px;
}
.product-info-main .product-info-price .price-box .old-price {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price-excluding-tax:after,
.product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax:after,
.product-info-main .product-info-price .price-box .old-price .price-container .price-excluding-tax:before,
.product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax:before {
  display: none;
}
@media screen and (max-width: 479px) {
  .product-info-main .product-info-price {
    padding-top: 35px;
  }
  .product-info-main .product-info-price .price-box {
    margin: 10px 0 30px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .product-info-main .product-info-price .price-box .price-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .product-info-main .product-info-price .price-box .price-container .price {
    color: #009EE6;
  }
  .product-info-main .product-info-price .price-box .price-container .old-price-excl-vat {
    line-height: normal;
  }
  .product-info-main .product-info-price .price-box .price-container .old-price-excl-vat .price {
    color: #009EE6;
    font-size: 2.4rem;
  }
  .product-info-main .product-info-price .price-box .price-container .price-excluding-tax,
  .product-info-main .product-info-price .price-box .price-container .price-including-tax {
    margin: 2px 0;
  }
  .product-info-main .product-info-price .price-box .price-container .price-excluding-tax .price {
    font-size: 2.4rem;
  }
  .product-info-main .product-info-price .price-box .price-container .price-excluding-tax:before {
    display: none;
  }
  .product-info-main .product-info-price .price-box .price-container .price-excluding-tax:after {
    display: none;
    display: inline-block;
    content: attr(data-label);
    margin-left: 5px;
    color: #4a4a4a;
    font-size: 1.4rem;
    text-transform: lowercase;
  }
  .product-info-main .product-info-price .price-box .price-container .price-including-tax .price {
    font-size: 1.6rem;
    color: #666;
  }
  .product-info-main .product-info-price .price-box .price-container .price-including-tax:before {
    display: none;
  }
  .product-info-main .product-info-price .price-box .price-container .price-including-tax:after {
    display: none;
    display: inline-block;
    content: attr(data-label);
    margin-left: 5px;
    color: #4a4a4a;
    font-size: 1.2rem;
    text-transform: lowercase;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .price {
    color: #009EE6;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .old-price-excl-vat {
    line-height: normal;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .old-price-excl-vat .price {
    color: #009EE6;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .price-excluding-tax,
  .product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax {
    margin: 2px 0;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .price-excluding-tax:before {
    display: none;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .price-excluding-tax:after {
    display: none;
    display: inline-block;
    content: attr(data-label);
    margin-left: 5px;
    color: #939494;
    text-transform: lowercase;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax .price {
    color: #666;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax:before {
    display: none;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax:after {
    display: none;
    display: inline-block;
    content: attr(data-label);
    margin-left: 5px;
    color: #939494;
    text-transform: lowercase;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .price-label {
    display: none;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .price {
    text-decoration: line-through;
    font-weight: normal;
    color: #666;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .price-wrapper:after {
    text-decoration: line-through;
  }
  .product-info-main .product-info-price .price-box .special-price {
    margin-left: 2px;
  }
  .product-info-main .product-info-price .price-box .old-price {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .product-info-main .product-info-price .price-box .old-price .price-container .price-excluding-tax:after,
  .product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax:after,
  .product-info-main .product-info-price .price-box .old-price .price-container .price-excluding-tax:before,
  .product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax:before {
    display: none;
  }
}
.product-info-main .product-info-price .price-box {
  margin: 0;
  padding: 0;
  width: auto;
}
.product-info-main .product-info-price .price-box .old-price .price-container .price-excluding-tax,
.product-info-main .product-info-price .price-box .price-container .price-excluding-tax,
.product-info-main .product-info-price .price-box .old-price .price-container .price-including-tax,
.product-info-main .product-info-price .price-box .price-container .price-including-tax {
  margin: 0;
}
.product-info-main .product-info-price .price-box .price-container .price,
.product-info-main .product-info-price .price-box .price-including-tax + .price-excluding-tax .price {
  line-height: normal;
}
.product-info-main .product.alert a {
  text-decoration: underline;
}
.product-info-main .product.alert a:hover {
  text-decoration: none;
}
.product-social-links {
  padding: 20px;
  margin: 10px 0 0;
  border-bottom: 1px solid #b9c4cc;
  border-top: 1px solid #b9c4cc;
  text-align: left;
  display: none;
}
.product-social-links .action.tocompare {
  display: block;
  width: 100%;
}
.product-social-links .action.tocompare span {
  overflow: auto;
  height: auto;
  position: static;
  width: auto;
  text-transform: lowercase;
  font-weight: normal;
  font-size: 1.4rem;
  letter-spacing: normal;
  color: #0D314D;
}
.product-social-links .action.tocompare:before {
  margin-right: 5px;
  content: '';
}
.product-social-links .action.tocompare:hover span {
  color: #009EE6;
}
.product-social-links .action.tocompare:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 51.15452930728242%;
  width: 21px;
  height: 16px;
}
.product-social-links .action.tocompare:hover:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 53.99644760213144%;
  width: 21px;
  height: 16px;
}
.product-social-links .action.tocompare span {
  font-size: 1.6rem;
}
.product-page__usps {
  padding: 20px;
  border-bottom: 1px solid #b9c4cc;
}
.product-page__usps ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.product-page__usps ul li {
  color: #0D314D;
  font-size: 1.6rem;
  font-family: 'Ubuntu', sans-serif;
  line-height: 1.7;
  position: relative;
  padding-left: 20px;
}
.product-page__usps ul li:before {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 29.173989455184536%;
  width: 13px;
  height: 10px;
  display: inline-block;
  content: '';
  margin-right: 10px;
  position: absolute;
  top: 8px;
  left: 0;
}
.product-page__payment {
  padding: 20px;
}
.product-page__payment p {
  margin-bottom: 0;
  line-height: 0;
}
.product-page__button-wrapper {
  padding: 0 20px 10px;
}
.product-page__button-container {
  display: flex;
  align-items: flex-end;
}
.product-page__button-container .product-options-bottom {
  margin-top: 0;
  margin-right: 15px;
}
.product-page__button-container .product-options-bottom .box-tocart {
  margin-top: 0;
}
.product-page__button-container button.product-page__button {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  position: relative;
  padding-right: 65px;
  padding-left: 25px;
  text-align: left;
  width: 100%;
  max-width: 360px;
  margin-bottom: 20px;
}
.product-page__button-container button.product-page__button:visited,
.product-page__button-container button.product-page__button:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.product-page__button-container button.product-page__button:hover,
.product-page__button-container button.product-page__button:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.product-page__button-container button.product-page__button:visited,
.product-page__button-container button.product-page__button:focus,
.product-page__button-container button.product-page__button:hover,
.product-page__button-container button.product-page__button:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.product-page__button-container button.product-page__button:visited,
.product-page__button-container button.product-page__button:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.product-page__button-container button.product-page__button:hover,
.product-page__button-container button.product-page__button:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.product-page__button-container button.product-page__button:visited,
.product-page__button-container button.product-page__button:focus,
.product-page__button-container button.product-page__button:hover,
.product-page__button-container button.product-page__button:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.product-page__button-container button.product-page__button:before {
  content: "";
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 24.057450628366247%;
  width: 34px;
  height: 22px;
  display: block;
  position: absolute;
  right: 20px;
  top: 9px;
}
@media (max-width: 1110px) {
  .product-page__button-container button.product-page__button {
    font-size: 1.7rem;
  }
}
@media (max-width: 1090px) {
  button.product-page__button {
    font-size: 1.6rem;
  }
}
@media (max-width: 1070px) {
  button.product-page__button {
    font-size: 1.5rem;
  }
}
@media (max-width: 1050px) {
  button.product-page__button {
    font-size: 1.4rem;
  }
}
@media (max-width: 1024px) {
  button.product-page__button {
    font-size: 1.8rem;
  }
}
@media (max-width: 370px) {
  button.product-page__button {
    padding-right: 0;
    padding-left: 0;
  }
  button.product-page__button span {
    display: block;
    width: 100%;
    text-align: center;
  }
  button.product-page__button::before {
    content: "";
    background: none;
  }
}
.product-page__blocks {
  width: 100%;
}
.product-blocks {
  width: 100%;
  float: left;
}
.product-blocks .product-block {
  margin-bottom: 40px;
}
.product-blocks .product-block .product-block__title {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 2.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.product-blocks .product-block .product-block__title span:not(.base) {
  color: #009EE6;
}
.product-blocks .product-block .product-block__content {
  font-size: 1.8rem;
  color: #0D314D;
  font-family: 'Barlow', sans-serif;
  font-weight: 400;
}
.product-blocks .product-block .product-block__content.flex {
  align-items: center;
  display: flex;
  gap: 4rem;
  padding: 2rem 0;
}
.product-blocks .product-block .product-block__content.flex .product-block__content--image {
  flex: 0 0 100%;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .product-blocks .product-block .product-block__content.flex .product-block__content--image {
    flex: 0 0 calc(40% - 2rem);
    width: calc(40% - 2rem);
  }
}
.product-blocks .product-block .product-block__content.flex .product-block__content--image:last-of-type {
  flex: 0 0 100%;
  width: 100%;
}
.product-blocks .product-block .product-block__content.flex .product-block__content--image + .product-block__content--text {
  flex: 0 0 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .product-blocks .product-block .product-block__content.flex .product-block__content--image + .product-block__content--text {
    flex: 0 0 calc(60% - 2rem);
    width: calc(60% - 2rem);
  }
}
.product-blocks .product-block .product-block__content.flex .product-block__content--text {
  flex: 0 0 100%;
  width: 100%;
}
.product-blocks .product-block .product-block__content.image-left {
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .product-blocks .product-block .product-block__content.image-left {
    flex-direction: row;
  }
}
.product-blocks .product-block .product-block__content.image-right {
  flex-direction: column-reverse;
}
@media screen and (min-width: 768px) {
  .product-blocks .product-block .product-block__content.image-right {
    flex-direction: row-reverse;
  }
}
.product-blocks .product-block .product-block__content .table-wrapper table tr th {
  border: none;
  width: 20%;
  min-width: 200px;
  padding: 0 0 2px 0;
  font-weight: 500;
}
.product-blocks .product-block .product-block__content .table-wrapper table tr td {
  border: none;
  padding: 0 0 2px 0;
}
.product-blocks .product-block .product-block__content .table-wrapper table tr td::before {
  content: ":\00a0";
}
@media screen and (max-width: 639px) {
  .product-blocks .product-block .product-block__content .table-wrapper {
    border-top: 0;
  }
  .product-blocks .product-block .product-block__content .table-wrapper table tr th {
    border: none;
    width: 20%;
    min-width: 200px;
    padding: 0 0 2px 0;
    font-weight: 500;
  }
  .product-blocks .product-block .product-block__content .table-wrapper table tr td {
    border: none;
    padding: 0 0 2px 0;
  }
  .product-blocks .product-block .product-block__content .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr,
  .product-blocks .product-block .product-block__content .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr {
    margin: 0 0 5px;
  }
  .product-blocks .product-block .product-block__content .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr td:before,
  .product-blocks .product-block .product-block__content .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr td:before,
  .product-blocks .product-block .product-block__content .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr th:before,
  .product-blocks .product-block .product-block__content .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr th:before {
    display: block;
    color: #0D314D;
    font-weight: 500;
  }
}
@media screen and (min-width: 480px) {
  .product-downloads {
    display: table;
  }
}
@media screen and (min-width: 768px) {
  .product-downloads {
    padding-right: 20px;
  }
}
.product-downloads__button-wrapper {
  margin-bottom: 10px;
}
.product-downloads__button {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #009EE6;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #009EE6;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}
.product-downloads__button:visited,
.product-downloads__button:focus {
  background: #009EE6;
  color: white;
  text-decoration: none;
  border: 2px solid #009EE6;
}
.product-downloads__button:hover,
.product-downloads__button:active {
  background: #008ccd;
  color: white;
  text-decoration: none;
  border: 2px solid #008ccd;
}
.product-downloads__button:visited,
.product-downloads__button:focus,
.product-downloads__button:hover,
.product-downloads__button:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.product-downloads__button:visited,
.product-downloads__button:focus {
  background: #009EE6;
  color: white;
  text-decoration: none;
  border: 2px solid #009EE6;
}
.product-downloads__button:hover,
.product-downloads__button:active {
  background: #008ccd;
  color: white;
  text-decoration: none;
  border: 2px solid #008ccd;
}
.product-downloads__button:visited,
.product-downloads__button:focus,
.product-downloads__button:hover,
.product-downloads__button:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.product-page__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  -ms-flex-pack: justify;
}
.product-page__details .product-page__downloads {
  flex: 1;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .product-page__details .product-page__downloads {
    flex: 1 1 33.33333333%;
    width: 33.33333333%;
    padding-right: 20px;
  }
}
.product-page__details .product-page__downloads + .product-page__blocks {
  flex: 1;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .product-page__details .product-page__downloads + .product-page__blocks {
    flex: 1 1 66.66666667%;
    width: 66.66666667%;
  }
}
.product-page__media .product-page__media-inner {
  position: relative;
}
.product-page__media .product-page__media-inner:before,
.product-page__media .product-page__media-inner:after {
  content: '';
  display: table;
}
.product-page__media .product-page__media-inner:after {
  clear: both;
}
.product-page__media .fotorama__stage {
  border: 1px solid #b9c4cc;
  border-radius: 5px;
}
@media screen and (min-width: 768px) {
  .product-page__media .fotorama__stage {
    margin-bottom: 2rem;
  }
}
.product-page__media .fotorama__nav-wrap .fotorama__img {
  border: 1px solid #b9c4cc !important;
  border-radius: 5px;
}
.product-page__media .fotorama__nav-wrap .fotorama__active .fotorama__img {
  border-color: #009EE6 !important;
}
.product-page__media .fotorama__thumb-border {
  border-color: #009EE6;
  border-radius: 5px;
  display: none;
}
.product-page__media .fotorama__nav--thumbs .fotorama__nav__frame {
  margin-right: 20px;
}
.product-page__media .fotorama__nav--thumbs .fotorama__nav__frame:last-child {
  margin-right: 0;
}
.fotorama__nav__frame {
  cursor: pointer;
}
.fotorama__nav .fotorama__dot {
  width: 10px;
  height: 10px;
}
.fotorama__nav .fotorama__active .fotorama__dot {
  background-color: #009EE6;
  border-color: #009EE6;
}
.fotorama__nav .fotorama__thumb {
  background-color: white;
}
.product-page .page-section--content {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media screen and (min-width: 1024px) {
  .product-page__main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.product-page__main h1.page-title {
  font-size: 3.4rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 767px) {
  .product-page__main h1.page-title {
    font-size: 3rem;
  }
}
.product-page__media,
.product-page__options,
.product-page__addto-box {
  flex: 1;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .product-page__media,
  .product-page__options,
  .product-page__addto-box {
    max-width: 33.33333333%;
  }
}
.product-page__details:before,
.product-page__product-relations:before,
.product-page__details:after,
.product-page__product-relations:after {
  content: '';
  display: table;
}
.product-page__details:after,
.product-page__product-relations:after {
  clear: both;
}
.product-page__details .block .block-title strong,
.product-page__product-relations .block .block-title strong {
  color: #0D314D;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 3.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.product-page__details .block .block-title strong span:not(.base),
.product-page__product-relations .block .block-title strong span:not(.base) {
  color: #009EE6;
}
.product-page__details .block .block-content .block-actions,
.product-page__product-relations .block .block-content .block-actions {
  display: none;
}
.product-page__details {
  margin-top: 3rem;
}
.product-page__details:before,
.product-page__details:after {
  content: '';
  display: table;
}
.product-page__details:after {
  clear: both;
}
@media screen and (max-width: 767px) {
  .product-page__details {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 480px) and (max-width: 1023px) {
  .product-page__media {
    width: 30%;
    display: inline-block;
  }
}
.product-page__options {
  margin: 20px 0 30px;
}
@media screen and (min-width: 480px) and (max-width: 1023px) {
  .product-page__options {
    width: 70%;
    float: right;
    margin: 0;
    padding: 0 20px;
  }
}
@media screen and (min-width: 1024px) {
  .product-page__options {
    padding: 0 15px;
    margin: 0 15px;
  }
}
.product-info-stock-sku > .stock {
  display: none;
}
.product.ean_number,
.product.sku {
  font-size: 1.8rem;
  font-weight: normal;
  color: #0D314D;
}
.product.ean_number strong.type,
.product.sku strong.type {
  font-weight: normal;
}
.product.ean_number strong.type:after,
.product.sku strong.type:after {
  content: ':';
}
.product.ean_number .value,
.product.sku .value {
  display: inline-block;
}
.product-page__meta-info {
  margin-bottom: 2rem;
}
.page-layout-1column .product.media,
.page-layout-1column .product-info-main {
  width: 100%;
}
.recently-viewed {
  min-width: 160px;
  min-height: 100px;
  position: relative;
  margin: 20px 0;
}
.recently-viewed__loader {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  background: transparent;
}
.recently-viewed__loader:before {
  background: transparent url('../images/loader-2.gif') no-repeat 50% 50%;
  border-radius: 5px;
  height: 160px;
  width: 160px;
  bottom: 0;
  box-sizing: border-box;
  content: '';
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.product-page__options .box-tocart.simple-selector-cart {
  display: none;
}
.box-tocart .input-text.qty {
  color: #0D314D;
  padding: 0 20px;
  font-size: 1.6rem;
  font-family: 'Barlow', sans-serif;
  border: 1px solid #9B9B9B;
  border-radius: 5px;
  box-shadow: none;
  height: 42px;
  text-align: center;
  padding: 0 10px;
}
.box-tocart .input-text.qty:focus {
  border: 1px solid #009EE6;
  box-shadow: none;
}
.box-tocart .input-text.qty.readonly {
  opacity: 1;
  background: lightgrey;
  pointer-events: none;
}
.box-tocart .input-text.qty.readonly:focus {
  border: 1px solid #9B9B9B;
  box-shadow: none;
}
.product-options-wrapper .swatch-opt {
  margin: 0;
}
.product-options-wrapper .swatch-attribute {
  margin: 0;
}
.product-options-wrapper .swatch-attribute-options {
  margin: 10px 0 !important;
}
.product-options-wrapper .swatch-attribute-label {
  font-family: 'Ubuntu', sans-serif;
  color: #0D314D;
  font-weight: 700;
  font-size: 1.6rem;
}
.product-options-wrapper select,
.product-options-wrapper input[type="text"],
.product-options-wrapper input[type="password"],
.product-options-wrapper input[type="url"],
.product-options-wrapper input[type="tel"],
.product-options-wrapper input[type="search"],
.product-options-wrapper input[type="number"],
.product-options-wrapper input[type="datetime"],
.product-options-wrapper input[type="file"],
.product-options-wrapper input[type="email"] {
  color: #0D314D;
  padding: 0 20px;
  font-size: 1.6rem;
  font-family: 'Barlow', sans-serif;
  border: 1px solid #9B9B9B;
  border-radius: 5px;
  box-shadow: none;
  height: 42px;
}
.product-options-wrapper select:focus,
.product-options-wrapper input[type="text"]:focus,
.product-options-wrapper input[type="password"]:focus,
.product-options-wrapper input[type="url"]:focus,
.product-options-wrapper input[type="tel"]:focus,
.product-options-wrapper input[type="search"]:focus,
.product-options-wrapper input[type="number"]:focus,
.product-options-wrapper input[type="datetime"]:focus,
.product-options-wrapper input[type="file"]:focus,
.product-options-wrapper input[type="email"]:focus {
  border: 1px solid #009EE6;
  box-shadow: none;
}
.product-options-wrapper select.readonly,
.product-options-wrapper input[type="text"].readonly,
.product-options-wrapper input[type="password"].readonly,
.product-options-wrapper input[type="url"].readonly,
.product-options-wrapper input[type="tel"].readonly,
.product-options-wrapper input[type="search"].readonly,
.product-options-wrapper input[type="number"].readonly,
.product-options-wrapper input[type="datetime"].readonly,
.product-options-wrapper input[type="file"].readonly,
.product-options-wrapper input[type="email"].readonly {
  opacity: 1;
  background: lightgrey;
  pointer-events: none;
}
.product-options-wrapper select.readonly:focus,
.product-options-wrapper input[type="text"].readonly:focus,
.product-options-wrapper input[type="password"].readonly:focus,
.product-options-wrapper input[type="url"].readonly:focus,
.product-options-wrapper input[type="tel"].readonly:focus,
.product-options-wrapper input[type="search"].readonly:focus,
.product-options-wrapper input[type="number"].readonly:focus,
.product-options-wrapper input[type="datetime"].readonly:focus,
.product-options-wrapper input[type="file"].readonly:focus,
.product-options-wrapper input[type="email"].readonly:focus {
  border: 1px solid #9B9B9B;
  box-shadow: none;
}
.product-options-bottom {
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .product-add-form .product-options-wrapper .field .control {
    width: 100%;
  }
}
select option[value="delimiter"] {
  display: none;
}
.stores {
  color: #0D314D;
  font-size: 1.6rem;
  padding: 0;
  margin: 10px 0 0;
}
.stores ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.stores ul li {
  margin-bottom: 0.5rem;
}
.stores a,
.stores a:visited,
.stores a:hover {
  color: #0D314D;
}
.stores__title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 2.2rem;
  font-weight: 700;
  display: block;
  margin-bottom: 0.5rem;
}
.stores__stock {
  font-weight: 700;
}
.stores__stock:before {
  content: ':';
  color: #0D314D;
  margin: 0 4px 0 0;
  font-weight: normal;
}
.stores__stock.stores__stock--available {
  color: #47BB0E;
}
.stores__stock.stores__stock--unavailable {
  color: #47BB0E;
}
.stores__list {
  position: relative;
  min-height: 30px;
}
.stores__loader {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  background: transparent;
  right: auto;
}
.stores__loader:before {
  background: transparent url('../images/loader-2.gif') no-repeat 50% 50%;
  border-radius: 5px;
  height: 160px;
  width: 160px;
  bottom: 0;
  box-sizing: border-box;
  content: '';
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.stores__loader:before {
  width: 30px;
  height: 30px;
  background-size: 150px;
}
.delivery-label + .stores .stores__loader {
  display: none;
}
.product-options-wrapper label[for="options_length_text"]:after {
  display: none;
}
.product-options-wrapper .product-custom-option {
  margin: 5px 0;
}
.product-options-wrapper .field .note {
  color: #0D314D;
  font-size: 1.8rem;
}
.note__message {
  display: block;
  margin-bottom: 2em;
  color: #bf0000;
}
.product-tailormade-info {
  padding: 0 20px;
  color: #0D314D;
}
.product-page__price-calculation .product-info-main .product-info-price .price-box .price-container .price-excluding-tax:after,
.product-page__price-calculation .product-info-main .product-info-price .price-box .price-container .price-including-tax:after {
  content: attr(data-label-calculation);
}
.configuration_notice {
  color: #0D314D;
  font-size: 1.7rem;
  padding: 0;
  font-weight: 700;
}
.reset-button__container {
  padding: 10px 0;
}
.delivery-label__text {
  color: #0D314D;
  font-size: 1.6rem;
  padding-top: 5px;
  line-height: 1.7;
  position: relative;
  display: block;
  min-height: 20px;
  margin-bottom: 20px;
}
.bundle-options-container:before,
.bundle-options-container:after {
  content: '';
  display: table;
}
.bundle-options-container:after {
  clear: both;
}
.bundle-options-container .bundle-options-wrapper,
.bundle-options-container .product-options-wrapper {
  width: 100%;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .bundle-options-container .bundle-options-wrapper,
  .bundle-options-container .product-options-wrapper {
    width: 100%;
    float: none;
  }
}
.bundle-options-container .bundle-options-wrapper .fieldset,
.bundle-options-container .product-options-wrapper .fieldset {
  margin: 0;
}
.bundle-options-container .bundle-options-wrapper .fieldset > .field,
.bundle-options-container .product-options-wrapper .fieldset > .field {
  border-top: 0;
  padding-top: 0;
}
.bundle-options-container .bundle-options-wrapper .fieldset > .field .nested:not(.options-list),
.bundle-options-container .product-options-wrapper .fieldset > .field .nested:not(.options-list) {
  display: none;
}
.bundle-options-container .bundle-options-wrapper .price-box,
.bundle-options-container .product-options-wrapper .price-box {
  display: none;
}
.bundle-options-container .block-bundle-summary {
  width: 100%;
  float: none;
  padding: 0;
  margin-top: 0;
  top: auto !important;
  position: relative !important;
}
@media screen and (min-width: 768px) {
  .bundle-options-container .block-bundle-summary {
    width: 100%;
    float: none;
    padding: 0;
    margin-top: 0;
    top: auto;
  }
}
.bundle-options-container .block-bundle-summary .price-box {
  display: none;
}
.bundle-options-container .product-add-form {
  display: block !important;
}
.page-product-bundle .product-page__addto-box .product-info-price > .price-box p {
  margin-bottom: 0;
}
.page-product-bundle .product-page__addto-box .product-info-price .price-to {
  display: none;
}
.page-product-bundle .product-page__addto-box .product-info-price .price-from .price-label {
  display: none;
}
.aw-acp-popup-container.mfp-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #0b0b0b;
  opacity: 0.8;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.aw-acp-popup-container.mfp-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}
.aw-acp-popup-container .mfp-container {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 0 8px;
  width: 100%;
  height: 100%;
  text-align: center;
}
.aw-acp-popup-container .mfp-container::before {
  display: inline-block;
  height: 100%;
  content: "";
  vertical-align: middle;
}
.aw-acp-popup-container.mfp-align-top .mfp-container::before {
  display: none;
}
.aw-acp-popup-container .mfp-content {
  position: relative;
  z-index: 106;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
  text-align: left;
}
.aw-acp-popup-container .mfp-ajax-holder .mfp-content,
.aw-acp-popup-container .mfp-inline-holder .mfp-content {
  width: 100%;
  cursor: auto;
}
.aw-acp-popup-container.mfp-ajax-cur {
  cursor: progress;
}
.aw-acp-popup-container.mfp-auto-cursor .mfp-content {
  cursor: auto;
}
.aw-acp-popup-container .mfp-preloader {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  right: 8px;
  left: 8px;
  z-index: 105;
  margin-top: -0.8em;
  width: auto;
  color: #ccc;
  text-align: center;
}
.aw-acp-popup-container .mfp-preloader a {
  color: #ccc;
}
.aw-acp-popup-container .mfp-preloader a:hover {
  color: #FFFFFF;
}
.aw-acp-popup-container .mfp-s-ready .mfp-preloader {
  display: none;
}
.aw-acp-popup-container .mfp-s-error .mfp-content {
  display: none;
}
.aw-acp-popup-container .aw-acp-popup-with-anim {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.aw-acp-popup-container--zoom-in.mfp-ready .aw-acp-popup-with-anim {
  opacity: 1;
}
.aw-acp-popup-container--zoom-in.mfp-removing .aw-acp-popup-with-anim {
  opacity: 0;
}
.aw-acp-popup-container--zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}
.aw-acp-popup {
  position: relative;
  margin: 20px auto;
  padding: 15px;
  max-width: 800px;
  background: #FFFFFF;
  box-sizing: border-box;
}
.aw-acp-popup * {
  box-sizing: border-box;
}
.aw-acp-popup .swatch-option {
  box-sizing: content-box;
}
.aw-acp-popup__title,
.aw-acp-popup__description,
.aw-acp-popup__options-wrapper {
  margin: 0 0 20px;
}
.aw-acp-popup__mobile-title {
  font-size: 26px;
  font-weight: 700;
  padding: 0 20px;
  margin: 10px 0;
  line-height: 1.1;
}
.aw-acp-popup__form .fieldset > .field:not(.choice) > .label,
.aw-acp-popup__form .fieldset > .field:not(.choice) > .control {
  width: auto;
  float: none;
}
.aw-acp-popup__form .fieldset > .field:not(.choice) > .label {
  text-align: left;
  box-sizing: content-box;
  padding: 0;
  margin: 0 0 5px;
}
.aw-acp-popup .products-grid .product-items:not(.widget-product-grid) .product-item .product-item-photo {
  margin: 0;
}
.aw-acp-popup__options-wrapper .fieldset {
  margin-left: 2px;
}
.aw-acp-popup__options-wrapper .fieldset .legend {
  margin: 0 0 20px;
}
.aw-acp-popup__box-tocart-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
}
.aw-acp-popup__box-qty {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  margin: 0;
}
.aw-acp-popup__box-price {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
.aw-acp-popup__box-price .price-container .price {
  color: #009900;
}
.aw-acp-popup__mobile-close-wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 15px;
  line-height: 10px;
}
.aw-acp-popup__mobile-close-wrapper.headless {
  margin-left: 0;
  width: 100%;
}
.aw-acp-popup__mobile-close-wrapper .aw-acp-popup__mobile-close.action.primary {
  border: none;
  background: none;
  color: #1979c3;
  width: 100%;
}
.aw-acp-popup__message {
  margin: 0 0 20px;
  padding: 13px 20px;
  border-radius: 4px;
  line-height: 20px;
}
.aw-acp-popup__message--good {
  background: none;
  color: initial;
}
.aw-acp-popup__message--bad {
  background: none;
  color: initial;
}
.aw-acp-popup__message--info {
  background: none;
  color: initial;
}
.aw-acp-popup__main {
  margin-bottom: 20px;
}
.aw-acp-popup__loader {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 0 40px;
  height: 300px;
}
.aw-acp-popup__loader-bar {
  position: relative;
  display: block;
  overflow: hidden;
  margin: auto;
  max-width: 300px;
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background: #2bc253 -webkit-linear-gradient(top, #54f054, #2bc253);
  background: #2bc253 linear-gradient(180deg, #54f054, #2bc253);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
}
.aw-acp-popup__loader-bar::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  overflow: hidden;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.2) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.2) 75%, transparent 0, transparent);
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.2) 75%, transparent 0, transparent);
  background-size: 50px 50px;
  content: "";
  -webkit-animation: a 2s linear infinite;
  animation: a 2s linear infinite;
}
.aw-acp-popup__column--media {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.aw-acp-popup__reviews-wrapper,
.aw-acp-popup__image-wrapper {
  margin: 0;
  text-align: center;
}
.aw-acp-popup__reviews-wrapper {
  height: 20px;
  flex: 1 1 auto;
}
.aw-acp-popup__reviews-wrapper .product-reviews-summary {
  display: block;
}
.aw-acp-popup__reviews-wrapper .product-reviews-summary .rating-summary,
.aw-acp-popup__reviews-wrapper .product-reviews-summary .reviews-actions {
  display: inline-block;
  vertical-align: middle;
}
.aw-acp-popup__image-wrapper {
  flex: 0 0 auto;
  position: relative;
}
.aw-acp-popup__image {
  max-width: 100%;
  max-height: 300px;
  height: auto;
}
.aw-acp-popup__actions {
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.aw-acp-popup__close-wrapper {
  text-align: center;
}
.aw-acp-popup__columns {
  width: auto !important;
}
.aw-acp-popup__columns .price-box {
  margin: 0;
}
.aw-acp-popup__submit,
.aw-acp-popup__close {
  font-size: 18px !important;
}
.aw-acp-popup__submit {
  width: 100%;
}
.aw-acp-popup__result {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  text-align: center;
  /* Related */
}
.aw-acp-popup__result-title {
  margin: 0 0 10px;
  font-size: 18px;
}
.aw-acp-popup__result-price {
  margin: 0 0 10px;
  font-size: 24px;
}
.aw-acp-popup__result-items {
  margin: 0 0 10px;
}
.aw-acp-popup__result-view-cart {
  margin: 0;
}
.aw-acp-popup__result__related {
  border-top: 1px solid #ccc;
  margin-top: 25px;
  margin-bottom: 0;
}
.aw-acp-popup__result__related .title {
  margin-top: 10px;
}
.aw-acp-popup__result__related .products {
  margin: 0;
}
.aw-acp-popup__result__related .products .product-item-info {
  margin: -10px;
  padding: 9px;
}
@-webkit-keyframes a {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: 50px 50px;
  }
}
@keyframes a {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: 50px 50px;
  }
}
.mfp-hide {
  display: none !important;
}
/* Controls */
.input-text.qty {
  width: 54px;
  text-align: center;
}
.field.qty .label {
  font-weight: 600;
  margin: 8px 0;
  display: block;
}
@media screen and (min-width: 480px) {
  .aw-acp-popup-container .aw-acp-popup-with-anim {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
  .aw-acp-popup-container--zoom-in.mfp-ready .aw-acp-popup-with-anim {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .aw-acp-popup-container--zoom-in.mfp-removing .aw-acp-popup-with-anim {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
  .aw-acp-popup__mobile-header {
    display: none;
  }
  .aw-acp-popup__options-wrapper {
    max-height: 700px;
    overflow-y: auto;
    padding: 0 5px 0 0;
  }
  .aw-acp-popup__columns {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .aw-acp-popup__column:first-child {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 250px;
  }
  .aw-acp-popup__column:nth-child(2) {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-left: 20px;
  }
}
@media screen and (min-width: 640px) {
  .block.aw-acp-popup__related {
    margin-top: 20px;
  }
  .block.aw-acp-popup__related .block-title strong {
    font-size: 2rem;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .product-items {
    display: flex;
    align-items: stretch;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .product-item {
    width: 25% !important;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .product-item:nth-child(n+5) {
    display: none;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .product-item-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .product-item-details {
    display: flex;
    flex-direction: column;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .product-item-details .product-item-name {
    margin: 10px 12px;
    max-height: 40px;
    overflow: hidden;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .product-item-details .product-item-name .product-item-link {
    font-size: 1.4rem;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .product-item .price-box {
    margin: 10px 12px;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .product-item .price-box .price-container .price-including-tax .price {
    font-size: 1.3rem;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .product-item .price-box .price-container .price-excluding-tax .price {
    font-size: 1.5rem;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .actions-primary {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #d1d1d1;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .action.primary {
    display: block;
    font-size: 1.2rem;
    background-color: #47bb0e;
    border: none;
    padding: 10px;
  }
  .products-grid.products-acp-related:not(.widget-product-grid) .action.tocart.primary {
    display: block;
    margin: 10px auto;
  }
}
@media screen and (max-width: 639px) {
  .aw-acp-popup-container .mfp-container {
    position: static;
    padding: 0;
    background: #FFFFFF;
  }
  .aw-acp-popup-container .mfp-container::before {
    display: none;
  }
  .aw-acp-popup-container .mfp-content {
    position: static;
  }
  .aw-acp-popup {
    position: static;
    margin: 0;
  }
  .aw-acp-popup__mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 102;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
    padding: 0 7.5px;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #000000;
    background: #FFFFFF;
  }
  .aw-acp-popup__product-info {
    display: none;
  }
  .aw-acp-popup--result .aw-acp-popup__message {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .aw-acp-popup--result .aw-acp-popup__message--info {
    display: none;
  }
  .aw-acp-popup--result .aw-acp-popup__columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin: auto;
    padding-bottom: 20px;
  }
  .aw-acp-popup--result .aw-acp-popup__column--media {
    display: none;
  }
  .aw-acp-popup__main {
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0;
    padding: 40px 15px 40px 15px;
    width: 100%;
  }
  .aw-acp-popup__main.aw-acp-popup--result {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .aw-acp-popup__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .aw-acp-popup__column:first-child {
    margin-bottom: 20px;
  }
  .aw-acp-popup__column--media {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .aw-acp-popup__column--media.small .aw-acp-popup__image-wrapper {
    width: 50%;
    margin: auto;
  }
  .aw-acp-popup__reviews-wrapper {
    text-align: left;
    margin-bottom: 10px;
  }
  .aw-acp-popup__close-wrapper {
    display: none;
  }
  .aw-acp-popup__submit-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }
}
.aw-acp-popup {
  padding-top: 45px;
}
.aw-acp-popup__title {
  font-size: 2rem;
}
.aw-acp-popup__message.aw-acp-popup__message--info {
  display: none;
}
.aw-acp-popup__box-price .price-excluding-tax .price {
  margin-left: 20px;
}
.aw-acp-popup__result-price {
  font-size: 2.4rem;
  color: #009EE6;
  font-weight: 700;
}
.aw-acp-popup__loader {
  justify-content: center;
  text-align: center;
}
.aw-acp-popup__main .aw-acp-popup__mobile-close-wrapper .aw-acp-popup__mobile-close.action.primary {
  position: absolute;
  display: block !important;
  right: 0;
  top: 0;
  font-size: 0;
  width: auto;
}
.aw-acp-popup__main .aw-acp-popup__mobile-close-wrapper .aw-acp-popup__mobile-close.action.primary:after {
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 60.82289803220036%;
  width: 20px;
  height: 20px;
  font-size: 1.4rem;
  content: '';
  display: block;
}
.aw-acp-popup .action.primary {
  height: auto;
  display: block;
  margin: 10px auto;
}
.aw-acp-popup .action.primary[data-action="checkout"],
.aw-acp-popup .action.primary[data-action="submit"] {
  padding: 10px 16px;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background: #47BB0E;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 2px solid #47BB0E;
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.aw-acp-popup .action.primary[data-action="checkout"]:visited,
.aw-acp-popup .action.primary[data-action="submit"]:visited,
.aw-acp-popup .action.primary[data-action="checkout"]:focus,
.aw-acp-popup .action.primary[data-action="submit"]:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.aw-acp-popup .action.primary[data-action="checkout"]:hover,
.aw-acp-popup .action.primary[data-action="submit"]:hover,
.aw-acp-popup .action.primary[data-action="checkout"]:active,
.aw-acp-popup .action.primary[data-action="submit"]:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.aw-acp-popup .action.primary[data-action="checkout"]:visited,
.aw-acp-popup .action.primary[data-action="submit"]:visited,
.aw-acp-popup .action.primary[data-action="checkout"]:focus,
.aw-acp-popup .action.primary[data-action="submit"]:focus,
.aw-acp-popup .action.primary[data-action="checkout"]:hover,
.aw-acp-popup .action.primary[data-action="submit"]:hover,
.aw-acp-popup .action.primary[data-action="checkout"]:active,
.aw-acp-popup .action.primary[data-action="submit"]:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.aw-acp-popup .action.primary[data-action="checkout"]:visited,
.aw-acp-popup .action.primary[data-action="submit"]:visited,
.aw-acp-popup .action.primary[data-action="checkout"]:focus,
.aw-acp-popup .action.primary[data-action="submit"]:focus {
  background: #47BB0E;
  color: white;
  text-decoration: none;
  border: 2px solid #47BB0E;
}
.aw-acp-popup .action.primary[data-action="checkout"]:hover,
.aw-acp-popup .action.primary[data-action="submit"]:hover,
.aw-acp-popup .action.primary[data-action="checkout"]:active,
.aw-acp-popup .action.primary[data-action="submit"]:active {
  background: #3ea30c;
  color: white;
  text-decoration: none;
  border: 2px solid #3ea30c;
}
.aw-acp-popup .action.primary[data-action="checkout"]:visited,
.aw-acp-popup .action.primary[data-action="submit"]:visited,
.aw-acp-popup .action.primary[data-action="checkout"]:focus,
.aw-acp-popup .action.primary[data-action="submit"]:focus,
.aw-acp-popup .action.primary[data-action="checkout"]:hover,
.aw-acp-popup .action.primary[data-action="submit"]:hover,
.aw-acp-popup .action.primary[data-action="checkout"]:active,
.aw-acp-popup .action.primary[data-action="submit"]:active {
  box-sizing: border-box;
  box-shadow: none;
  line-height: 1;
  display: inline-block;
}
.aw-acp-popup .action.primary[data-action="submit"] {
  position: relative;
  padding-right: 65px;
  padding-left: 25px;
  text-align: left;
}
.aw-acp-popup .action.primary[data-action="submit"]:before {
  content: "";
  background: url("../svg/dist/css/css-sprite.svg") no-repeat;
  background-position: 0 24.057450628366247%;
  width: 34px;
  height: 22px;
  display: block;
  position: absolute;
  right: 20px;
  top: 9px;
}
.aw-acp-popup .fieldset-bundle-options .field.qty {
  display: none;
}
.aw-acp-popup .aw-acp-popup__description {
  display: none;
}
.aw-acp-popup .aw-acp-popup__description.active {
  display: block;
}
.aw-acp-popup .read-more .read-more__content {
  height: 60px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}
.aw-acp-popup .read-more:not(.read-more--active) .read-more__content:after {
  content: '';
  display: block;
  background-image: linear-gradient(to bottom, transparent, white);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 15px;
}
.aw-acp-popup .read-more .read-more__link--less {
  display: none;
}
.aw-acp-popup .read-more--active .read-more__content {
  height: auto;
}
.aw-acp-popup .read-more--active .read-more__link--more {
  display: none;
}
.aw-acp-popup .read-more--active .read-more__link--less {
  display: block;
}
.aw-acp-popup .read-more__link {
  cursor: pointer;
  color: #009EE6;
}
.fotorama__caption {
  display: none;
}
@media screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .show-on-mobile {
    display: none !important;
  }
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after {
  content: '';
  display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after {
  clear: both;
}
