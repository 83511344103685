
//
//  Product Options
//  ---------------------------------------------
.product-page__options {
    .box-tocart.simple-selector-cart{
        display: none;
    }
}
.box-tocart {



    .input-text.qty {
        .lib-bluebird-input-qty();
    }

}

.product-options-wrapper {
    .swatch-opt {
        margin: 0;
    }

    .swatch-attribute {
        margin: 0;
    }

    .swatch-attribute-options {
        margin: @indent__s 0 !important;
    }

    .swatch-attribute-label {
        font-family: @secondary__font-family;
        color: @primary__color;
        font-weight: @font-weight__bold;
        font-size: @font-size__m;
    }

    select,
    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="datetime"],
    input[type="file"],
    input[type="email"] {
        .lib-bluebird-input-square();
    }
}

.product-options-bottom {
    margin-top: @indent__s;
}

.product-add-form {
    .product-options-wrapper {
        .field .control {
            .mq(m; {
                width: 100%;
            });
        }
    }
}

select {
    option[value="delimiter"] {
        display: none;
    }
}
