
//
//  Settings
//  ---------------------------------------------

@page-header-bottom__height: 50px;





//
//  Page Header
//  ---------------------------------------------

.page-header {

    //
    //  Header General
    //  ---------------------------------------------

    position: relative;

    &.page-header--fixed {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 60;
    }

    a {
        color: @text__color;

        &:hover,
        &:active {
            color: @text__color;
            text-decoration: underline;
        }
    }

    p {
        margin-bottom: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            margin: 0;
        }
    }

    .checkout-index-index & {
        .page-header__usps {
            width: 100%;
            position: relative;

            ul {
                .lib-css(justify-content, center);
            }
        }

        .page-header__slogan {
            font-size: 2.4rem;
        }
    }




    //
    //  Header Top
    //  ---------------------------------------------

    .page-header__top.panel.wrapper {
        background-color: @panel__color;
        color: @text__color;
        border-bottom: none;

        .mq(- m; {

            .page-header__quality-mark,
            .page-header__rating,
            .page-header__compare,
            .page-header__top-links {
                display: none;
            }

            .page-header__language {
                position: absolute;
                top: 80px;
                z-index: 40;
                right: 60px;
            }

            .switcher {
                display: block;
            }
        });

    }

    .page-header__top-inner {
        .lib-css(display, flex);
        .lib-css(justify-content, space-between);
        .lib-css(align-items, center);
    }

    .page-header__quality-mark {
        line-height: 1;
    }

    .page-header__rating {
        margin-top: -4px;
    }

    .page-header__links {
        .lib-css(display, flex);
    }

    .page-header__language {
        .switcher-label {
            .lib-css(display, none);
        }
        margin-left: @indent__base;
    }




    //
    //  Header Content
    //  ---------------------------------------------

    .page-header__logo {
        .mq(- l; {
            max-width: 200px;
        });

        .mq(- m; {
            max-width: 150px;
        });

        .mq(- xs; {
            max-width: 130px;
        });

        .logo {
            max-width: 100%;
            margin: 0;

            .mq(- l; {
                max-width: 200px;
            });
        }
    }

    .page-header__content {
        background: white;

        .mq(m; {
            &.header.content {
                padding: 0 @indent__base;
            }
        });

        .mq(- m; {
            border-bottom: 1px solid #ccc;
        });

        .checkout-index-index & {
            .mq(- m; {
                padding-top: 3px;
            });
        }
    }

    .page-header__content-inner {
        .lib-css(display, flex);
        .lib-css(justify-content, space-between);
        .lib-css(align-items, center);
        height: 127px;

        .mq(- m; {
            height: 56px;
        });
    }

    .page-header__search {
        .mq( m ; {
            margin-top: @indent__s;
            margin-left: 35px;
        });
        .mq( l; {
            margin-top: 15px;
        });
    }

    .page-header__slogan {
        text-align: center;
        color: @primary__color;
        font-size: 1.7rem;
        font-weight: @font-weight__bold;
        font-family: @secondary__font-family;

        .mq(- l; {
            display: none;
        });
    }

    .page-header__actions {
        .lib-css(display, flex);
        font-family: @secondary__font-family;
        margin-top: @indent__s;
        min-width: 280px;
        .lib-css(justify-content, flex-end);

        > div[class^="page-header__"] {
            margin: 0 @indent__s;

            .mq(- m; {
                margin: 0;
            });

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .mq(- m; {
            font-size: 1.2rem;
            margin: 0;
            min-width: unset;
        });
    }

    .page-header__mobile-search {
        display: none;

        .mq(- m; {
            display: block;
        });
    }

    .page-header__account,
    .page-header__mobile-search {
        .mq(- m; {
            min-width: 40px;
            text-align: center;
            span {
                display: none;
            }
        });
    }


    .page-header__quick-order {
        .mq(- m; {
           display: none;
        });
    }

    .page-header__middle {
        .mq(m; {
            border-bottom: solid 1px @quaternary__color;
        });

        .page-header__bottom-inner {
            .lib-css(display, flex);
            .lib-css(align-items, center);
            height: 100%;

        }
    }

    .page-header__bottom-inner {
        .outer-container();
        height: 100%;

        .mq(m; {
            position: relative;
        });
    }

    .page-header__bottom-absolute {
        .mq(m; {
            border-bottom: 1px solid #e8e8e8;
            height: @page-header-bottom__height;
        });
    }

    .page-header__rating {
        .lib-css(display, flex);
        .lib-css(align-items, center);
        padding-right: @indent__s;
        height: 100%;
        background: white;
        z-index: 1;

        .mq(- m; {
            display: none;
        });
    }

    .page-header__usps {
        position: absolute;
        right: @indent__base;
        font-size: 1.2em;
        top: 0;
        width: ~"calc(100% - 50px)";

        ul {
            .lib-css(display, flex);
            .lib-css(justify-content, flex-end);
            .lib-css(align-items, center);
            height: @page-header-bottom__height;
            .lib-css(flex-wrap, wrap);
            overflow: hidden;
        }

        li {
            position: relative;
            padding-left: @indent__base;
            height: @page-header-bottom__height;
            line-height: @page-header-bottom__height;
            margin-left: 40px;
            font-size: 0.9em;

            &:first-child {
                margin-left: 0;
            }

            &:before {
                content: '';
                .svg-check-icon-blue();
                .svg-check-icon-blue-dims();
                display: block;
                position: absolute;
                top: 22px;
                left: 0;
            }
        }

        .mq(- m; {
            display: none;
        });
    }

    .page-header__menu {
        height: @page-header-bottom__height;
        width: 100%;
    }


    .mq(- m; {
        border-bottom: 0;
        margin-bottom: 0;
    });
}



.page-header__quick-order {
    .mq(m; {
        display: none;
    });

    .page-products &,
    .product-page &,
    .quickorder-index-index &,
    .checkout-cart-index & {
        .mq(m; {
            display: block;
        });
    }
}

